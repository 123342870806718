import { format, parseISO } from "date-fns";
import { MDBBtn, MDBCard, MDBCardBody, MDBCheckbox, MDBChip, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBSelect, MDBTable, MDBTableBody, MDBTableHead, MDBTooltip } from "mdb-react-ui-kit";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';
import { useLocation, useNavigate } from "react-router";
import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import Loader from "../components/Loader";
import Popup from "../components/Popup";
import CreateMember from "../components/createMember";
import ExcelExportButton from "../components/exportToExcel.js";
import {
  deleteMember, getGymAddonsList, getWaiver, logEvent, membertable, getGymPlansList, allsessions,
  sendWhatsappmessage,
  getWhatsAppTEMP,
  getappvariables
} from "../services/apiServices.js";
import addImg from "./../components/images/add.svg";
import deleteImg from "./../components/images/delete.svg";
import editImg from "./../components/images/edit.svg";
import VeiwWaiverForm from "./veiwWaiverForm";
import DatePicker from "react-datepicker";
import calImg from "./../components/images/calendar.svg";
import PageError from "./PageError";
import { googleAnalytic } from "../components/googleAnalytics";

const MManger = () => {
  const searchRef = useRef();
  const [memberData, setData] = useState([]);
  const [addandSave, setaddandSave] = useState(1);
  const [mId, setMId] = useState(null);
  const [sFlag, setFlag] = useState(0);
  const [sessionData, setSessionData] = useState([])
  const [Date11, setDate11] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [Date22, setDate22] = useState(new Date());
  const [checkFilter, setCheckFilter] = useState(false)
  const [age, setAge] = useState({
    fage: "",
    tage: ""
  })
  const [selectData, setSelectData] = useState([
    { text: 'Select', value: "Select" },

  ]);
  const [campainName, setCampainName] = useState("")
  const [WaiverPopup, setWaiverPopup] = useState(false)
  const [formData, setFormData] = useState({})
  const [mfilter, setMfilter] = useState({
    tidName: "",
    planNme: [],
    gender: "",
    status: "",
    Ttype: "",
    inputText: "",
    mType: "",
    transtype: "",
    planType: "",
  })
  const [numberData, setNumberData] = useState([])
  // const [inputText, setInputText] = useState("");
  const [filterr, setFilterr] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [valuee, setValuee] = useState(-1);
  // const [memberData1, setMemberData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalCount, setTotalcount] = useState(0)
  const [totalPages, setTotalPages] = useState(1);
  const [padata, setPadata] = useState([])
  const [wtsDatacheck, setWtsDtacheck] = useState(false)
  const today = new Date();
  const currentTime = new Date();
  const startOfToday = new Date(currentTime);
  startOfToday.setHours(0, 0, 0, 0);

  // Set end of today
  const endOfToday = new Date(currentTime);
  endOfToday.setHours(23, 59, 59, 999);

  const yesterday = new Date(currentTime);
  yesterday.setDate(currentTime.getDate() - 1);
  const startOfYesterday = new Date(yesterday);
  startOfYesterday.setHours(0, 0, 0, 0);

  const endOfYesterday = new Date(yesterday);
  endOfYesterday.setHours(23, 59, 59, 999);


  const startOfWeek = new Date(currentTime);
  startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
  startOfWeek.setHours(12, 0, 0, 0);
  const endOfWeek = new Date(currentTime);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  endOfWeek.setHours(12, 0, 0, 0);

  const startOfMonth = new Date(currentTime);
  startOfMonth.setDate(1);
  startOfMonth.setHours(0, 0, 0, 0);

  const startOfYear = new Date(currentTime);
  startOfYear.setMonth(0, 1);
  startOfYear.setHours(0, 0, 0, 0);

  const endOfYear = new Date(currentTime);
  endOfYear.setMonth(11, 31);
  endOfYear.setHours(23, 59, 59, 999);

  today.setHours(23, 59, 0, 0);
  const [edateRange, setEDateRange] = useState([]);
  const [estartDate, eendDate] = edateRange;
  const [sdateRange, setSDateRange] = useState([]);
  const [sstartDate, sendDate] = sdateRange;
  const [cdateRange, setCDateRange] = useState([])
  const [cstartDate, cendDate] = cdateRange
  const [templateData, setTemplateData] = useState([]);
  const [selectedtemp, setSelectedtemp] = useState({
    templatename: "",
    template: "",
    id: ""
  })
  const [staticModal, setStaticModal] = useState(false);

  const toggleOpenNew = () => setStaticModal(!staticModal);
  const [mappings, setMappings] = useState({});
  const [errors, setErrors] = useState({});
  const [whatsappvar, setwhatsappvar] = useState([])
  const [customValues, setCustomValues] = useState({});
  const [alert, setAlert] = useState(false)

  const WaiverSettingsOpen = async (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    const resp = await getWaiver(data);
    if (!resp) {
      return;
    }

    if (resp == "false") {
      setAlert(true)
      return;
    }
    const waiver = await resp.json();
    if (waiver.gymid) {
      setFormData(waiver);
    }
    setWaiverPopup(true);
  }
  function onsubmit() {
    setFlag(1);
  }

  async function fetchTData() {
    try {
      setWtsDtacheck(true)

      const response = await getWhatsAppTEMP(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
      if (!response) {
        return;
      }
      if (response == "false") {
        setApiError(true);
        return;
      }

      const responseText = await response.text();
      if (!responseText) {
        throw new Error("Empty response from server.");
      }

      let TemplateData;
      try {
        TemplateData = JSON.parse(responseText);
      } catch (error) {
        console.error("Failed to parse JSON:", responseText);
        throw error;
      }

      console.log(TemplateData);

      const filterTemplates = TemplateData.messageTemplates && TemplateData.messageTemplates.length > 0
        ? TemplateData.messageTemplates.filter((item) => item.status === "APPROVED")
        : [];
      console.log(filterTemplates);
      // && item.category === "MARKETING"
      setWtsDtacheck(false)
      setTemplateData(filterTemplates);
    } catch (error) {
      console.error("Error fetching template data:", error);
      // setApiError(true);
    }
  }

  useEffect(() => {
    fetchTData();
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  function createMemberCallback(e, updates, newMember) {
    togglePopup();
  }


  async function deleteThisMember(e, tmemberId) {
    e.stopPropagation();
    let ip = localStorage.getItem("ipaddress");
    let browserinfo = browserName + "-" + browserVersion + ".0.0";
    let flag = `Member Deleted Successfull (${tmemberId.fname} ${tmemberId.lname})`;
    let Eventtype = "member";
    let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
    if (window.confirm("Are you sure want to delete this Member?")) {
      deleteMember(tmemberId._id);
      await googleAnalytic("MManger", 'Member', 'Delete Member Button', 'Member Deleted')

      let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
      logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
      window.location.reload(false);
    }
  }


  function openAddform(e) {
    setMId(0);
    setaddandSave(1);
    togglePopup();
  }
  function openEditForm(e, tId) {
    e.stopPropagation();
    setMId(tId.id);
    togglePopup();
  }
  const navigate = useNavigate();
  let location = useLocation();
  let clearDashboardFilters = 0;
  let clearFilterObject = {
    tidName: mfilter.tidName,
    planNme: mfilter.planNme,
    gender: mfilter.gender,
    status: mfilter.status,
    Ttype: mfilter.Ttype,
    inputText: mfilter.inputText,
    mType: mfilter.mType,
    transtype: mfilter.transtype,
    planType: mfilter.planType,
    fage: age.fage,
    tage: age.tage,
    estartDate: estartDate ? estartDate : "",
    eendDate: eendDate ? eendDate : "",
    sstartDate: sstartDate ? sstartDate : "",
    sendDate: sendDate ? sendDate : "",
    cstartDate: cstartDate ? cstartDate : "",
    cendDate: cendDate ? cendDate : "",
  };

  async function fetchPAData() {
    if (localStorage.getItem("loggedUserInfo")) {
      const response = await getGymPlansList(
        JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
      );
      if (!response) {
        return;
      }


      if (response === "false") {
        setApiError(true)
        return
      }

      const allplan = await response.json();


      allplan.length > 0 && allplan.forEach(plan => {
        plan.type = "plan";
      });

      const respo = await getGymAddonsList(
        JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
      );
      if (!respo) {
        return;
      }
      if (respo === "false") {
        setApiError(true)
        return;
      }
      const allAddons = await respo.json()
      allAddons.length > 0 && allAddons.forEach(plan => {
        plan.type = "addon";
      });

      // setAddons(await respo.json());
      let allData = allplan.concat(allAddons);
      setPadata(allData)

    }
  }



  // useEffect(() => {
  //   fetchData()
  // }, [resert]);
  async function fetchData(sTxt) {
    if (localStorage.getItem("loggedUserInfo")) {
      let search = ""
      // mfilter.inputText;
      if (sTxt) {
        search = sTxt;
      }
      setMfilter({
        ...clearFilterObject,
        inputText: search
      })

      let locfilter = localStorage.getItem("memberm_filter");
      location.filter = {};

      if (locfilter) {
        let mlocfilter = JSON.parse(locfilter).filters;
        console.log(mlocfilter);
        if ((mlocfilter && (mlocfilter.planNme.length > 0 ||
          mlocfilter.inputText !== "" ||
          mlocfilter.Ttype !== "" ||
          mlocfilter.tidName !== "" ||
          mlocfilter.gender !== "" ||
          mlocfilter.status !== "" ||
          mlocfilter.mType !== "" ||
          mlocfilter.planType !== "" ||
          mlocfilter.transtype !== "" ||
          mlocfilter.estartDate !== "" ||
          mlocfilter.sstartDate !== "" ||
          mlocfilter.cstartDate !== "" ||
          mlocfilter.eendDate !== "" ||
          mlocfilter.sendDate !== "" ||
          mlocfilter.cendDate !== "" ||
          mlocfilter.fage !== "" ||
          mlocfilter.tage !== ""))
        ) {
          setFilterr(true);
          setCheckFilter(true)
          setMfilter({
            tidName: mlocfilter.tidName,
            planNme: mlocfilter.planNme,
            gender: mlocfilter.gender,
            status: mlocfilter.status,
            Ttype: mlocfilter.Ttype,
            inputText: mlocfilter.inputText,
            mType: mlocfilter.mType,
            transtype: mlocfilter.transtype,
            planType: mlocfilter.planType
          })
          console.log("0000000000000000");
          setEDateRange([mlocfilter.estartDate !== "" ? new Date(mlocfilter.estartDate) : null, mlocfilter.eendDate !== "" ? new Date(mlocfilter.eendDate) : null])
          setSDateRange([mlocfilter.sstartDate !== "" ? new Date(mlocfilter.sstartDate) : null, mlocfilter.sendDate !== "" ? new Date(mlocfilter.sendDate) : null])
          setCDateRange([mlocfilter.cstartDate !== "" ? new Date(mlocfilter.cstartDate) : null, mlocfilter.cendDate !== "" ? new Date(mlocfilter.cendDate) : null])
          setAge({
            fage: mlocfilter.fage,
            tage: mlocfilter.tage
          })
        }
        location.state = {}
        location.state.data = JSON.parse(locfilter).dashboardFID;
        location.state.fdate = JSON.parse(locfilter).dashboardFfdate;
        location.state.tdate = JSON.parse(locfilter).dashboardFtdate;
        location.filter = JSON.parse(locfilter).filters;
        localStorage.removeItem("memberm_filter");
      }
      location.limitmember = itemsPerPage;
      if (currentPage > totalPages) {
        setCurrentPage(1)
      }
      location.currentPage = currentPage > totalPages ? 1 : currentPage;
      console.log(location);
      if (location.state === null && valuee !== -1) {
        location.state = {}
        location.state.data = valuee;
        location.state.fdate = Date11;
        location.state.tdate = Date22;
      }
      if (clearDashboardFilters === 1) {
        location.state = {}
      }
      // if (location.filter == {}) {
      location.filter.planNme = clearFilterObject.planNme.length > 0 ? clearFilterObject.planNme : location.filter && (location.filter.planNme ? location.filter.planNme : []);
      location.filter.inputText = search !== "" ? search : location.filter && (location.filter.inputText ? location.filter.inputText : "");
      location.filter.Ttype = clearFilterObject.Ttype !== "" ? clearFilterObject.Ttype : location.filter && (location.filter.Ttype ? location.filter.Ttype : "");
      location.filter.tidName = clearFilterObject.tidName !== "" ? clearFilterObject.tidName : location.filter && (location.filter.tidName ? location.filter.tidName : "")
      location.filter.gender = clearFilterObject.gender !== "" ? clearFilterObject.gender : location.filter && (location.filter.gender ? location.filter.gender : "")
      location.filter.status = clearFilterObject.status !== "" ? clearFilterObject.status : location.filter && (location.filter.status ? location.filter.status : "")
      location.filter.mType = clearFilterObject.mType !== "" ? clearFilterObject.mType : location.filter && (location.filter.mType ? location.filter.mType : "")
      location.filter.planType = clearFilterObject.planType !== "" ? clearFilterObject.planType : location.filter && (location.filter.planType ? location.filter.planType : "")
      // }
      console.log(location.filter);

      const transdata = clearFilterObject.transtype !== "" ? clearFilterObject.transtype : location.filter && (location.filter.transtype ? location.filter.transtype : "")
      location.filter.transtype = transdata
      let estartOfDate = clearFilterObject.estartDate !== "" ? new Date(clearFilterObject.estartDate) : location.filter && (location.filter.estartDate ? new Date(location.filter.estartDate) : "")
      let eendOfDate = clearFilterObject.eendDate !== "" ? new Date(clearFilterObject.eendDate) : location.filter && (location.filter.eendDate ? new Date(location.filter.eendDate) : "")
      let sstartOfDate = clearFilterObject.sstartDate !== "" ? new Date(clearFilterObject.sstartDate) : location.filter && (location.filter.sstartDate ? new Date(location.filter.sstartDate) : "")
      let sendOfDate = clearFilterObject.sendDate !== "" ? new Date(clearFilterObject.sendDate) : location.filter && (location.filter.sendDate ? new Date(location.filter.sendDate) : "")
      let cstartOfDate = clearFilterObject.cstartDate !== "" ? new Date(clearFilterObject.cstartDate) : location.filter && (location.filter.cstartDate ? new Date(location.filter.cstartDate) : "")
      let cendOfDate = clearFilterObject.cendDate !== "" ? new Date(clearFilterObject.cendDate) : location.filter && (location.filter.cendDate ? new Date(location.filter.cendDate) : "")
      // setEDateRange([startOfDate, endOfDate])
      console.log(location.filter.transtype);
      console.log(estartOfDate, eendOfDate, sstartOfDate, sendOfDate, cstartOfDate, cendOfDate, '8888888866666666666444444444');
      if ((estartOfDate !== "" && eendOfDate !== "")) {
        const date = dateConverttoUtc(estartOfDate, eendOfDate)
        //  console.log(date,'88888888888888888888888888');
        location.filter.estartDate = date.sdate;
        location.filter.eendDate = date.edate;
      }
      if ((sstartOfDate !== "" && sendOfDate !== "")) {
        const date = dateConverttoUtc(sstartOfDate, sendOfDate)
        // console.log(date,'88888888888888888888888888');
        location.filter.sstartDate = date.sdate;
        location.filter.sendDate = date.edate;
      }
      if ((cstartOfDate !== "" && cendOfDate !== "")) {
        const date = dateConverttoUtc(cstartOfDate, cendOfDate)
        // console.log(date,'88888888888888888888888888');
        location.filter.cstartDate = date.sdate;
        location.filter.cendDate = date.edate;
      }


      // if (location.filter.transtype == "age") {
      location.filter.fage = clearFilterObject.fage !== "" ? clearFilterObject.fage : location.filter && (location.filter.fage ? location.filter.fage : "");

      location.filter.tage = clearFilterObject.tage !== "" ? clearFilterObject.tage : location.filter && (location.filter.tage ? location.filter.tage : "");
      // }
      const response = await membertable(
        JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
        location
      )

      if (!response) {
        return;
      }

      if (response === "false") {
        setApiError(true)
        return
      }
      let membersData = await response.json();
      console.log(membersData)
      if (!membersData) {

        navigate("/");
        return;
      }


      const resp = await allsessions(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);

      if (!resp) {
        return;
      }


      if (resp === "false") {
        setApiError(true)
        return;
      }

      const sessionData = await resp.json();
      console.log(sessionData);
      setSessionData(sessionData)


      setTotalcount(membersData.totalCount)
      setData(membersData.paginatedMembers);
      setTotalPages(membersData.paginatedMembers.length > 0 ? membersData.totalPages : 1)
      setValuee(location && location.state && location.state.data);
      setDate11(location && location.state && location.state.fdate);
      setDate22(location && location.state && location.state.tdate);
      setLoading(false);
      // clearDashboardFilters = 0;
      // navigate(location.pathname, { replace: true, state: {} });

    }
  }



  const dateConverttoUtc = (startDate, endDate) => {

    const startDatef = new Date(startDate)
    startDatef.setHours(0, 0, 0, 0)
    const utcstartDate = startDatef.toISOString();

    let lastoftheday = new Date(endDate);
    lastoftheday.setHours(23, 59, 59, 999);
    const utcEndDate = lastoftheday.toISOString();

    return { sdate: utcstartDate, edate: utcEndDate }
  }
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const handleDashboardClearFilter = (e) => {
    e.preventDefault();
    setValuee(-1);
    setDate11(new Date());
    setDate22(new Date());
    clearDashboardFilters = 1;
    fetchData();
  }
  useEffect(() => {
    fetchData();
  }, [sFlag, currentPage, itemsPerPage]);
  function showThisInf(e, data) {
    // console.log(data);
    // console.log("data ::::::::::::::::: onlcick");
    // console.log(memberData);
    let selMember = memberData.filter(
      (tmdata) =>
        tmdata.fname + " " + tmdata.lname === data[1] && tmdata.mid === data[0]
    )[0];
    console.log(selMember);
    selMember.anotherColumn = valuee && valuee;
    selMember.Date11 = Date11;
    selMember.Date22 = Date22;
    selMember.return = "mmanager"
    selMember.filters = {
      // inputText: mfilter.inputText,
      planNme: mfilter.planNme,
      gender: mfilter.gender,
      Ttype: mfilter.Ttype,
      tidName: mfilter.tidName,
      status: mfilter.status,
      mType: mfilter.mType,
      planType: mfilter.planType,
      transtype: mfilter.transtype,
      estartDate: estartDate ? estartDate : "",
      eendDate: eendDate ? eendDate : "",
      sstartDate: sstartDate ? sstartDate : "",
      sendDate: sendDate ? sendDate : "",
      cstartDate: cstartDate ? cstartDate : "",
      cendDate: cendDate ? cendDate : "",
      fage: age.fage,
      tage: age.tage,
    }
    console.log(selMember);
    navigate("/mdetails", {
      state: selMember,
    });
  }
  function preaddUser(e, dataObj) {
    console.log(dataObj)
    e.preventDefault();
    e.stopPropagation()
    localStorage.setItem("preadd_plan", JSON.stringify(dataObj));
    localStorage.setItem("preadd_member", JSON.stringify(dataObj));
    navigate("/home");
  }
  let tmrow = [];
  memberData.length > 0 && memberData.forEach((data, index) => {
    // console.log(data.isDND);
    let memberSession = []
    if (data.plan.length > 0) {
      memberSession = sessionData.length > 0 && sessionData.filter((item) => item.memberid === data._id && item.planid === data.planid)
    } /**
         * Added temporarly to fix the UI need to fix from backend
         */
    // data.trans = [{}];

    // console.log(index,data.expirydate);
    let rarray = [];
    // rarray.push(data.mID ? data.mID : (membersData.length - index));
    rarray.push(data.mid);
    rarray.push(data.fname + " " + data.lname);
    rarray.push(data.number ? data.number : "--");
    rarray.push(data.sex ? data.sex : "--");
    rarray.push(data.age ? data.age : '--');
    rarray.push(data.plan && data.plan.length > 0
      ? data.plan[0].name
      : data.addon.length > 0 ? data.addon[0].name : "No Plan Associated"
    );
    rarray.push((data.plan.length > 0 || data.addon.length > 0) ? format(new Date(parseISO(data.startdate)), "dd-MM-yyyy") : "No Plan Associated");
    let expiryDate = data.expirydate ? parseISO(data.expirydate) : new Date();
    let formattedExpiryDate = expiryDate ? format(expiryDate, "dd-MM-yyyy") : "No Plan Associated";
    rarray.push((data.plan.length > 0 || data.addon.length > 0) ? formattedExpiryDate : "No Plan Associated");
    rarray.push(<div className="title">{!data.issuspend ? data.membershipstatus ? data.membershipstatus : ((new Date(data.expirydate) < new Date()) ? "Inactive" : "Active") : "suspend"}</div>);
    rarray.push((data.plan.length > 0 || data.addon.length > 0) ? (data.transactiontype !== 0 ? "Renewed" : "New") : "--");
    rarray.push(format(parseISO(data.createdAt), "dd-MM-yyyy"));
    rarray.push(data.plan.length > 0
      ? data.plan[0].numberofdays + " " +
      (data.plan[0].validityflag === 1
        ? (data.plan[0].numberofdays > 1 ? "Day(s)" : "Day")
        : data.plan[0].validityflag === 2
          ? (data.plan[0].numberofdays > 1 ? "Month(s)" : "Month")
          : (data.plan[0].numberofdays > 1 ? "Year(s)" : "Year"))
      : data.addon.length > 0 ? (data.addon[0].rentbasis === "0"
        ? "Hourly"
        : data.addon[0].rentbasis === "1"
          ? "1 Day(s)"
          : data.addon[0].rentbasis === "2"
            ? "1 Month" : "1 Year")
        : "No Plan Associated");
    rarray.push(data.isDND ? (data.isDND ? "On" : "Off") : "Off");
    rarray.push(data.iswaiververified === true ?
      <div className="d-flex align-items-center">
        <div className="link-color me-2" style={{ zIndex: 5 }} onClick={(e) => WaiverSettingsOpen(e, data._id)} >
          Submitted
        </div>
        {/* <div className="link-color" onClick={(e) => ViewWaiver(e, data)}>View</div> */}
      </div>
      : "Not Submitted");
    rarray.push(!data.issuspend && (
      ((data.plan && data.plan.length > 0) || (data.addon && data.addon.length > 0)) && <>
        < div className="ml0" style={{ zIndex: 100 }} onClick={(e) =>
          preaddUser(e, {
            member: data,
            planId: data.plan.length > 0 ? data.plan[0]._id : data.addon[0]._id || -1,
          })
        } > <div className="link-color me-2"
        >
            Renew
          </div></div></>))
    rarray.push(
      <>
        {" "}
        {data.issuspend ? <span className="ml0 suspendaccess" style={{ visibility: "hidden" }}>
          <img
            src={editImg}
            width="20px"
            alt="Edit"

          />
        </span> : <span className="ml0">
          <img
            src={editImg}
            width="20px"
            alt="Edit"
            onClick={(e) => openEditForm(e, { id: data._id })}
          />
        </span>}
        <span href="#" className="ml20">
          <img
            src={deleteImg}
            width="15px"
            alt="Delete"
            onClick={(e) => deleteThisMember(e, data)}
          />
        </span>
      </>
    );
    tmrow.push(rarray);
  });

  const basicData = {
    columns: [
      // "S.no",
      "Member Id",
      "Name",
      "Contact",
      "Gender",
      "Age",
      "Plan/Addon",
      // "Plan Type",
      "Start Date",
      // "End Date",
      "Expiry Date",
      "Status",
      "Transaction Type",
      "Member Created",
      "Plan Validity",
      "DND",
      "Waiver Form",
      "Actions",
      "",
    ],
    rows: tmrow,
  };

  const downloaddata = async (e) => {
    fetchDataAndExport();
  }

  const formatPhone = (phone) => {
    let formatted = null;

    try {
      if (phone.startsWith('+')) {
        formatted = phone.replace('+', '');
        // console.log("+ same");
      } else if (phone.startsWith('1')) {
        // console.log("1 same");
        formatted = phone;
      } else if (phone.length < 10) {
        // console.log("<10");
        formatted = null;
      } else if (phone.length === 12) {
        // console.log("==12");
        formatted = phone;
      } else if (phone.length === 10) {
        // console.log("==10");
        formatted = '91' + phone;
      }
      // else if (phone.length > 10 && !(phone.length === 12)) {
      //   console.log(">10");
      //   formatted = null;
      // }
      else {
        // console.log("same");
        formatted = phone;
      }
    } finally {
      // console.log('formatted phone:', formatted);
      return formatted;
    }
  };

  const fetchDataAndExport = async () => {
    location.limitmember = totalCount;

    location.currentPage = 1;
    const response = await membertable(
      localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
      location
    );

    if (!response) {
      return;
    }
    if (response === "false") {
      setApiError(true)
      return;
    }

    const memberData = await response.json();
    console.log(memberData);
    let tmrow = [];
    memberData.paginatedMembers.length > 0 && memberData.paginatedMembers.forEach((data, index) => {


      let rarray = [];
      const formattedPhone = formatPhone(data.number);

      rarray.push(data.mid);
      rarray.push(data.fname + " " + data.lname);
      rarray.push(formattedPhone);
      rarray.push(data.sex);
      rarray.push(data.age);
      rarray.push(data.plan && data.plan.length > 0
        ? data.plan[0].name
        : data.addon.length > 0 ? data.addon[0].name : ""
      );
      // rarray.push((data.plan.length > 0 || data.addon.length > 0) ? format(new Date(parseISO(data.startdate)), "dd/MM/yyyy") : "");
      rarray.push((data.plan.length > 0 || data.addon.length > 0) ? parseISO(data.startdate) : "");
      // rarray.push((data.plan.length > 0 || data.addon.length > 0) ? format(new Date(parseISO(data.expirydate)), "dd/MM/yyyy") : "");
      rarray.push((data.plan.length > 0 || data.addon.length > 0) ? parseISO(data.expirydate) : "");
      rarray.push(!data.issuspend ? data.membershipstatus ? data.membershipstatus : ((new Date(data.expirydate) < new Date()) ? "Inactive" : "Active") : "suspend");
      rarray.push((data.plan.length > 0 || data.addon.length > 0) && (data.transactiontype !== 0 ? "Renewed" : "New"));
      // rarray.push(format(parseISO(data.createdAt), "dd/MM/yyyy"));
      rarray.push(parseISO(data.createdAt));
      rarray.push(data.plan.length > 0
        ? data.plan[0].numberofdays + " " +
        (data.plan[0].validityflag === 1
          ? (data.plan[0].numberofdays > 1 ? "Day(s)" : "Day")
          : data.plan[0].validityflag === 2
            ? (data.plan[0].numberofdays > 1 ? "Month(s)" : "Month")
            : (data.plan[0].numberofdays > 1 ? "Year(s)" : "Year"))
        : data.addon.length > 0 ? (data.addon[0].rentbasis === "0"
          ? "Hourly"
          : data.addon[0].rentbasis === "1"
            ? "1 Day(s)"
            : data.addon[0].rentbasis === "2"
              ? "1 Month" : "1 Year")
          : "");
      rarray.push(data.iswaiververified === true ? "True" : "False");

      tmrow.push(rarray);
    });
    console.log(tmrow.length > 0 && tmrow);
    const updatedBasicData = {
      columns: [
        "Member Id",
        "Name",
        "Contact",
        "Gender",
        "Age",
        "Plan/Addon",
        "StartDate",
        "ExpiryDate",
        "Status",
        "Transaction Type",
        "Member Created",
        "Plan Validity",
        "Waiver Form",

      ],
      rows: tmrow.length > 0 && tmrow,
    };
    // console.log(updatedBasicData);
    ExcelExportButton(updatedBasicData, "MemberTableData");
  };


  const nextPage = () => {

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
    setLoading(true);
  };

  const prevPage = () => {

    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
    setLoading(true);
  };
  let filter = {}
  const handleApplyAndToggle = async () => {
    filter.planNme = mfilter.planNme
    filter.inputText = mfilter.inputText
    // filter.inputText = mfilter.inputText
    filter.Ttype = mfilter.Ttype
    filter.tidName = mfilter.tidName

    filter.gender = mfilter.gender
    filter.status = mfilter.status
    filter.mType = mfilter.mType
    filter.planType = mfilter.planType
    setCurrentPage(1)
    setCheckFilter(true)
    setFilterr(true)
    setLoading(true);

    fetchData();
  }
  const handleClearFilter = (filterKey) => {
    clearFilterObject = { ...clearFilterObject, [filterKey]: "", }
    setMfilter((prevMfilter) => ({
      ...prevMfilter,
      [filterKey]: "",
    }));

    fetchData()
  };
  const handleResetAndToggle = () => {
    setEDateRange([])
    setSDateRange([])
    setCDateRange([])
    setCurrentPage(1)
    clearFilterObject = {
      tidName: "",
      planNme: [],
      gender: "",
      status: "",
      Ttype: "",
      mType: "",
      planType: "",
      transtype: '',
      fage: '',
      tage: '',
      estartDate: "",
      eendDate: "",
      sstartDate: "",
      sendDate: "",
      cstartDate: "",
      cendDate: "",
    }
    setMfilter({
      tidName: "",
      planNme: [],
      gender: "",
      status: "",
      Ttype: "",
      mType: "",
      transtype: '',
      planType: "",
    });
    setAge({
      fage: '',
      tage: ''
    })
    clearDashboardFilters = 1
    console.log(location);
    fetchData()
    setFilterr((prevFilterr) => !prevFilterr);
  }
  console.log(location);

  const filterLabels = {
    planNme: "Plan/Addon",
    tidName: "Search by Member / Contact",
    gender: "Gender",
    status: "Status",
    Ttype: "Transaction Type",
    mType: "Membership Type",
    transtype: "Transition Type",
    planType: "Plan Type"
  };

  useEffect(() => {
    fetchPAData()
  }, [])

  useEffect(() => {
    const itemSet = new Set();


    padata.length > 0 && padata.forEach(item => {
      if (item._id && item._id.length > 0) {
        itemSet.add({ text: item.name + "  (" + item.type + ")", name: item.name });
      }
    });
    console.log(itemSet);
    const uniqueitem = Array.from(itemSet);
    console.log(uniqueitem);
    const formatteditem = uniqueitem.map(item => ({
      text: item.text,
      value: item.name
    }));
    console.log(formatteditem);
    formatteditem.unshift({
      text: "No Plan(s) & No addon(s)",
      value: "Noplan"
    })

    setSelectData(formatteditem);
  }, [padata]);

  const CalenderInput = forwardRef(({ value, onClick }, ref) => (
    <>
      <span className="form-control select-input  placeholder-active me-3 shadow-1 d-flex align-items-center justify-content-between" onClick={onClick}>
        <p className="d-inline m-0 me-2">
          {value === "" ? "Custom Range" : value}
        </p>
        <img src={calImg} alt="Custom Range" ref={ref} title="Select Custom Range" />
      </span>
    </>
  ));
  const handleReload = () => {
    window.location.reload();
  }
  // const appVariables = ["MemberName", "StartDate", "EndDate", "Membership", 'Age', "BussinessName"];
  const [appVariables, setAppvariables] = useState([])
  useEffect(() => {
    fetchSData()
  }, []);

  async function fetchSData() {
    const gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : null
    const res = await getappvariables(gymid)
    if (!res) {
      return
    }
    if (res === "false") {
      setApiError(true)
      return
    }
    const variables = await res.json();
    console.log(variables, '8888888888888888');
    if (variables.length > 0) {
      setAppvariables(variables)
    }

  }
  const [basicModal, setBasicModal] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);
  const [ids, setIds] = useState([])
  const whatsappHandler = async () => {
    location.limitmember = totalCount;

    location.currentPage = 1;
    const response = await membertable(
      localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
      location
    );
    if (!response) {
      return
    }
    if (response == "false") {
      setApiError(true)
      return
    }
    const membersData = await response.json();
    console.log(membersData);

    const memberData = membersData.paginatedMembers

    const numbers = memberData.length > 0 && memberData
      .filter(entry => !entry.isDND)
      .map(entry => {
        const { _id, number, fname, lname } = entry;
        const formattedNumber = formatPhone(number);
        return formattedNumber ? { _id, number, fname, lname } : null;
      })
      .filter(entry => entry !== null);

    // console.log(numbers, numbers.length > 0);

    const jsonData = JSON.stringify(numbers);

    // Calculate size in bytes
    const dataSize = new Blob([jsonData]).size;

    // Convert size to kilobytes (KB)
    const sizeInKB = dataSize / 1024;
    console.log(`Data size: ${dataSize} bytes (${sizeInKB.toFixed(2)} KB)`);
    const ids = numbers.map(item => ({ _id: item._id }));
    setIds(ids);
    setNumberData(numbers)
    toggleOpen();
  }

  const [errr, seterrr] = useState("")
  // console.log(templateData);
  const [templateinfo, setTemplateinfo] = useState(null)
  const handleSendmessage = async () => {
    if (campainName != "") {
      // console.log(numberData, selectedtemp, '8888888888888888');
      if (templateinfo.customParams.length > 0) {
        toggleOpenNew()
      } else {
        setAlert(true)
      }
    } else {
      seterrr("This can't be blank")
    }

  }


  const [searchTerm, setSearchTerm] = useState('');
  const handleTemplateSelection = (item) => {
    // console.log(item);
    setTemplateinfo(item)
    if (selectedtemp && selectedtemp.templatename === item.elementName) {
      setSelectedtemp(null);
    } else {
      setSelectedtemp({
        templatename: item.elementName,
        template: item.bodyOriginal ? item.bodyOriginal : item.body,
        id: item.id
      });
    }
  };
  console.log(templateinfo);
  const filteredTemplates = templateData.length > 0 && templateData.filter(item =>
    item.elementName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  console.log(selectedtemp)


  const handleSelectChange = (waitVar, event) => {
    const selectedValue = event.target.value;
    setMappings(prevMappings => ({
      ...prevMappings,
      [waitVar]: selectedValue
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [waitVar]: null
    }));

    if (selectedValue !== "customvalue") {
      setCustomValues(prevCustomValues => ({
        ...prevCustomValues,
        [waitVar]: ''
      }));
    }
  };

  useEffect(() => {
    if (templateinfo && templateinfo.customParams) {
      const waitVariables = templateinfo.customParams.map(param => param.paramName);
      setwhatsappvar(waitVariables);
    }
  }, [templateinfo]);

  const handleCustomValueChange = (waitVar, event) => {
    const customValue = event.target.value;
    setCustomValues(prevCustomValues => ({
      ...prevCustomValues,
      [waitVar]: customValue
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [waitVar]: customValue === "" ? "This can't be blank." : null
    }));
  };
  const [mappingdata, setMappingdata] = useState(null)

  const handleSubmit = async () => {
    const newErrors = {};
    whatsappvar.forEach(varName => {
      if (!mappings[varName] || mappings[varName] === "Select") {
        newErrors[varName] = "Mandatory field";
      }
      if (mappings[varName] === "customvalue" && !customValues[varName]) {
        newErrors[varName] = "Mandatory field";
      }
    });

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const formattedMappings = whatsappvar.map(varName => ({
        wativariable: varName,
        [mappings[varName] === "customvalue" ? "customvalue" : "applicationvariable"]: mappings[varName] === "customvalue" ? customValues[varName] : mappings[varName]
      }));
      console.log("Formatted Mappings:", formattedMappings);
      setMappingdata(formattedMappings)
      setAlert(true)
    }

  };


  const handlealert = async () => {
    const gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : null
    if (templateinfo.customParams.length > 0) {

      await sendWhatsappmessage(gymid, { memberData: ids, message: selectedtemp, variables: mappingdata, campainName: campainName })
      toggleOpenNew()
      toggleOpen();
      setSelectedtemp(null)
      setTemplateinfo([])
      setCustomValues({})
      setMappings({})
      setSearchTerm("")
      setCampainName("")
      setAlert(false)
    } else {
      await sendWhatsappmessage(gymid, { memberData: ids, message: selectedtemp, campainName: campainName })

      toggleOpen();
      setSelectedtemp(null)
      setAlert(false)
      setCampainName("")
    }

  }

  const cancelhandler = () => {
    setBasicModal(false)
    setCampainName("")
    seterrr("")
    setSelectedtemp(null)
  }
  console.log(mappings);
  const handleclose = () => {
    setStaticModal(false);
  }
  console.log(valuee, checkFilter, filterr, mfilter);

  return (
    <>
      <LeftPane isactive="3" />
      <Header />
      {apiError ? <PageError handleReload={handleReload} /> :
        <div
          className="gym-main rightContent"
        >
          <div className="gym-container" id="showcase">
            <div className="d-flex space-between align-center m-3">

              <h3 className="gym-large">Members Management</h3>

              <MDBBtn color="light"
                style={{ cursor: "pointer!important" }}
                id="adduserBtn"
                onClick={(e) => openAddform(e)}
              >
                <img src={addImg} alt="Add Member" width="16px" className="me-1" /> Add Member
              </MDBBtn>
            </div>



            <div className="d-flex mob-inline-block align-items-center justify-content-start">
              {valuee !== -1 && valuee !== null && valuee !== undefined && (
                <MDBChip className=" mb-0">
                  {([0, 1, 2].includes(valuee) && "New Members") ||
                    ([10, 11, 12].includes(valuee) && "Active Members") ||
                    ([3, 4, 5].includes(valuee) && "Plan Exp") ||
                    ([6, 7, 8, 9].includes(valuee) && "Plan Renewals")}
                  <div className="d-flex align-items-center justify-content-center text-danger"
                    onClick={(e) => handleDashboardClearFilter(e)}
                    style={{ cursor: 'pointer' }}  // Add this style to make the icon clickable
                  >
                    <i className="fa fa-close ms-2"></i>
                  </div>
                </MDBChip>
              )}

              {filterr && checkFilter && Object.entries(mfilter).map(([key, value]) => (
                filterr && (
                  (typeof value === 'string' && value.trim() !== "") ||
                  (Array.isArray(value) && value.length > 0)
                ) && filterLabels[key] && (
                  <MDBChip key={key} closeIcon className="mb-0" onDelete={() => handleClearFilter(key)}>
                    {filterLabels[key]}
                  </MDBChip>
                )
              ))}
            </div>

            <div className="searchFilterToggle mt-2">



              <div className=" d-flex flex-wrap">
                <div className="gym-section w-100-mobile">
                  <label className="fw-bold">Search by Member/Contact/Member Id</label>
                  <div>
                    <input
                      type="text"
                      ref={searchRef}
                      value={mfilter.tidName}
                      className="form-control select-input placeholder-active form-controlMobile"
                      // className="form-control"
                      placeholder="search here..."
                      onChange={(e) => setMfilter({
                        ...mfilter,
                        tidName: e.target.value.toLowerCase()
                      })}
                    />
                  </div>
                </div>
                <div className="gym-section w-300 w-100-mobile ms-2">
                  <label className="fw-bold">Plan/Addons</label>

                  <div>

                    <MDBSelect
                      clearButton
                      placeholder="All"
                      multiple search
                      data={selectData}
                      value={mfilter.planNme}
                      onChange={(e) => {
                        const selectedValues = e.map(item => item.value);
                        // setSelectValue(selectedValues);
                        setMfilter({
                          ...mfilter,
                          planNme: selectedValues
                        })
                      }}
                    />
                  </div>
                </div>


                <div className="gym-section w-100-mobile ms-2">
                  <label className="fw-bold">Membership Type</label>
                  <div>
                    <select
                      name="emailReminder"
                      id="emailReminder"
                      className="form-control select-input form-select placeholder-active me-3 shadow-1 form-selectMobile"
                      value={mfilter.mType}

                      onChange={(e) => setMfilter({
                        ...mfilter,
                        mType: e.target.value
                      })}
                    >
                      <option value="">All</option>
                      <option value="plans">Plans</option>
                      <option value="addons">Addons</option>
                      <option value="noplans">No plans/addons</option>
                    </select>
                  </div>
                </div>
                <div className="gym-section w-100-mobile ms-2">
                  <label className="fw-bold">Plan Type</label>
                  <div>
                    <select
                      name="emailReminder"
                      id="emailReminder"
                      className="form-control select-input form-select placeholder-active me-3 shadow-1 form-selectMobile"
                      value={mfilter.planType}

                      onChange={(e) => setMfilter({
                        ...mfilter,
                        planType: e.target.value
                      })}
                    >
                      <option value="">All</option>
                      <option value="onetime">One-Time</option>
                      <option value="recurring">Recurring</option>
                    </select>
                  </div>
                </div>
                <div className="gym-section w-100-mobile ms-2">
                  <label className="fw-bold">Gender</label>
                  <div>
                    <select
                      name="emailReminder"
                      id="emailReminder"
                      className="form-control select-input placeholder-active form-select me-3 shadow-1 form-selectMobile"
                      value={mfilter.gender}

                      onChange={(e) => setMfilter({
                        ...mfilter,
                        gender: e.target.value
                      })}
                    >
                      <option value="">All</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
                <div className="gym-section w-100-mobile ms-2">
                  <label className="fw-bold">Status</label>
                  <div>
                    <select
                      name="emailReminder"
                      id="emailReminder"
                      className="form-control select-input placeholder-active form-select shadow-1 me-3 form-selectMobile"
                      value={mfilter.status}

                      onChange={(e) => setMfilter({
                        ...mfilter,
                        status: e.target.value
                      })}
                    >
                      <option value="">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
                <div className="gym-section w-100-mobile ms-2">
                  <label className="fw-bold">Transaction Type</label>
                  <div>
                    <select
                      name="emailReminder"
                      id="emailReminder"
                      className="form-control select-input placeholder-active form-select shadow-1 form-selectMobile"
                      value={mfilter.Ttype}

                      onChange={(e) => setMfilter({
                        ...mfilter,
                        Ttype: e.target.value
                      })}
                    >
                      <option value="">All</option>
                      <option value="Renewed">Renewed</option>
                      <option value="New">New</option>
                    </select>
                  </div>
                </div>




                <div className="gym-section w-100-mobile ms-3">

                  <label className="fw-bold">Plan Expired Range</label>
                  <div className="w_200px w-100-mobile">
                    <div className="gym-xlarge gym-larg-pos">
                      <DatePicker
                        dateFormat={"d/M/yyyy"}
                        selectsRange={true}
                        startDate={estartDate}
                        endDate={eendDate}
                        onChange={(update) => {
                          // getDates(update)
                          setEDateRange(update)
                        }}
                        isClearable={false}
                        customInput={<CalenderInput />}
                      />
                    </div>
                  </div>
                </div>
                <div className="gym-section w-100-mobile ms-2">

                  <label className="fw-bold">Plan Start Range</label>
                  <div className="w_200px w-100-mobile">
                    <div className="gym-xlarge gym-larg-pos">
                      <DatePicker
                        dateFormat={"d/M/yyyy"}
                        selectsRange={true}
                        startDate={sstartDate}
                        endDate={sendDate}
                        onChange={(update) => {
                          // getDates(update)
                          setSDateRange(update)
                        }}
                        isClearable={false}
                        customInput={<CalenderInput />}
                      />
                    </div>
                  </div>
                </div>
                <div className="gym-section w-100-mobile ms-2">

                  <label className="fw-bold">Member Create Range</label>
                  <div className="w_200px w-100-mobile">
                    <div className="gym-xlarge gym-larg-pos">
                      <DatePicker
                        dateFormat={"d/M/yyyy"}
                        selectsRange={true}
                        startDate={cstartDate}
                        endDate={cendDate}
                        onChange={(update) => {
                          setCDateRange(update)
                        }}
                        isClearable={false}
                        customInput={<CalenderInput />}
                      />
                    </div>
                  </div>
                </div>

                {/* {mfilter.transtype !== "" && mfilter.transtype == "age" && */}
                <div className="gym-section w-100-mobile ms-2">
                  <label className="fw-bold">Age Range</label>
                  <div className="d-flex align-items-center w-auto">
                    <input type="number" name="" id="" className="form-control w-80px" placeholder="From" value={age.fage}

                      onChange={(e) => setAge({
                        ...age,
                        fage: e.target.value
                      })} />
                    <div className="mx-2">-</div>
                    <input type="number" name="" id="" className="form-control w-80px " placeholder="To" value={age.tage}

                      onChange={(e) => setAge({
                        ...age,
                        tage: e.target.value
                      })} />
                  </div>
                </div>

                <div className="gym-section w-100-mobile ms-3">
                  <div className="mt-3 pt-1 filterApplyMobile">
                    <button type="submit" className="me-2 btn btn-primary"
                      onClick={(e) => handleApplyAndToggle()}
                    >
                      Apply
                    </button>
                    <button type="submit" className="mt-2 btn btn-secondary"
                      onClick={(e) => handleResetAndToggle()}
                    >
                      Clear
                    </button>
                    {/* {(localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role == "admin" && <button type="submit" className="ms-2 btnheight btn btn-primary"
                    onClick={(e) => downloaddata()}
                  >
                    <MDBIcon fas icon="download" className="d-inline me-1" />Export
                  </button>} */}
                    {(localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "admin" &&
                      <>
                        <button type="submit" className="ms-2 btnheight btn btn-primary" onClick={(e) => downloaddata()}>
                          <MDBIcon fas icon="download" className="d-inline me-1" />Export
                        </button>

                        <MDBTooltip placement="bottom-end" tag='a' title={wtsDatacheck ? "Loading please wait" : (filteredTemplates && filteredTemplates.length > 0 ? "whatsApp" : `Complete the WhatsApp settings on the "Templates & Reminders" page to enable this feature.`)} >
                          <button type="submit" disabled={filteredTemplates && filteredTemplates.length > 0 ? false : true} className="ms-2 btnheight btn btn-primary whatsapp" onClick={(e) => whatsappHandler()}>
                            <i className="d-inline fs-6 me-1 fab fa-whatsapp" ></i>WhatsApp
                          </button>
                        </MDBTooltip>
                      </>
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>






          <div className="tableContainer">

            {loading ? (<h4><Loader /></h4>) :
              <div className="datatable cursorPointer mmManager_Table" data-mdb-fixed-header="true">
                <div className="mmtablediv">

                  <MDBTable hover small scroll>
                    <MDBTableHead>
                      <tr>
                        {basicData.columns.map((column, index) => (
                          <th key={index}>{column}</th>
                        ))}
                      </tr>
                    </MDBTableHead>

                    <MDBTableBody>
                      {basicData.rows.map((row, rowIndex) => (
                        <tr key={rowIndex}
                          onClick={(e) => {
                            showThisInf(e, row);
                          }}
                        >
                          {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                          ))}
                        </tr>
                      ))}
                      {basicData.rows.length === 0 && <tr >
                        <td colSpan={15}><p className=" d-flex justify-content-center"> No Data Found</p></td>
                      </tr>}
                    </MDBTableBody>
                  </MDBTable>
                </div>

                <div className="datatable-pagination">
                  Rows per page
                  <div className="ms-2 form-control w-auto">
                    <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className="Pagenation-Select">
                      <option value="50">
                        <div className="select-option">
                          50
                        </div>
                      </option>
                      <option value="100">
                        <div className="select-option">
                          100
                        </div>
                      </option>
                      <option value="500">
                        <div className="select-option">
                          500
                        </div>
                      </option>
                      <option value="1000">
                        <div className="select-option">
                          1000
                        </div>
                      </option>

                    </select>
                  </div>
                  <button onClick={prevPage}
                    disabled={currentPage === 1}
                    className="btn btn-link datatable-pagination-button datatable-pagination-right ripple-surface-dark">
                    Previous
                  </button>
                  <div className="d-flex justify-content-between w-120px align-items-center"> Page
                    <input className="form-control w-50px" value={currentPage} onChange={(e) => setCurrentPage(Number(e.target.value))} />
                    of {totalPages}</div>
                  <button onClick={nextPage}
                    disabled={currentPage === totalPages}
                    className="btn btn-link datatable-pagination-button datatable-pagination-right ripple-surface-dark">
                    Next
                  </button>
                </div>
              </div>}
          </div>
        </div >}

      {isOpen && (
        <Popup
          title="Add Member"
          ptype="modal-lg"
          className="modal-body-overflow"
          content={
            <>
              <div className="modal-body-overflow">
                <CreateMember
                  id={null}
                  index={null}
                  onsubmit={onsubmit}
                  backallback={createMemberCallback}
                  submit="0"
                  mId={mId}
                  addandSave={addandSave}
                  from={"reload"}
                />
              </div>
            </>
          }
          handleClose={togglePopup}
        />
      )
      }

      {WaiverPopup && <MDBModal open={WaiverPopup} setOpen={setWaiverPopup} tabIndex='-1'>
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{formData.title ? formData.title : "Digital Waiver Form"}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={() => setWaiverPopup(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {formData.gymid ?
                <VeiwWaiverForm data={formData} />
                : <Loader />}
            </MDBModalBody>

            <MDBModalFooter className="pb-2 pe-3">
              <MDBBtn color='secondary' onClick={() => setWaiverPopup(false)}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal >}
      <MDBModal open={basicModal} onClose={() => cancelhandler()} tabIndex='-1'>
        <MDBModalDialog size="xl">
          <MDBModalContent>
            <MDBModalHeader className="w-100 flex-wrap">
              <MDBModalTitle className="w-sm-100">
                <div className="w-sm-100">
                  <div className="">WhatsApp Templates</div>
                </div>
              </MDBModalTitle>
              <div className="d-flex justify-content-between align-items-center">
                <div className="flex-column">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="h6 w-70">Campaign Name:</p>
                    <input
                      type="text"
                      className="form-control w-100"
                      placeholder="Enter here"
                      value={campainName}
                      onChange={(e) => setCampainName(e.target.value)}
                    />
                  </div>
                  {errr && <p className="text-danger w-100 text-center ps-5 me-5">{errr}</p>}
                </div>
                <MDBBtn className='ms-2 btn-close' color='none' onClick={() => {
                  setBasicModal(false);
                  setSearchTerm('');
                }}></MDBBtn>
              </div>

            </MDBModalHeader>
            <MDBModalBody className="whatsapptemplateBody">
              <input
                type="text"
                className="form-control"
                placeholder="Search by template name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              {(filteredTemplates && filteredTemplates.length > 0) ? filteredTemplates.map((item, index) => (
                <MDBRow>
                  <MDBCol size='15' key={index}>
                    <MDBCard className="mt-2">
                      <MDBCardBody>
                        <div className="d-flex align-items-start mob-block">
                          <div className="fw-600 d-flex align-items-center w-30" style={{ fontSize: 14 }}>
                            <MDBCheckbox
                              name='flexCheck'
                              value=''
                              id={`flexCheckDefault-${index}`}
                              className="me-2"
                              label={item.elementName}
                              checked={selectedtemp && selectedtemp.templatename === item.elementName}
                              onChange={() => handleTemplateSelection(item)} />
                          </div>
                          <div className="ps-0 w-70 mob-block" dangerouslySetInnerHTML={{ __html: item.bodyOriginal ? item.bodyOriginal : item.body }} />
                        </div>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              )) : <p>No Templates Found.</p>}
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={cancelhandler}>
                Cancel
              </MDBBtn>
              <MDBBtn onClick={() => handleSendmessage()} >Send Message</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>


      <MDBModal staticBackdrop tabIndex='-1' open={staticModal} onClose={() => setStaticModal(false)}>
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                <h6 className="m-0">
                  {templateinfo ? templateinfo.elementName : ""}
                </h6>
              </MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={handleclose}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <p className="whatsAppTemplate border border-2 rounded-2 p-2">
                {templateinfo ? (templateinfo.bodyOriginal ? templateinfo.bodyOriginal : templateinfo.body) : ""}
              </p>
              <p class="note note-info">
                <strong>Note:</strong> Map the template variables with the application variables.
              </p>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-bold">WhatsApp Variables</div>
                <div className="fw-bold w-40 text-center">Application Variables</div>
              </div>

              {whatsappvar.map((waitVar, index) => (<>
                <div className="d-flex align-items-start justify-content-between mt-2" key={index}>
                  <div className="fw-bold w-30 mt-1">{waitVar}<span className="text-danger">*</span></div>
                  <div className="w-40">
                    <select
                      className="form-select p-1 px-2"
                      value={mappings[waitVar] || "Select"}
                      onChange={(e) => handleSelectChange(waitVar, e)}
                    >
                      <option disabled>Select</option>
                      {appVariables && appVariables.length > 0 && appVariables.map((appVar, i) => (
                        <option key={i} value={appVar.value}>{appVar.name}</option>

                      ))}
                      <option value={"customvalue"} >Custom Value</option>
                    </select>
                    {errors[waitVar] && mappings[waitVar] !== "customvalue" && <div className="text-danger">{errors[waitVar]}</div>}

                  </div>

                  {mappings[waitVar] === "customvalue" && (
                    <div className="w-35 ms-2">
                      <input
                        type="text"
                        className="form-control"
                        value={customValues[waitVar] || ""}
                        onChange={(e) => handleCustomValueChange(waitVar, e)}
                        placeholder="Enter custom value"
                      />
                      {errors[waitVar] && <div className="text-danger">{errors[waitVar]}</div>}
                    </div>

                  )}
                </div>
              </>
              ))}

            </MDBModalBody>
            <MDBModalFooter className="pe-2 pb-2">
              <MDBBtn color='secondary' onClick={handleclose}>
                Close
              </MDBBtn>
              <MDBBtn onClick={() => handleSubmit()}>Send</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {alert && (
        <Popup
          title="Confirm message"
          content={
            <>
              <div className="pb-3">
                <span className="fs-6 pb-3">Are you sure you want to send message?</span>

                <div className="overflow_inh d-block py-2 scrollsend"  >
                  {numberData.length > 0 && numberData.map((item) => (<span className="mb-1 chip w-auto d-inline-block"><strong>{item.fname + " " + item.lname}</strong>,<span className="fs-7">{item.number}</span></span>))}

                </div>
              </div>
              <MDBModalFooter>
                <MDBBtn color='secondary' onClick={(e) => setAlert(false)}>
                  Cancel
                </MDBBtn>
                <MDBBtn color='success' onClick={() => handlealert()}>Send</MDBBtn>
              </MDBModalFooter>
            </>
          }
          handleClose={() => setAlert(false)}
        />
      )}
    </>
  );
};

export default MManger;