import React from 'react';

function FeatureText(props) {
    return (
        <>
            <li className="text-muted ">
                <span className=' text-success h5 me-1'>
                    <i className=' uil uil-check-circle align-middle'>
                    </i>
                </span>
                {props.value}
            </li>
        </>
    );
}

export default FeatureText;
