import React, { useEffect, useState } from 'react'
import {
    MDBAlert,
    MDBBtn,
    MDBDatatable,
    MDBIcon,
    MDBInput,
    MDBTooltip
} from 'mdb-react-ui-kit';
import { updateSMSTemp } from '../services/apiServices';


function MessageFrequency(props) {

    let plansInfo = localStorage.getItem("planInfo") ? JSON.parse(localStorage.getItem("planInfo")) : null;
    const [AllSMS, setAllSMS] = useState([])
    const [apiError, setApiError] = useState(false);

    const [Alerttxt, setAlerttxt] = useState("");
    useEffect(() => {
        setAllSMS(props.allData)
        // setData(props.allData)
    }, [props.allData]);

    function durationFunction(lab, type11, messagetype, value) {
        value = value.replace(/(0{2,})/g, "0");
        const durat = value.split(',').map(Number);
        console.log(durat);
        let limitValue = process.env.REACT_APP_LIMIT_FREQUENCY || 20;
        if (durat.some((itm) => isNaN(itm) || itm > 99)) {
            console.log("All values must be two-digit numbers between 10 and 99.");
            return;
        }
        const updatedAllSMS = AllSMS.map(item => {
            if (item.label === lab && item.type === type11) {
                const count = value.split(',').length - 1;
                console.log(value);
                if (count > 4) {
                    return item;
                }
                if (durat.length > 0) {
                    if (durat.some((itm) => itm > limitValue)) {
                        console.log(`One or more values are greater than ${limitValue}`);
                        return item;
                    }
                }
                if (messagetype === "whatsapp") {
                    return { ...item, wtsappduration: value };
                } else if (messagetype === "email") {
                    return { ...item, emailduration: value };
                } else if (messagetype === "copy") {
                    return {
                        ...item,
                        wtsappduration: value,
                        emailduration: value,
                        duration: value
                    };
                } else {
                    return { ...item, duration: value };
                }
            }
            return item;
        });
        console.log(updatedAllSMS);
        setAllSMS(updatedAllSMS);
        // setcheck(true);
        return;
    }

    function copyToAll(lab, type11) {
        console.log(lab, type11,);
        const sms = AllSMS.find(item => item.label === lab && item.type === type11);
        const valueToCopy = sms.duration;
        console.log(valueToCopy);
        durationFunction(lab, type11, 'copy', valueToCopy);

    }

    const isNumberOrComma = (key) => {
        return /^[0-9,]$/.test(key);
    };

    const handleKeyPress = (e) => {
        const key = e.key;

        if (!isNumberOrComma(key)) {
            e.preventDefault();
        }
    };

    async function updateFunction(e) {
        e.preventDefault();
        if (AllSMS.length > 0) {
            for (const value of AllSMS) {
                const updates = {
                    label: value.label,
                    type: value.type,
                    toggle: value.toggle,
                    duration: value.duration,
                    templete: value.templete,
                    smscategory: value.smscategory,
                    emailduration: value.emailduration,
                    wtsappduration: value.wtsappduration,
                    gymid: JSON.parse(sessionStorage.getItem("loggedUserInfo")) ? JSON.parse(sessionStorage.getItem("loggedUserInfo")).gymid : "",
                };
                try {
                    console.log(updates, '633377777777777777777777777777734');
                    let saverep = await updateSMSTemp(updates, JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
                    if (!saverep) {
                        return
                    }
                    if (saverep === "false") {
                        setApiError(true)
                        return;
                    }
                    const recinfo = await saverep.json();
                    console.log(recinfo);
                    setAlerttxt("Template saved successfully")
                } catch (error) {
                    console.error("Error updating record:", error);
                }
            }
        }
    }

    const actionData = {
        columns: [
            { label: 'Template Name', field: 'name' },
            ...(plansInfo === null || (plansInfo.planUsage && plansInfo.planUsage.smsintergration)
                ? [{ label: 'SMS', field: 'sms', sort: false }]
                : []),
            ...(plansInfo === null || (plansInfo.planUsage && plansInfo.planUsage.whatsappintegration)
                ? [{ label: 'WhatsApp', field: 'whatsapp', sort: false }]
                : []),
            { label: 'Email', field: 'email', sort: false },
        ],
        rows: AllSMS.filter(item => item.duration !== undefined).map(item => ({
            name: `${item.label}`,

            ...((plansInfo === null || (plansInfo.planUsage && plansInfo.planUsage.smsintergration)) ? {
                sms: (
                    <div className='d-flex align-items-center mob-block'>
                        <div className=" toolpit-position w-60 mob-w-100 membership-notification">
                            <MDBInput
                                id='formControlDefault'
                                type='text'
                                className={"d-flex"}
                                placeholder='Ex:-1,2,3,4,5'
                                value={item.duration || ""}
                                onChange={(e) => durationFunction(item.label, item.type, "sms", e.target.value)}
                            >
                                <MDBTooltip
                                    tag='a'
                                    wrapperProps={{ href: '#' }}
                                    title="If you enter 0 then Member will get SMS notification 1day before their Membership validity ended if you enter 0,1 then Member will get SMS notifications 1day & 2days before their Membership validity ended if you enter 0,1,2 then Member will get SMS notifications 1day, 2days & 3days before their Membership validity ended"
                                    className='position-absolute'>
                                    <MDBIcon fas icon="info-circle" className='ms-2 position-absolute top_12px' />
                                </MDBTooltip>
                            </MDBInput>
                        </div>
                        <div className=''>
                            <MDBBtn className="text-capitalize ms-3" onClick={() => copyToAll(item.label, item.type)}>
                                Copy to All
                            </MDBBtn>
                        </div>
                    </div>
                ),
            } : {}),
            ...(plansInfo === null || (plansInfo.planUsage && plansInfo.planUsage.whatsappintegration) ? {
                whatsapp: (
                    <div className='d-flex'>
                        <div className=" toolpit-position w-60  mob-w-100 membership-notification">
                            <MDBInput
                                id='formControlDefault'
                                type='text'
                                placeholder='Ex:-1,2,3,4,5'
                                className={"d-flex"}
                                value={item.wtsappduration || ""}
                                onChange={(e) => durationFunction(item.label, item.type, "whatsapp", e.target.value)}
                                onKeyPress={handleKeyPress}
                            >
                                <MDBTooltip
                                    tag='a'
                                    wrapperProps={{ href: '#' }}
                                    title="If you enter 0 then Member will get WhatsApp notification 1day before their Membership validity ended if you enter 0,1 then Member will get WhatsApp notifications 1day & 2days before their Membership validity ended if you enter 0,1,2 then Member will get WhatsApp notifications 1day, 2days & 3days before their Membership validity ended"
                                    className='position-absolute'>
                                    <MDBIcon fas icon="info-circle" className='ms-2 position-absolute top_12px' />
                                </MDBTooltip>
                            </MDBInput>
                        </div>
                    </div>
                ),
            } : {}),
            email: (
                <div className='d-flex'>
                    <div className=" toolpit-position w-60  mob-w-100 membership-notification">
                        <MDBInput
                            id='formControlDefault'
                            type='text'
                            className={"d-flex"}
                            placeholder='Ex:-1,2,3,4,5'
                            value={item.emailduration || ""}
                            onChange={(e) => durationFunction(item.label, item.type, "email", e.target.value)}
                            onKeyPress={handleKeyPress}
                        >
                            <MDBTooltip
                                tag='a'
                                wrapperProps={{ href: '#' }}
                                title="If you enter 0 then Member will get Email notification 1day before their Membership validity ended if you enter 0,1 then Member will get Email notifications 1day & 2days before their Membership validity ended if you enter 0,1,2 then Member will get Email notifications 1day, 2days & 3days before their Membership validity ended"
                                className='position-absolute'>
                                <MDBIcon fas icon="info-circle" className='ms-2 position-absolute top_12px' />
                            </MDBTooltip>
                        </MDBInput>
                    </div>
                </div>
            ),
        })),
    };

    const hadlecancel = () => {
        window.location.reload();
    }


    return (

        <>
            <div className='d-flex align-items-center justify-content-end'>
                <div className='mb-3'>
                    <MDBBtn color='secondary' className='me-2' onClick={hadlecancel}>Cancel</MDBBtn>
                    <MDBBtn onClick={(e) => updateFunction(e)}>Save</MDBBtn>
                </div>
            </div>
            <div className='notificationFrequencyDatatable'>
                <MDBDatatable hover data={actionData} />
            </div>
            <MDBAlert
                color="success"
                autohide
                position='top-center'
                delay={5000}
                appendToBody
                open={Alerttxt !== ""}
                onClose={() => setAlerttxt("")}
            >
                {Alerttxt}
            </MDBAlert>
        </>
    )
}

export default MessageFrequency;
