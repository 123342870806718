import React from 'react';  // This is needed for JSX


function PasswordInputField({
  handlePasswordChange,
  passwordValue,
  passwordStrength,
}) {
  return (
<div>
   <label className="form-label">Password<span className="text-danger">*</span></label>
   <input
    type="password"
    className="form-control"
    placeholder="Password"
    autoComplete="new-password"
    onChange={handlePasswordChange}
    onInput={passwordStrength}
    value={passwordValue}
    id="typePasswordX-2"
    required />
  </div>
  );
}

export default PasswordInputField;
