import React, { useEffect, useState } from "react";
import TrainerSummary from '../views/TrainerSummary';
import Checkinreport from '../views/checkinreport';
import Header from './Header';
import LeftPane from './LeftPane';
import CheckInCount from '../views/CheckInCount';
import { MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import TransactionSummary from '../views/TransactionSummary';
import PlanSummary from '../views/planSummary';
import SessionSummary from "../views/SessionSummary.js";
import SessionCountRpt from "../views/sessionCountRpt.js";
import ReactGA from 'react-ga';
import SmsReports from "../views/smsReports";
import WhatsAppCampaignReports from "../views/whatsAppCampaignReports";
import RevenueReport from "../views/revenueReports";
import WtsAppNotificationRpts from "../views/wtsAppNotificationRpts";
import ActiveMemberHisory from "./ActiveMembersHistory";

function Reports() {
  let plansInfo = localStorage.getItem("planInfo") ? JSON.parse(localStorage.getItem("planInfo")) : null;

  const [basicActive, setBasicActive] = useState("tab1");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    if (plansInfo && plansInfo.planFeature && !plansInfo.planFeature.checkinreports) {
      setBasicActive("tab6");
    }
  }, []);


  let userInfoInSession = localStorage.getItem("loggedUserInfo") ? true : false;
  return (
    <><>
      <LeftPane isactive="13" />
      <Header />
    </>
      <div className="gym-main gym-main-div rightContent">
        {/* <div className='d-flex align-items-center justify-content-between'>
          <h5 className="m-0 me-3">Reports:</h5>
          <div onClick={toggleCollapse} style={{ cursor: 'pointer' }} className='chip bg-info bg-opacity-50 px-2 py-1 rounded-2'>
            {!isCollapsed ? <><span className='me-2 text-dark'>Hide Tabs</span><i class="fa-solid fa-chevron-up"></i> </> : <><span className='me-2 text-dark'>View Tabs</span><i class="fa-solid fa-chevron-down"></i></>}
          </div>
        </div> */}

        {/* <div className="d-flex align-items-center mb-2">
                    <h5 className="m-0 me-3">Reports:</h5>
                    <select value={selectedValue} className='forminput' onChange={handleChange}>
                        <option value="">Select Option</option>

                        <option value="checkreport">Check-In Reports</option>
                        <option value="trainersummary">Trainer Summary</option>
                        <option value="checkincount">Check-In Count</option>
                    </select>
                </div> */}
        {/* Note: Please choose a report from the dropdown menu above. */}
        {/* {selectedValue == "checkreport" && <Checkinreport />}
                {selectedValue == "trainersummary" && <TrainerSummary />}
                {selectedValue == "checkincount" && <CheckInCount />} */}
        <div className='d-flex '>
          <MDBTabs className="customnavtabs">

            {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.trainersessionssummary)) ? <>
              {userInfoInSession && JSON.parse(localStorage.getItem("loggedUserInfo")).role !== "staff" && <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => handleBasicClick("tab2")}
                  active={basicActive === "tab2"}
                >
                  Trainer Summary
                </MDBTabsLink>
              </MDBTabsItem>}
            </> : ""}
            {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.checkinreports)) ? <>
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => handleBasicClick("tab1")}
                  active={basicActive === "tab1"}
                >
                  Check-In
                </MDBTabsLink>
              </MDBTabsItem>
            </> : ""}
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab3")}
                active={basicActive === "tab3"}
              >
                Check-In Count
              </MDBTabsLink>
            </MDBTabsItem>


            {userInfoInSession && JSON.parse(localStorage.getItem("loggedUserInfo")).role !== "staff" && <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab4")}
                active={basicActive === "tab4"}
              >
                Payment Summary
              </MDBTabsLink>
            </MDBTabsItem>}
            {userInfoInSession && JSON.parse(localStorage.getItem("loggedUserInfo")).role !== "staff" && <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab5")}
                active={basicActive === "tab5"}
              >
                Plan Summary
              </MDBTabsLink>
            </MDBTabsItem>}

            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab6")}
                active={basicActive === "tab6"}
              >
                Session
              </MDBTabsLink>
            </MDBTabsItem>
            {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.trainersessionssummary)) ? <>
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => handleBasicClick("tab7")}
                  active={basicActive === "tab7"}
                >
                  Session Summary
                </MDBTabsLink>
              </MDBTabsItem>
            </> : ""}
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab8")}
                active={basicActive === "tab8"}
              >
                SMS History
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab9")}
                active={basicActive === "tab9"}
              >
                WhatsApp Campaign History
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab11")}
                active={basicActive === "tab11"}
              >
                WhatsApp Notification History
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              {userInfoInSession && JSON.parse(localStorage.getItem("loggedUserInfo")).role !== "staff" && <MDBTabsLink
                onClick={() => handleBasicClick("tab10")}
                active={basicActive === "tab10"}
              >
                Revenue Report
              </MDBTabsLink>}
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab12")}
                active={basicActive === "tab12"}
              >
                Active Members History
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </div>
        <MDBTabsContent>
          <MDBTabsPane open={basicActive === "tab1"} show={basicActive === "tab1"}>
            <Checkinreport />

          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "tab2"} show={basicActive === "tab2"}>
            <TrainerSummary />

          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "tab3"} show={basicActive === "tab3"}>
            <CheckInCount />

          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "tab4"} show={basicActive === "tab4"}>
            <TransactionSummary />

          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "tab5"} show={basicActive === "tab5"}>

            <PlanSummary />
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "tab6"} show={basicActive === "tab6"}>

            <SessionSummary />
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "tab7"} show={basicActive === "tab7"}>

            <SessionCountRpt />
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "tab8"} show={basicActive === "tab8"}>

            <SmsReports />
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "tab9"} show={basicActive === "tab9"}>

            <WhatsAppCampaignReports />
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "tab10"} show={basicActive === "tab10"}>

            <RevenueReport />
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "tab11"} show={basicActive === "tab11"}>
            <WtsAppNotificationRpts />
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "tab12"} show={basicActive === "tab12"}>
            < ActiveMemberHisory />
          </MDBTabsPane>
        </MDBTabsContent>


      </div>




    </>
  );
}

export default Reports;