import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Assuming you're using react-router
import logo from "./../components/images/GymLogo.png";
import easymembr from "./../components/images/Easy-MembrLogo.png";

import {
    cancelSubscriptionPlan,
    checkEmail,
    createGBussinessInfo,
    createSubscriptionPlan,
    getAllPricingPlans,
    getGBussinessInfo,
    getPricingPlansById,
    getSubscriptionPlans,
    saveGBussinessInfo
} from "../services/apiServices.js";
import { MDBAlert, MDBBtn, MDBCheckbox, MDBIcon, MDBInput, MDBModalFooter, MDBRadio, MDBSelect, MDBSpinner } from "mdb-react-ui-kit";
import axios from "axios"; // to make API requests
import { isEmail, isEmpty, } from "../validators/helper.js";
import Popup from "../components/Popup";
import PendingAnimation from "../components/images/stopwatch-svgrepo-com.svg"
export default function BillingSubscriptionPlan() {
    const logourl = (localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl : logo) : logo);
    const [imageLoaded, setImageLoaded] = useState(true);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const planId = params.get('id');
    const [plansData, setPlansData] = useState({});
    const [allPlansData, setAllPlansData] = useState()
    const [PayForm, setPayForm] = useState(false);
    const [planExist, setPlanExist] = useState(true);
    const [lastSubPlans, setLastSubPlans] = useState([]);
    const [Form, setForm] = useState({
        planid: "",
        smsintergration: false,
        accountantrole: false,
        whatsappintegration: false,
        memberportal: false,
        subscriptionfrequency: 0,
        autopay: true,
        transactionid: "",
        amount: 0,
    });
    const [businessForm, setbusinessForm] = useState({
        businessName: "",
        businessEmail: "",
        firstName: "",
        lastName: "",
        contactNumber: "",
        address: "",
        city: "",
        gstnumber: "",
        isVerified: false,
    });
    const [ErrorBusinessInfo, setErrorBusinessInfo] = useState({});
    const [verifyValue, setverifyValue] = useState(false)
    useEffect(() => {
        const handleImageLoad = () => setImageLoaded(true);
        const handleImageError = () => setImageLoaded(false);
        const imgElement = document.createElement("img");
        imgElement.onload = handleImageLoad;
        imgElement.onerror = handleImageError;
        imgElement.src = logourl;
        return () => {
            imgElement.onload = null;
            imgElement.onerror = null;
        };
    }, [logourl]);
    const navigate = useNavigate();
    const [error, seterror] = useState(null);
    function updateForm(value) {
        return setForm((prev) => {
            return { ...prev, ...value };
        });
    }
    function updateBusinessForm(value) {
        return setbusinessForm((prev) => {
            return { ...prev, ...value };
        });
    }
    async function fetchPData() {
        const response = await getPricingPlansById(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
        if (!response) {
            return;
        }
        if (response === "false") {
            return;
        } else {
            const record = await response.json();
            console.log(record);
            setLastSubPlans((record && record.planUsage) ? [record] : []);
        }
    }
    useEffect(() => {
        if (lastSubPlans.length > 0 && allPlansData) {
            const planUsage = lastSubPlans[0].planUsage;
            const pricingPlans = allPlansData.pricingPlans;

            const eligiblePlans = pricingPlans.filter((objt) => {
                const plan = allPlansData.planLimitation.find(obj => obj.id === objt._id);
                return (
                    ((plan.memebershipplans >= planUsage.plans) || plan.memebershipplans === -1) &&
                    ((plan.addons >= planUsage.addons) || plan.addons === -1) &&
                    ((plan.timeslots >= planUsage.slots) || plan.timeslots === -1) &&
                    ((plan.activemembers >= planUsage.activeMemb) || plan.activemembers === -1) &&
                    ((plan.stafflogin >= planUsage.staff) || plan.stafflogin === -1) &&
                    ((plan.transactions >= planUsage.transactionCount) || plan.transactions === -1)
                );
            });

            console.log("Eligible Plans:", eligiblePlans);

            // Update plansData to only include eligible plans
            setPlansData(prevData => ({
                ...prevData,
                pricingPlans: eligiblePlans.length > 0 ? eligiblePlans : [],
            }));
        }
    }, [allPlansData, lastSubPlans]);

    useEffect(() => {
        if (localStorage.getItem("number")) {
            updateBusinessForm({ contactNumber: localStorage.getItem("number") });
            localStorage.removeItem("number");
        }
        async function fetchData() {

            const response = await getGBussinessInfo(
                JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
            );
            if (!response || response == 'false') {
                return;
            }
            const data = await response.json();
            console.log(data)
            setbusinessForm({
                businessName: data.businessName,
                businessEmail: data.businessEmail,
                firstName: data.firstName,
                lastName: data.lastName,
                contactNumber: data.contactNumber,
                address: data.address,
                gstnumber: data.gstnumber,
                isVerified: false,
            });
        }
        if (localStorage.getItem("loggedUserInfo")) {
            fetchData();
            fetchPData();
        }
    }, []);

    function generateUniqueKey(length) {
        const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let uniqueKey = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            uniqueKey += characters.charAt(randomIndex);
        }
        return uniqueKey;
    }
    let validateForm = (e) => {
        let errors = {};
        if (isEmpty(businessForm.businessName)) {
            errors.businessName = "Business Name can't be blank";
        }
        if (isEmpty(businessForm.firstName)) {
            errors.firstName = "First Name can't be blank";
        }
        if (isEmpty(businessForm.lastName)) {
            errors.lastName = "Last Name can't be blank";
        }

        // if (isEmpty(businessForm.gstnumber)) {
        //     errors.gstnumber = "Field can't be blank";
        // }
        if (isEmpty(businessForm.businessEmail)) {
            errors.businessEmail = "Field can't be blank";
        } else if (!isEmail(businessForm.businessEmail)) {
            errors.businessEmail = "Please enter valid email";
        }

        if (isEmpty(businessForm.address)) {
            errors.address = "Address can't be blank";
        }

        if (isEmpty(businessForm.city)) {
            errors.city = "City can't be blank";
        }

        if (isEmpty(errors)) {
            return true;
        } else {
            return errors;
        }
    };
    const verifyGstNumber = async () => {
        try {
            // Make API call to fetch business details using GST number
            const gst = businessForm.gstnumber;
            let response;
            try {
                let Keys = '76ee5b3ccf8a43404affb46277228466'
                let Url = `http://sheet.gstincheck.co.in/check/${Keys}/${gst}`;
                response = await axios.get(Url);
            } catch (error) {
                updateBusinessForm({
                    isVerified: false,
                });
                setverifyValue(false);
                seterror("Failed to verify GST number, try again.");
            }
            console.log(response)
            if (response.data && response.data.data.lgnm) {
                let gstVerifedData = response.data.data
                const ownerName = "" /*gstVerifedData.pradr?.addr?.pncd || "";*/  // Adjust based on API structure
                const contactNumber = "" /*gstVerifedData.pradr?.addr?.contact || "";*/ // Adjust based on API structure
                const address = "" /* gstVerifedData.pradr?.adr || "";*/ // Adjust based on API structure
                // Update form with business details and verification status
                updateBusinessForm({
                    businessName: gstVerifedData.lgnm,
                    firstName: ownerName?.split(' ')[0] || "",  // Assuming ownerName has a first and last name
                    lastName: ownerName?.split(' ')[1] || "",   // Adjust if API provides separate fields for first/last name
                    isVerified: true,
                    contactNumber: contactNumber,
                    gstnumber: gstVerifedData.gstin,
                    address: address,
                });
            } else {
                seterror("Failed to verify GST number, try again.");
                updateBusinessForm({
                    isVerified: false,
                });
            }
            setverifyValue(false);
        } catch (error) {
            // Handle error if GST verification fails
            updateBusinessForm({
                isVerified: false,
                error: "Failed to verify GST number",
            });
        }
    };

    const cancelHandler = async (id) => {
        let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
        let updates = {
            subscriptionId: id
        }
        const response = await cancelSubscriptionPlan(gymid, updates);
        if (!response || response == 'false') {
            console.log("Subscription cancelled successfully");
            return;
        }
        const res = await response.json();
        console.log(res);
    }
    async function checkingEmail() {
        if (localStorage.getItem("loggedUserInfo")) {
            return true;
        }
        let id = "1";
        let obj = {
            email: businessForm.businessEmail,
        }
        const res = await checkEmail(id, obj);
        const resObj = await res.json();
        console.log(resObj)
        if (resObj?.error) {
            setErrorBusinessInfo({ businessEmail: "Email already exists." });
            return false;
        } else {
            return true;
        }
    }
    function payNow(e) {
        let errors = validateForm(e);
        if (errors === true) {
            setErrorBusinessInfo({});
            payNowHandler(e);

        } else {
            setErrorBusinessInfo(errors);
        }
    }
    async function payNowHandler(e) {
        try {
            e.preventDefault();
            let gymid = JSON.parse(localStorage.getItem("loggedUserInfo"))?.gymid;
            if (localStorage.getItem("loggedUserInfo")) {
                const updates = {
                    businessName: businessForm.businessName,
                    businessEmail: businessForm.businessEmail,
                    firstName: businessForm.firstName,
                    lastName: businessForm.lastName,
                    contactNumber: businessForm.contactNumber,
                    address: businessForm.address,
                    city: businessForm.city,
                    // gstnumber: businessForm.gstnumber,
                };
                await saveGBussinessInfo(updates, gymid);
            } else {
                const uniqueId = generateUniqueKey(8);
                const updates = {
                    firstname: businessForm.firstName,
                    lastname: businessForm.lastName,
                    businessName: businessForm.businessName,
                    email: businessForm.businessEmail,
                    address: businessForm.address,
                    city: businessForm.city,
                    password: uniqueId,
                    through: "subscription",
                    number: businessForm.contactNumber,
                };
                const saverep = await createGBussinessInfo(updates);
                const jsOBj = await saverep.json();
                console.log(jsOBj)
                if (jsOBj.userInfo) {
                    gymid = jsOBj.userInfo.gymid;
                }
            }
            let modules = {
                smsintergration: Form.smsintergration,
                accountantrole: Form.accountantrole,
                whatsappintegration: Form.whatsappintegration,
                memberportal: Form.memberportal,
            };
            let update = {
                planid: Form.planid,
                gymid: gymid,
                amount: Form.amount,
                transactionid: Form.transactionid,
                subscriptionfrequency: Form.subscriptionfrequency,
                autopay: Form.autopay,
                modules: modules,
                issignup:true

            }
            const reqObj = await createSubscriptionPlan(gymid, update);
            if (!reqObj || reqObj == 'false') {
                return;
            }
            const resObj = await reqObj.json();
            console.log(resObj);

            // if (resObj.success) {
            //     window.location.href = reqObj.paymentLink;
            // } else {
            //     console.log("Subscription creation failed.");
            // }
            setPayForm(true);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        async function fetchData() {
            const response = await getAllPricingPlans();
            if (!response || response === 'false') {
                return;
            }
            let recinfo = await response.json();
            if (recinfo && recinfo.pricingPlans) {
                console.log(recinfo)
                let filterData = recinfo.pricingPlans.filter((obj) => obj.availability === true);
                let filterPlan = recinfo.pricingPlans.find((obj) => obj._id == planId);
                if (!filterPlan) {
                    filterPlan = filterData[filterData.length - 1];
                }
                let frequency = filterPlan.billingperiod ? filterPlan.billingperiod[filterPlan.name == "Premium" ? 1 : 0] : 0;
                let uniqueId = generateUniqueKey(10);
                setForm({
                    ...Form,
                    planid: filterPlan._id,
                    subscriptionfrequency: frequency,
                    amount: filterPlan.pricemonthly * frequency,
                    transactionid: uniqueId,
                });
                recinfo.pricingPlans = filterData;
                setPlansData(recinfo);
                setAllPlansData(recinfo);
            }
        }
        fetchData();
    }, []);
    useEffect(() => {
        if (plansData.pricingPlans) {
            let filterPlan = plansData.pricingPlans.find((obj) => obj._id == Form.planid);
            let frequency = filterPlan.billingperiod ? filterPlan.billingperiod[filterPlan.name == "Premium" ? 1 : 0] : 0;
            setForm({
                ...Form,
                subscriptionfrequency: frequency,
            });
        }
    }, [Form.planid]);
    useEffect(() => {
        if (plansData && plansData.pricingPlans) {
            let planObj = plansData.pricingPlans.find((obj) => obj._id === Form.planid);
            if (!planObj) {
                return;
            }
            let planPrice = planObj?.pricemonthly;
            let frequency = Form.subscriptionfrequency; // Use the current frequency from the form state
            let smsPrice = Form.smsintergration ? planObj.smsintergration : 0;
            let accountantPrice = Form.accountantrole ? planObj.accountantrole : 0;
            let whatsappPrice = Form.whatsappintegration ? planObj.whatsappintegration : 0;
            let memberPortalPrice = Form.memberportal ? planObj.memberportal : 0;
            let totalPrice = (planPrice + smsPrice + accountantPrice + whatsappPrice + memberPortalPrice) * frequency;
            setForm((prevForm) => ({
                ...prevForm,
                amount: totalPrice,  // Update the amount in the form based on the new calculations
            }));
        }
    }, [
        Form.planid,
        Form.subscriptionfrequency,
        Form.smsintergration,
        Form.whatsappintegration,
        Form.accountantrole,
        Form.memberportal,
        plansData.pricingPlans
    ]);
    let currentPlan = (plansData && plansData.pricingPlans) ? (plansData.pricingPlans.find((obj) => obj._id == Form.planid)) : null;
    const currentDate = new Date();
    const lastExpiryDate = new Date(currentDate.setMonth(currentDate.getMonth() + parseInt(Form.subscriptionfrequency)));
    const formattedExpiryDate = lastExpiryDate.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });
    return (
        <>
            {!PayForm ?
                <div>
                    <div className="border-bottom">
                        <div className="py-3 px-4 d-flex align-items-center justify-content-between w-100">
                            <h4 className="m-0">Payment Billing Details</h4>
                            <img
                                // src={imageLoaded ? logourl : logo}
                                src={easymembr}
                                className="logoImg"
                                alt="Gym Logo"
                            />
                        </div>
                    </div>

                    <div className="p-4">
                        <p className="bold fs-5 mt-0">
                            Billing Address:
                        </p>
                        <div className="Billing__Address w-40 mob-w-100 ">
                            {/* <div class=" Business__Details__data pb-3">
                                <label className="fw-bold">GST:<span className="text-danger">*</span></label>
                                <div className="position-relative">
                                    <input class="Business__input__adjust w-50" type="text" name="first_name" id="first_name" placeholder="GST Number"
                                        value={businessForm.gstnumber}
                                        onChange={(e) => updateBusinessForm({ gstnumber: e.target.value })}
                                        /> */}
                            {/* <div className="position-absolute end-0 bottom-10">
                                            {businessForm.isVerified ?
                                                <label className="clr-green">
                                                    <MDBIcon fas icon="check" /> Verified
                                                </label>
                                                :
                                                <>
                                                    {verifyValue ?
                                                        <MDBBtn outline size="sm"><MDBSpinner size="sm" role="status" tag="span" /></MDBBtn>
                                                        :
                                                        <MDBBtn outline size="sm" onClick={() => { setverifyValue(true); verifyGstNumber() }}>Verify</MDBBtn>}
                                                </>
                                            }
                                        </div> */}
                            {/* {ErrorBusinessInfo.gstnumber && (<p className="clr-red">Field can't be blank.</p>)}

                                        </div>
                                </div> */}
                            <div class=" Business__Details__data pb-3">
                                <label className="fw-bold">Owner First Name:<span className="text-danger">*</span></label>
                                <div>
                                    <input class="Business__input__adjust w-100" type="text" name="first_name" id="first_name" placeholder="Owner First Name"
                                        value={businessForm.firstName}
                                        onChange={(e) => updateBusinessForm({ firstName: e.target.value })}
                                    />
                                    {ErrorBusinessInfo.firstName && (<p className="clr-red">Field can't be blank.</p>)}
                                </div>
                            </div>
                            <div class=" Business__Details__data pb-3">
                                <label className="fw-bold">Owner Last Name:<span className="text-danger">*</span></label>
                                <div>

                                    <input class="Business__input__adjust w-100" type="text" name="first_name" id="first_name" placeholder="Owner Last Name"
                                        value={businessForm.lastName}
                                        onChange={(e) => updateBusinessForm({ lastName: e.target.value })}
                                    />
                                    {ErrorBusinessInfo.lastName && (<p className="clr-red">Field can't be blank.</p>)}
                                </div>

                            </div>
                            <div class=" Business__Details__data pb-3">
                                <label className="fw-bold">Business Name:<span className="text-danger">*</span></label>
                                <div>

                                    <input class="Business__input__adjust w-100" type="text" name="first_name" id="first_name" placeholder="Business Name"
                                        value={businessForm.businessName}
                                        onChange={(e) => updateBusinessForm({ businessName: e.target.value })}
                                    />
                                    {ErrorBusinessInfo.businessName && (<p className="clr-red">Field can't be blank.</p>)}
                                </div>
                            </div>
                            <div class=" Business__Details__data pb-3">
                                <label className="fw-bold">Email ID:<span className="text-danger">*</span></label>
                                <div>

                                    <input class="Business__input__adjust w-100" type="text" name="first_name" id="first_name" placeholder="Email ID"
                                        value={businessForm.businessEmail}
                                        onChange={(e) => updateBusinessForm({ businessEmail: e.target.value })}
                                    />
                                    {ErrorBusinessInfo.businessEmail && (<p className="clr-red">{ErrorBusinessInfo.businessEmail}</p>)}
                                </div>

                            </div>
                            <div class=" Business__Details__data pb-3">
                                <label className="fw-bold">City:<span className="text-danger">*</span></label>
                                <input class="Business__input__adjust w-50" type="text" name="first_name" id="first_name" placeholder="City"
                                    value={businessForm.city}
                                    onChange={(e) => updateBusinessForm({ city: e.target.value })}
                                />
                            </div>
                            <div class=" Business__Details__data pb-3">
                                <label className="fw-bold">Address:<span className="text-danger">*</span></label>
                                <input class="Business__input__adjust w-50" type="text" name="first_name" id="first_name" placeholder="Address"
                                    value={businessForm.address}
                                    onChange={(e) => updateBusinessForm({ address: e.target.value })}
                                />
                            </div>


                        </div>
                        <div className="border-bottom border-2"></div>



                        <div className="mob-d-block d-flex justify-content-between">
                            <div className="w-50 mob-w-100 ">
                                <p className="bold fs-5 mt-3">
                                    Payment Details:
                                </p>
                                <div className="d-flex align-items-center pb-4">
                                    <p className="m-0 w-100px">Plan Name: </p>
                                    <div className="w-30 ms-4 mob-w-50">
                                        {plansData.pricingPlans && (
                                            <select
                                                className="form-select me-2"
                                                onChange={(e) => updateForm({ planid: e.target.value })}
                                                value={Form.planid}
                                            >
                                                {plansData.pricingPlans.length > 0 &&
                                                    plansData.pricingPlans.map((obj) => (
                                                        <option key={obj._id} value={obj._id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        )}
                                    </div>
                                </div>

                                <div className="d-flex pb-2">
                                    <p className="m-0 w-100px">Billing Period: </p>
                                    <div className="w-30 mob-w-50 ms-2">
                                        <select
                                            className='form-select me-2'
                                            onChange={(e) => updateForm({ subscriptionfrequency: e.target.value })}
                                            value={Form.subscriptionfrequency}
                                        >
                                            {currentPlan?.billingperiod.map(obj => (
                                                <option key={obj} value={obj}>
                                                    {obj} Months
                                                </option>
                                            ))}
                                        </select>
                                        <p className="fs-7">Renew on {formattedExpiryDate}</p>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <p className="m-0">Additional Addons: </p>
                                    <div className="d-flex pt-3 mob-d-block">
                                        <div className="me-4" onClick={(e) => updateForm({ smsintergration: !Form.smsintergration })}>
                                            <MDBCheckbox name='inlineCheck' id='inlineCheckbox1' value='option1'
                                                label={<label>SMS Integration &#8377;{currentPlan?.smsintergration}/<span style={{ "font-size": "9px" }}>Month</span></label>}
                                                inline
                                                checked={Form.smsintergration}
                                                onChange={(e) => updateForm({ smsintergration: e.target.checked })}
                                            />
                                        </div>
                                        <div className="me-4" onClick={(e) => updateForm({ whatsappintegration: !Form.whatsappintegration })}>
                                            <MDBCheckbox name='inlineCheck' id='inlineCheckbox2' value='option2'
                                                label={<label>WhatsApp Integration &#8377;{currentPlan?.whatsappintegration}/<span style={{ "font-size": "9px" }}>Month</span></label>}
                                                inline
                                                checked={Form.whatsappintegration}
                                                onChange={(e) => updateForm({ whatsappintegration: e.target.checked })}
                                            />
                                        </div>
                                        <div className="me-4" onClick={(e) => updateForm({ accountantrole: !Form.accountantrole })}>
                                            <MDBCheckbox name='inlineCheck' id='inlineCheckbox3' value='option3'
                                                label={<label>Accountant Role &#8377;{currentPlan?.accountantrole}/<span style={{ "font-size": "9px" }}>Month</span></label>}
                                                inline
                                                checked={Form.accountantrole}
                                                onChange={(e) => updateForm({ accountantrole: e.target.checked })}
                                            />
                                        </div>
                                        {/* <div className="me-4">
                                        <MDBCheckbox name='inlineCheck' id='inlineCheckbox4' value='option4' label='Member Portal' inline
                                            checked={Form.memberportal}
                                            onChange={(e) => updateForm({ memberportal: e.target.checked })}
                                        />
                                    </div> */}
                                    </div>
                                </div>

                            </div>

                            <div className="w-30 mob-w-100 bg-light p-3">
                                {/* <p className="bold fs-5">
                                    Payment Details:
                                </p> */}
                                <div className="d-flex w-100 mb-3">
                                    <label className="fw-bold w-30">Plan Name:</label>
                                    <label className="fw-bold w-50">{currentPlan?.name}</label>
                                    <label className="fw-bold w-20 text-end">&#8377;{currentPlan?.pricemonthly}</label>
                                </div>
                                <div className="d-flex w-100 mb-3">
                                    <label className="fw-bold w-30">Duration:</label>
                                    <label className="fw-bold w-50">{Form.subscriptionfrequency} Months</label>
                                    <label className="fw-bold w-20  text-end">{Form?.subscriptionfrequency} x &#8377;{currentPlan?.pricemonthly}</label>
                                </div>
                                <div className="d-flex w-100 mb-3">
                                    <label className="fw-bold w-50">Plan Amount:</label>
                                    <label className="fw-bold w-50  text-end">&#8377;{currentPlan?.pricemonthly * Form.subscriptionfrequency}</label>
                                </div>
                                {(Form.smsintergration || Form.whatsappintegration || Form.accountantrole || Form.memberportal) && <h5 className="w-100">
                                    <p className="fw-bold">Additional Addons:</p>
                                </h5>}
                                {Form.smsintergration ?
                                    <div className="d-flex w-100 mb-3">
                                        <label className="fw-bold w-30">SMS Integration</label>
                                        <label className="fw-bold w-60">{Form?.subscriptionfrequency} x &#8377;{currentPlan?.smsintergration}</label>
                                        <label className="fw-bold w-10 text-end">&#8377;{currentPlan?.smsintergration * Form.subscriptionfrequency}</label>
                                    </div>
                                    : <></>}
                                {Form.whatsappintegration ?
                                    <div className="d-flex w-100 mb-3">
                                        <label className="fw-bold w-30">WhatsApp Integration</label>
                                        <label className="fw-bold w-60">{Form?.subscriptionfrequency} x &#8377;{currentPlan?.whatsappintegration}</label>
                                        <label className="fw-bold w-10 text-end">&#8377;{currentPlan?.whatsappintegration * Form.subscriptionfrequency}</label>
                                    </div>
                                    : <></>}
                                {Form.accountantrole ?
                                    <div className="d-flex w-100 mb-3">
                                        <label className="fw-bold w-30">Accountant Role</label>
                                        <label className="fw-bold w-60">{Form?.subscriptionfrequency} x &#8377;{currentPlan?.accountantrole}</label>
                                        <label className="fw-bold w-10 text-end">&#8377;{currentPlan?.accountantrole * Form.subscriptionfrequency}</label>
                                    </div>
                                    : <></>}
                                {Form.memberportal ?
                                    <div className="d-flex w-100 mb-3">
                                        <label className="fw-bold w-80">Member Portal</label>
                                        <label className="fw-bold w-20 text-end">&#8377;{currentPlan?.memberportal * Form.subscriptionfrequency}</label>
                                    </div>
                                    : <></>}

                                <div className="border-bottom mb-3"></div>

                                <div className="d-flex w-100 align-items-center justify-content-between mb-3">
                                    <label className="fw-bold fs-6">Subtotal</label>
                                    <label className="fw-bold fs-6 w-20  text-end">&#8377;{Form.amount}</label>
                                </div>

                                <div className="d-flex w-100 align-items-center justify-content-between mb-3">
                                    <label className="fw-bold fs-6">Tax 18%</label>
                                    <label className="fw-bold fs-6 w-20  text-end">&#8377;{(Form.amount * 0.18).toFixed(2)}</label>
                                </div>

                                <div className="d-flex w-100 align-items-center justify-content-between mb-3">
                                    <label className="fw-bold fs-6">Total</label>
                                    <label className="fw-bold fs-6 w-20  text-end">&#8377;{(Form.amount * 1.18).toFixed(2)}</label>
                                </div>

                                <div className="me-4 d-flex w-100 align-items-center fw-bold">
                                    <label className="fw-bold fs-6 me-4">Payment Mode: </label>

                                    {/* <MDBCheckbox name='inlineCheck' id='inlineCheckbox5' value='option5' label='Auto Pay' inline /> */}
                                    <MDBRadio name='inlineRadio' id='inlineRadio2' value='option2' label='Offline' inline defaultChecked />
                                    <MDBRadio name='inlineRadio' id='inlineRadio3' value='option3' label='Online' disabled inline />

                                </div>


                                <div className="w-100 mt-4">
                                    <MDBBtn className="w-100" onClick={async (e) => { if (await checkingEmail()) { payNow(e) } }}>Pay</MDBBtn>
                                </div>
                            </div>
                        </div>
                    </div >
                    {error && <MDBAlert
                        color={(error.includes('failed')) ? 'danger' : (error.includes('success') ? 'success' : 'secondary')}
                        autohide
                        position='top-right'
                        delay={5000}
                        appendToBody
                        open={error}
                        onClose={() => { seterror(null) }}
                    >
                        {error}
                    </MDBAlert>
                    }
                </div >

                : <div>
                    <div className="border-bottom">
                        <div className="py-3 px-4 d-flex align-items-center justify-content-between w-100">
                            <h4 className="m-0">Payment Billing Details</h4>
                            <img
                                // src={imageLoaded ? logourl : logo}
                                src={easymembr}
                                className="logoImg"
                                alt="Gym Logo"
                            />
                        </div>
                    </div>
                    <div className="success-screen h-70vh">
                        <div class="success-animation">
                            <img src={PendingAnimation} className="logoImg" />
                            {/* <svg class="checkmark"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 52 52"
                            >
                                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg> */}
                        </div>
                        <div className="success-text text-center">
                            <h5>Payment Pending!!</h5>
                            {!localStorage.getItem("loggedUserInfo")
                                &&
                            <>
                                    <p className="fs-6 mb-0">
                                        Your login credentials have been sent to your registered email. Please check your inbox.
                                    </p>
                                </>}
                            <p><a className="p-0 fw-600" href="/login">Click Here </a> to Login.</p>
                        </div>
                    </div>
                </div>}

            {planExist && lastSubPlans.length > 0 &&
                ((new Date(lastSubPlans[0].planUsage.expiryDate) - new Date()) > 5 * 24 * 60 * 60 * 1000) &&  // Check if expiry date is more than 5 days away
                <>
                    <Popup
                        title="Subscription Exist"
                        ptype="modal-md"
                        content={
                            <>
                                <b>You already have a {lastSubPlans.length > 0 && lastSubPlans[0].pricingPlans.name} plan subscription!</b><br />
                                Thank you for being a valued subscriber. Do you wish to change the plan?
                                <MDBModalFooter className="pe-2 pb-2">
                                    <MDBBtn color='secondary' onClick={() => navigate('/dashboard')}>Cancel</MDBBtn>
                                    <MDBBtn onClick={() => setPlanExist(false)}>Change Plan</MDBBtn>
                                </MDBModalFooter>
                            </>
                        }
                        handleClose={() => navigate('/dashboard')}
                    />
                </>
            }
            <div className="text-center d-block text-secondary mt-2">Powered by Zencruz</div>
        </>
    );
}
