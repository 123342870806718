import React, { Component } from "react";
import { Col, Container, Card, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Link as Link2 } from 'react-scroll';
import * as Icon from 'react-feather';
import Popup from "../../components/Popup";
// import Logo from "../assets/images/logo-light.png";
// import logodark from "../assets/images/Easy-MembrLogo.png";
import Logo from "../assets/images/Easy-MembrLogo.png";
import Privacy from "../../components/privacy";
import Terms from "../../components/terms.js";
import Faqs from "../../components/faqs.js";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arrow: false,
            popup: false,
            popup1: false,
            popup2: false,
            logourl: localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl || Logo) : Logo,
            imageLoaded: true
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        if (document.body.scrollTop >= 500 || document.documentElement.scrollTop >= 500) {
            this.setState({ arrow: true });
        } else {
            this.setState({ arrow: false });
        }
    }

    compopup = () => {
        this.setState({ popup: true })
    }

    handler = () => {
        this.setState({ popup: false })
    }

    compopup1 = () => {
        this.setState({ popup1: true })
    }

    handler1 = () => {
        this.setState({ popup1: false })
    }

    compopup2 = () => {
        this.setState({ popup2: true })
    }

    handler2 = () => {
        this.setState({ popup2: false })
    }

    handleImageLoad = () => {
        this.setState({ imageLoaded: true });
    };

    handleImageError = () => {
        this.setState({ imageLoaded: false });
    };

    render() {
        const { arrow, popup, popup1, popup2, logourl, imageLoaded } = this.state;

        return (
            <React.Fragment>
                {/* Back to top */}
                <Link2 to="home" style={{ display: arrow ? 'block' : 'none' }} id="back-to-top" className="back-to-top rounded-pill fs-5">
                    <Icon.ArrowUp className="fea icon-sm icons align-middle" />
                </Link2>
                {/* Back to top  */}

                {/* Footer Start  */}
                <footer className="bg-footer">
                    <Container>
                        <Row className="justify-content-center">
                            <div className="col-12">
                                <div className="footer-py-60 text-center">
                                    <Row className="py-5">
                                        {/* Your footer content goes here */}
                                    </Row>
                                </div>
                            </div>
                        </Row>
                    </Container>

                    <div className="footer-py-30 footer-bar bg-footer">
                        <Container className="text-center">
                            <Row className="align-items-center justify-content-between">
                                <Col lg={3} md={2} sm={3}>
                                    <div className="text-sm-start">
                                        <Link to="#" className="logo-footer">
                                            {imageLoaded ? (
                                                <img src={logourl} alt="Logo" onLoad={this.handleImageLoad} onError={this.handleImageError} />
                                            ) : (
                                                <img src={Logo} alt="Default Logo" />
                                            )}
                                        </Link>
                                    </div>
                                </Col>

                                <Col lg={6} md={6} sm={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <ul className="list-unstyled footer-list terms-service mb-0">
                                        <li className="list-inline-item mb-0" onClick={this.compopup}>
                                            <Link to="#" className="text-foot me-2">Privacy</Link>
                                        </li>
                                        <li className="list-inline-item mb-0" onClick={this.compopup1}>
                                            <Link to="#" className="text-foot me-2">Terms</Link>
                                        </li>
                                        <li className="list-inline-item mb-0">
                                            <Link to="#" className="text-foot me-2">FAQs</Link>
                                        </li>
                                        {/* <li className="list-inline-item mb-0">
                                            <Link to="/contact" className="text-foot">Contact</Link>
                                        </li> */}
                                    </ul>
                                </Col>

                                <Col lg={3} md={4} sm={3} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <div className="text-sm-end">
                                        <p className="mb-0 text-foot">© {(new Date().getFullYear())}{" "} <Link to="#" className="text-reset">Zencruz.</Link></p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </footer>

                {/* Popups */}
                {popup && (
                    <Popup
                        title="Privacy Policy for EasyMembr:"
                        ptype="modal-lg"
                        content={<Privacy />}
                        handleClose={this.handler}
                    />
                )}
                {popup1 && (
                    <Popup
                        title="Terms and Conditions for EasyMembr:"
                        ptype="modal-lg"
                        content={<Terms />}
                        handleClose={this.handler1}
                    />
                )}
                {popup2 && (
                    <Popup
                        title="FAQs for EasyMembr:"
                        ptype="modal-lg"
                        content={<Faqs />}
                        handleClose={this.handler2}
                    />
                )}
                {/* Footer End  */}
            </React.Fragment>
        );
    }
}

export default Footer;
