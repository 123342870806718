import React, { useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';
import copy from 'clipboard-copy';
import deleteImg from "./../components/images/delete.svg";
import { updatePaymentLinkSetting, getPaymentLinkSetting, confirmPaymentLink, } from "../services/apiServices.js";
import {
    MDBAlert,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBIcon
} from 'mdb-react-ui-kit';

import {
    getGBussinessInfo,
    logEvent,
    saveGBussinessInfo
} from "../services/apiServices.js";
import {
    isEmail,
    isEmpty,
    isValidPhoneNumber
} from "../validators/helper.js";
import PageError from "./PageError";
import { googleAnalytic } from "../components/googleAnalytics";

function PaymentMethods(props) {


    const [ddgst, setDdgst] = useState(true);
    const [error, setError] = useState({});
    const [dpaymethods, setDpaymethods] = useState([]);
    const [userpaymethods, setUserpaymethods] = useState([]);
    const [selectedUOpts, setselecteUOpts] = useState([]);
    const [dgsttax, setDgsttax] = useState([]);
    const [ugsttax, setUgsttax] = useState([]);
    const [gstopt, setGstopt] = useState([]);
    const [emailrem, setEmailrem] = useState([]);
    const [smsrem, setSmsrem] = useState([]);
    const [can, setCan] = useState(false)
    const [businessForm, setbusinessForm] = useState({
        businessName: "",
        businessEmail: "",
        firstName: "",
        lastName: "",
        contactNumber: "",
        memberCapacity: "",
        state: "",
        city: "",
        address: "",
        prefix: "",
        dpaymethods: dpaymethods,
        userpaymethods: userpaymethods,
        dgsttax: dgsttax,
        ugsttax: ugsttax,
        shortcode: "",
        emailrem: emailrem,
        smsrem: smsrem,
        emailinter: "h",
        smsinter: "h",
        timezone: null,
    });
    const [form, setForm] = useState({
        merchantid: "",
        merchantkey: "",
        merchantindex: "",
        status: 0,
        toggle: false,
    });
    const [emptyErrorPaymentLink, setEmptyErrorPaymentLink] = useState(false);
    const [Error2, setError2] = useState("")
    const [apiError, setApiError] = useState(false);
    function updateForm(value) {
        return setForm((prev) => {
            if (!('status' in value) || ('toggle' in value)) {
                return { ...prev, ...value, ...{ status: 0 } };
            }
            return { ...prev, ...value };
        });
    }
    useEffect(() => {
        async function fetchData() {
            if (localStorage.getItem("loggedUserInfo")) {
                const response = await getGBussinessInfo(
                    JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
                );

                if (!response) {
                
                    return;
                }
                if (response === "false") {
                    setApiError(true);
                    return;
                } else {
                    const record = await response.json();
                    if (!record) {
                        return;
                    }
                    setbusinessForm(record);
                    setEmailrem(record.emailrem);
                    setSmsrem(record.smsrem);
                    setDpaymethods(record.dpaymethods);
                    setDgsttax(record.dgsttax);
                    if (record.ddgst === false) {
                        setDdgst(false);
                    }
                    let temOpts = [];
                    record.userpaymethods.forEach((opt) => {
                        if (opt.check) {
                            temOpts.push(opt._id);
                        }
                    });
                    setselecteUOpts(temOpts);
                    setUserpaymethods(record.userpaymethods);
                    let gsttaxoption = [];
                    record.ugsttax.forEach((opt) => {
                        if (opt.check) {
                            gsttaxoption.push(opt._id);
                        }
                    });
                    setGstopt(gsttaxoption);
                    setUgsttax(record.ugsttax);
                }
                await fetchPaymentLink();
            }
        }
        fetchData();
    }, []);
    async function fetchPaymentLink() {
        const paymentLinkSetting = await getPaymentLinkSetting(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
        if (!paymentLinkSetting) {
            return;
        }
        const paymentLinkSettings = await paymentLinkSetting.json();
        if (paymentLinkSettings.gymid) {
            console.log(paymentLinkSettings);
            setForm(paymentLinkSettings);
        }
    }
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    let validateForm = (e) => {
        console.log("Validating form...");
        let errors = {};
        if (isEmpty(businessForm.businessName)) {
            errors.businessname = "Business Name can't be blank";
        }
        if (isEmpty(businessForm.businessEmail)) {
            errors.businessEmail = "Email can't be blank";
        } else if (!isEmail(businessForm.businessEmail)) {
            errors.businessEmail = "Please enter valid email";
        }
        if (isEmpty(businessForm.firstName)) {
            errors.firstname = "First Name can't be blank";
        }
        if (isEmpty(businessForm.lastName)) {
            errors.lastName = "Last Name can't be blank";
        }
        if (isEmpty(businessForm.contactNumber)) {
            errors.contactNumber = "Contact Number can't be blank";
        } else if (!isValidPhoneNumber(businessForm.contactNumber)) {
            errors.contactNumber = "Please enter valid Contact Number";
        }
        // if (isEmpty(businessForm.memberCapacity)) {
        //   errors.memberCapacity = "Member Capacity can't be blank";
        // }

        if (isEmpty(businessForm.city)) {
            errors.city = "City can't be blank";
        }
        if (isEmpty(businessForm.address)) {
            errors.address = "Address can't be blank";
        }
        userpaymethods.forEach((item, index) => {
            if (!item.title) {
                errors[`title_${index}`] = `Title  can't be blank`;
            }
            else if (!isAlphabetical(item.title)) {
                errors[`title_${index}`] = `Title  should contain only alphabetical characters`;
            }
        });
        // if (isEmpty(businessForm.prefix)) {
        //   errors.prefix = "Transaction id prefix can't be blank";
        // }

        if (isEmpty(errors)) {
            return true;
        } else {
            return errors;
        }
    };
    function addNewRow(e) {
        e.preventDefault();
        if (userpaymethods.length >= 10) {
            window.alert("Maximum limit reached");
            return;
        }
        setUserpaymethods([...userpaymethods, { check: "", title: "", label: "" }]);
    }
    function deleteRow(e, index) {
        e.preventDefault();
        setUserpaymethods((current) =>
            current.filter((obj, tindex) => tindex !== index)
        );
        setError((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[`title_${index}`];
            return newErrors;
        });
    }

    const isAlphabetical = (str) => /^[a-zA-Z]+$/.test(str);
    function processCancel() {
        setCan(!can)
        window.location.href = "/bInfo";
    }
    function addMethod(e, tid, isdded) {
        try {
            console.log(isdded);
            let ischecked = isdded ? false : true;
            console.log("ischecked :: " + ischecked);
            if (ischecked) {
                setDpaymethods([...dpaymethods, tid]);
            } else {
                const nmethods = dpaymethods.filter((item) => item !== tid);
                setDpaymethods(nmethods);
            }
        } catch (e) {
            console.log("Error ::::::::::;; " + e);
        }
    }

    function addUMethod(e, tid, isdded) {
        try {
            //e.preventDefault();
            console.log(isdded);
            let ischecked = isdded ? false : true;
            console.log("ischecked :: " + ischecked);
            if (ischecked) {
                setselecteUOpts([...selectedUOpts, tid]);
            } else {
                const nmethods = selectedUOpts.filter((item) => item !== tid);
                setselecteUOpts(nmethods);
            }
            //updateForm({ dpaymethods: dpaymethods });
        } catch (e) {
            console.log("Error ::::::::::;; " + e);
        }
    }

    function updateTitle(obj) {
        setUserpaymethods((prevState) => {
            const newState = prevState.map((eachObj, index) => {
                if (index === obj.index) {
                    return {
                        _id: eachObj._id,
                        check: eachObj.check,
                        title: obj.title,
                        label: eachObj.label,
                    };
                }
                // 👇️ otherwise return object as is
                return eachObj;
            });
            return newState;
        });
    }
    async function submitPaymentLinkData(transactionId) {
        try {
            let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
            const updates = {
                gymid: gymid,
                merchantid: form.merchantid,
                merchantkey: form.merchantkey,
                merchantindex: form.merchantindex,
                status: 2,
                toggle: form.toggle,
            };
            if (transactionId != null) {
                updates.transactionId = transactionId;
            }
            let saverep = await updatePaymentLinkSetting(businessForm._id, updates);
            const recinfo = await saverep.json();
            console.log(recinfo)
            return;
        } catch (e) {
            console.log(e)
            return;
        }
    }

    function generateUniqueKey(length) {
        const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let uniqueKey = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            uniqueKey += characters.charAt(randomIndex);
        }
        return uniqueKey;
    }
    async function confirmPaymentLinkHandler(e) {
        e.preventDefault();
        if (form.merchantid === "" || form.merchantkey === "" || form.merchantindex === "") {
            setEmptyErrorPaymentLink(true);
            return;
        }
        try {
            let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
            let transactionId = generateUniqueKey(18);
            const updates = {
                gymid: gymid,
                merchantid: form.merchantid,
                merchantkey: form.merchantkey,
                merchantindex: form.merchantindex,
                transactionId: transactionId,
                clientUrl: window.location.origin,
            };
            let saverep = await confirmPaymentLink(gymid, updates);
            const recinfo = await saverep.json();
            console.log(recinfo);
            let flag = `Confirm Payment Link Generated Successfully`;
            if (recinfo.message) {
                updateForm({ status: 2 });
                console.log("Confirm payment link generated successfully.");
                console.log("payment Url::", recinfo.message);
                if (window.location.origin.includes('localhost') || window.location.origin.includes('beta')) {
                    copy(recinfo.message);
                    setError2("Payment link was copied in clipboard.");
                } else {
                    setError2("Confirm payment link generated successfully. Please Wait.");
                    window.location.href = recinfo.message; 
                }
            } else {
                setError2("Invalid Merchant Id/Merchant Key");
                flag = `Confirm Generating Payment Link sent Failed`;
                updateForm({ status: 0 })
            }
            await submitPaymentLinkData(transactionId);
            let Eventtype = "Payment Link";
            let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : '';
            let ip = localStorage.getItem("ipaddress");
            let browserinfo = browserName + "-" + browserVersion + ".0.0";
            logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
            return;
        } catch (e) {
            console.log(e)
            return;
        }
    }
    function updateLabel(obj) {
        setUserpaymethods((prevState) => {
            const newState = prevState.map((eachObj, index) => {
                if (index === obj.index) {
                    return {
                        _id: eachObj._id,
                        check: eachObj.check,
                        title: eachObj.title,
                        label: obj.label,
                    };
                }
                return eachObj;
            });
            return newState;
        });
    }
    async function onSubmit(e) {
        e.preventDefault();
        await updatePaymentLinkSetting(businessForm._id, { toggle: form.toggle });
        if (can) {
            console.log("erroorr");
            setCan(false);
            return;
        }
        userpaymethods.forEach((opt) => {
            opt.check = selectedUOpts.filter((item) => item === opt._id).length > 0;
        });
        ugsttax.forEach((opt) => {
            opt.check = gstopt.filter((item) => item === opt._id).length > 0;
        });
        await googleAnalytic("PaymentMethods", 'BussinessInfo', 'Save Button', 'Payment Methods Updated')
        const gstdata = ugsttax.filter((obj) => obj.title !== "" || obj.label !== "")
        const upaydata = userpaymethods.filter((obj) => obj.title !== "" || obj.label !== "")

        const updates = {
            businessName: businessForm.businessName,
            businessEmail: businessForm.businessEmail,
            firstName: businessForm.firstName,
            lastName: businessForm.lastName,
            contactNumber: businessForm.contactNumber,
            memberCapacity: businessForm.memberCapacity,
            ddgst: ddgst,
            city: businessForm.city,
            address: businessForm.address,
            userpaymethods: upaydata,
            dpaymethods: dpaymethods,
            dgsttax: dgsttax,
            ugsttax: gstdata,
            prefix: businessForm.prefix,
            shortcode: businessForm.shortcode,
            emailrem: emailrem,
            smsrem: smsrem,
            emailinter: businessForm.emailinter,
            smsinter: businessForm.smsinter,
            timezone: businessForm.timezone,
        };
        console.log(validateForm(e));
        let errors = validateForm(e);
        if (errors === true) {
            let saverep = await saveGBussinessInfo(updates, businessForm._id);
            await saverep.json();
            let ip = localStorage.getItem("ipaddress");
            let browserinfo = browserName + "-" + browserVersion + ".0.0";
            let flag = "Bussiness Info Updated Successfull";
            let Eventtype = "bussinessinfo";
            let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
            let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
            await logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");
            if (!can)
                localStorage.setItem("business_save", "Profile saved successfully");
            // navigate("/bInfo");
            props.toggleShow()
            window.location.href = "/bInfo";

        } else {
            setError(errors);
        }
    }
    const handleReload = () => {
        window.location.reload();
    }
    return (
        <React.Fragment>
            <>

                {apiError ? <PageError handleReload={handleReload} /> :
                    <form
                        action="#"
                        target="_blank"
                    // onSubmit={onSubmit}
                    >


                        <div className="gym-main rightContent pb-4 pt-0">
                            <div className="BusinessPage__content">

                                <MDBCard className='shadow-0'>
                                    <MDBCardBody className='py-0'>
                                        <MDBCardTitle>
                                            <label>Add New Payment Method</label>

                                            <MDBIcon fas icon="add"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => addNewRow(e)}
                                                className="ripple ripple-surface ripple-surface-light btn btn-primary fs-5" />
                                        </MDBCardTitle>
                                        <MDBCardText>
                                            <div className="payments">
                                                <ul>
                                                    <li className="Business__Details__data">
                                                        <div className="d-flex align-items-center Business__Details__data">

                                                            <button
                                                                type="button"
                                                                className="button-link"
                                                                onClick={(e) =>
                                                                    addMethod(
                                                                        e, 1, dpaymethods.filter((item) => item === 1)[0]
                                                                    )
                                                                }
                                                            >

                                                                <input
                                                                    type="checkbox"
                                                                    checked={dpaymethods.filter((item) => item === 1)[0]}
                                                                />
                                                                <span className="payment-span">Cash</span>
                                                            </button>

                                                        </div>
                                                    </li>
                                                    <li className="Business__Details__data">
                                                        <div className="d-flex align-items-center Business__Details__data">
                                                            <button
                                                                type="button"
                                                                className="button-link"
                                                                onClick={(e) =>
                                                                    addMethod(
                                                                        e,
                                                                        2,
                                                                        dpaymethods.filter((item) => item === 2)[0]
                                                                    )
                                                                }
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        dpaymethods &&
                                                                        dpaymethods.filter((item) => item === 2)[0]
                                                                    }
                                                                />{" "}
                                                                <span className="payment-span">Debit/Credit Card</span>
                                                            </button>
                                                        </div>
                                                    </li>
                                                    <li className="Business__Details__data">
                                                        <div className="d-flex align-items-center Business__Details__data">
                                                            <button
                                                                type="button"
                                                                className="button-link"
                                                                onClick={(e) =>
                                                                    addMethod(
                                                                        e,
                                                                        3,
                                                                        dpaymethods.filter((item) => item === 3)[0]
                                                                    )
                                                                }
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        dpaymethods &&
                                                                        dpaymethods.filter((item) => item === 3)[0]
                                                                    }
                                                                />{" "}
                                                                <span className="payment-span">UPI</span>
                                                            </button>
                                                        </div>
                                                    </li>
                                                    <li className="Business__Details__data">
                                                        <div className="d-flex align-items-center Business__Details__data">
                                                            <button
                                                                type="button"
                                                                className="button-link"
                                                                onClick={(e) =>
                                                                    addMethod(
                                                                        e,
                                                                        4,
                                                                        dpaymethods.filter((item) => item === 4)[0]
                                                                    )
                                                                }
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        dpaymethods &&
                                                                        dpaymethods.filter((item) => item === 4)[0]
                                                                    }
                                                                />{" "}
                                                                <span className="payment-span">
                                                                    Wallets ( Gpay,PhonePe, Paytm, etc)
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </li>
                                                    <li className="Business__Details__data">
                                                        <div className="d-flex align-items-center Business__Details__data">
                                                            <button
                                                                type="button"
                                                                className="button-link"
                                                                onClick={(e) =>
                                                                    addMethod(
                                                                        e,
                                                                        5,
                                                                        dpaymethods.filter((item) => item === 5)[0]
                                                                    )
                                                                }
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        dpaymethods &&
                                                                        dpaymethods.filter((item) => item === 5)[0]
                                                                    }
                                                                />{" "}
                                                                <span className="payment-span">Coupons</span>{" "}
                                                            </button>
                                                        </div>
                                                    </li>
                                                    {userpaymethods &&
                                                        userpaymethods.map((item, tindex) => (
                                                            <>
                                                                <li className="Business__Details__data">
                                                                    <div className="d-flex align-items-center Business__Details__data">
                                                                        <button
                                                                            type="button"
                                                                            className="button-link"
                                                                            onClick={(e) =>
                                                                                addUMethod(
                                                                                    e,
                                                                                    item._id,
                                                                                    selectedUOpts.filter(
                                                                                        (titem) => titem === item._id
                                                                                    )[0]
                                                                                )
                                                                            }
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={
                                                                                    selectedUOpts.filter(
                                                                                        (opt) => opt === item._id
                                                                                    )[0]
                                                                                }
                                                                            />
                                                                        </button>
                                                                        <span>
                                                                            <input
                                                                                className="payment_methods_input me-3"
                                                                                type="text"
                                                                                value={item.title}
                                                                                onChange={(e) =>
                                                                                    updateTitle({
                                                                                        title: e.target.value,
                                                                                        index: tindex,
                                                                                    })
                                                                                }
                                                                            />
                                                                            {error[`title_${tindex}`] && (
                                                                                <>
                                                                                    <p className="clr-red">{error[`title_${tindex}`]}</p>
                                                                                </>
                                                                            )}
                                                                        </span>{" "}
                                                                        <span>
                                                                            <input
                                                                                className="payment_methods_input"
                                                                                type="text"
                                                                                value={item.label}
                                                                                onChange={(e) =>
                                                                                    updateLabel({
                                                                                        label: e.target.value,
                                                                                        index: tindex,
                                                                                    })
                                                                                }
                                                                            />
                                                                        </span>
                                                                        <span>
                                                                            <button
                                                                                type="button"
                                                                                className="ml20 button-link">
                                                                                <img
                                                                                    src={deleteImg}
                                                                                    width="15px"
                                                                                    alt="Delete"
                                                                                    onClick={(e) =>
                                                                                        deleteRow(e, tindex)
                                                                                    }
                                                                                />
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        ))}
                                                </ul>

                                                <h5 className="card-title">
                                                    <label>Phonepe Payment Link</label>
                                                    <div className="phoneselect">
                                                        <label className="m-0 ms-2 switch"
                                                            onChange={(e) => updateForm({ toggle: !form.toggle })}
                                                        >
                                                            <input className="form-control" type="checkbox" checked={form.toggle} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                </h5>
                                                {form.toggle && <><div><div className=" Business__Details__data">
                                                    <label>Merchant ID:<span className="text-danger">*</span></label>
                                                    <label className="fw-600">
                                                        <input className="Business__input__adjust" type="text" name="bussiness_name" id="bussiness_name" placeholder="Merchant Id" 
                                                            onChange={(e) => updateForm({ merchantid: e.target.value })}
                                                            value={form.merchantid} />
                                                        {emptyErrorPaymentLink && form.merchantid === "" && (
                                                            <>
                                                                <p className="clr-red">Field can't be empty. </p>
                                                            </>
                                                        )}
                                                    </label>
                                                </div>
                                                    <div className=" Business__Details__data">
                                                        <label>Salt Key:<span className="text-danger">*</span></label>
                                                        <label className="fw-600">
                                                            <input className="Business__input__adjust" type="text" name="bussiness_name" id="bussiness_name" placeholder="Salt Key"
                                                                onChange={(e) => updateForm({ merchantkey: e.target.value })}
                                                                value={form.merchantkey} />
                                                            {emptyErrorPaymentLink && form.merchantkey === "" && (
                                                                <>
                                                                    <p className="clr-red">Field can't be empty. </p>
                                                                </>
                                                            )}
                                                        </label>
                                                    </div>
                                                    <div className=" Business__Details__data">
                                                        <label>Salt Index<span className="text-danger">*</span></label>
                                                        <label className="fw-600">
                                                            <input className="Business__input__adjust" type="text" name="bussiness_name" id="bussiness_name" placeholder="Salt Index"
                                                                onChange={(e) => updateForm({ merchantindex: e.target.value })}
                                                                value={form.merchantindex} />
                                                            {emptyErrorPaymentLink && form.merchantindex === "" && (
                                                                <>
                                                                    <p className="clr-red">Field can't be empty. </p>
                                                                </>
                                                            )}
                                                        </label>
                                                    </div>
                                                    {form.status === 1 ?
                                                        <label className="clr-green">
                                                            <MDBIcon fas icon="check" /> Confirmed
                                                        </label> :
                                                        (form.status === 2 ?
                                                            <label className="clr-orange">
                                                                <MDBIcon far icon="clock" /> Waiting For Confirm
                                                            </label> :
                                                            <button className="btn btn-primary me-0 mt-3 mb-2" onClick={(e) => { confirmPaymentLinkHandler(e) }} >
                                                                Pay yourself
                                                            </button>)}
                                                </div>
                                                    {form.status == 0 && <>
                                                        Note: Send Rs.100/- to your account for confirmation.
                                                    </>}
                                                </>}
                                            </div>

                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </div>
                        </div>


                        <div className="modal-footer pb-0">
                            <button className="ripple ripple-surface btn btn-secondary" onClick={() => processCancel()} >
                            Cancel
                        </button>
                            <button className="ripple ripple-surface btn btn-primary" type="submit" onClick={(e) => onSubmit(e)}
                                disabled={form.status != 1 && form.toggle}
                            >
                                Save
                            </button>
                        </div>
                    </form>}
                <MDBAlert
                    color={Error2.includes("ailed") ? "danger" : "success"}
                    autohide
                    position='top-right'
                    delay={3000}
                    appendToBody
                    open={Error2 !== ""}
                    onClose={() => setError2("")}
                >
                    {Error2}
                </MDBAlert>
            </>
        </React.Fragment>
    )
}
export default PaymentMethods