import { React, useEffect, useState } from "react";
import AddonProps from "./AddonProps";

export default function Addons(props) {
  const [showAddonProps, setshowAddonProps] = useState(false);
  const [selectedselItems, setSeletectedselItems] = useState(props.selItems);
  const [addonProps, setAddonProps] = useState({});
  const [addonid, setAddonid] = useState("");
  const [propName, setPropName] = useState("");
  const [addonOBj, setAddonObj] = useState({});
  const [rentBasis, setRentBasis] = useState("1");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setSeletectedselItems(props.selItems);
  }, [props, selectedselItems]);

  function callbackfunction(event, selItems, checked) {
    console.log("in Addons...");
    console.log(selItems);
    props.addonaddCallback(event, selItems, checked);
  }

  function showProperties(event, itemId) {
    if (event) {
      event.preventDefault();
    }

    setAddonid(itemId);
    setshowAddonProps(true);
    let addonOBj = props.addons.find((item) => item._id === itemId);
    setAddonObj(addonOBj);
    setPropName(addonOBj.name);
    setRentBasis(addonOBj.rentbasis);

    let isSelected = selectedselItems.some((item) => item._id === itemId);
    console.log(isSelected);

    setChecked(isSelected);

    setAddonProps(addonOBj.props);
  }
  let plansInfo = localStorage.getItem("planInfo") ? JSON.parse(localStorage.getItem("planInfo")) : null;
  return (
    <div className="col-lg-2 col-sm-12 col-md-5 g-col-4">
      <div className=" payment-board gym-payment-bord">
        <>
          <div className="pe-2 w-100 mob-addons">
            <h5>Addons</h5>
            {props.addons.length <= 0 && (
              <>
                <div className="gym-addons">No addons found</div>
              </>
            )}
            <div className="addOnButtons">
              {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.addons)) ? <>
              {props.addons.length > 0 &&
                props.addons.map((item) => (
                  <div className="addOn-item" key={item._id} onClick={(event) => showProperties(event, item._id)}>
               
                    <button
                            type="button"
                      className="p-4 button-link"
                          >
                               <span
                        className={
                          addonid === item._id ? "highlighted" : ""
                        }
                      > {item.name}
                      </span>
                          </button>
                  </div>
                ))}
              </> : ""}
            </div>
          </div>
          {showAddonProps && (
            <>
              <div className="Addon-Highlight">
                <b>{propName}</b>

                {addonProps.map((titem) => (
                  <AddonProps
                    key={titem._id}
                    item={titem}
                    addonItem={addonOBj}
                    rentbasis={rentBasis}
                    propName={propName}
                    selItems={selectedselItems}
                    addonaddCallback={callbackfunction}
                    checked={checked}
                  />
                ))}
              </div>
            </>
          )}
        </>
        <div className="Addon-btns">
          <button
            type="submit"
            className="btn btn-secondary"
            onClick={(event) => props.cancelThis(event)}
          >
            Cancel
          </button> 
          <button
            type="submit"
            className="btn btn-primary ms-2"
            onClick={(event) => props.checkUserAndForward(event)}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
