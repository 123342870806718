import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon } from "mdb-react-ui-kit";
import ReCAPTCHA from "react-google-recaptcha";
import logImg from "./../components/images/Easy-MembrLogo.png";
import logBg from "./../components/images/loginBg.png";
import PasswordStrengthChecker from "../components/PasswordValidator";
import { isEmpty } from "../validators/helper.js";
import { confirmPassword, checkrecoveryIdCheck } from "../services/apiServices";

const ConfirmPassword = () => {
  const [error, setError] = useState({});
  const [captchaValue, setCaptchaValue] = useState(false);
  const [strongPassword, setstrongPassword] = useState(false);
  const [signupForm, setSignupForm] = useState({
    password: "",
    confirmp: "",
  });
  const [recoveryId, setRecoveryId] = useState(null);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLinkValid, setIsLinkValid] = useState(null);
  const [capSiteKey, setCapSiteKey] = useState("");
  const [domain, setDomain] = useState("");

  const location = useLocation();

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    setDomain(urlObj.hostname);
  }, []);

  useEffect(() => {
    let captcha_ID = "6LfQZGglAAAAAPPUCjiIsnyCvmGisjTTowbcFP3_";
    if (domain === "easymembr.zencruz.com" || domain === "easyemebr-beta.zencruz.com") {
      captcha_ID = "6LcbvooqAAAAADsZ5lZdBiF1dt_ipQQdlTnppmwX";
    }
    setCapSiteKey(captcha_ID)

  }, [domain]);
  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const extractedId = pathParts[pathParts.length - 1];
    if (extractedId) {
      setRecoveryId(decodeURIComponent(extractedId));
    }
  }, [location.pathname]);

  useEffect(() => {
    const validateLink = async () => {
      try {
        const response = await checkrecoveryIdCheck({ recoveryId });
        if (response && response.status === 200) {
          setIsLinkValid(true);
        } else {
          setIsLinkValid(false);
          setMessage("The password reset link has expired.");
        }
      } catch (error) {
        console.error("Error validating the link:", error);
        setIsLinkValid(false);
        setMessage("An error occurred while validating the link. Please try again later.");
      }
    };

    validateLink();
  }, [recoveryId]);



  const validateForm = () => {
    let errors = {};
    if (isEmpty(signupForm.password)) {
      errors.passwordstrong = "Password can't be blank";
    } else if (signupForm.password.length < 8) {
      errors.passwordstrong = "Password must be at least 8 characters long.";
    } else if (!strongPassword.istrong) {
      errors.passwordstrong = "Please enter a strong password!";
    }
    if (signupForm.password !== signupForm.confirmp) {
      errors.confirmp = "Passwords do not match";
    }
    if (!captchaValue) {
      errors.tempcaptcha = "Please check the CAPTCHA";
    }
    setError(errors);
    return isEmpty(errors);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const updates = { password: signupForm.password, recoveryId };

      try {
        if (!recoveryId) {
          console.error("ID is missing in the URL.");
          return;
        }

        const response = await confirmPassword(updates, recoveryId);
        if (response && response.status === 200) {
          setMessage("Password successfully changed.");
          setSuccess(true);
        } else {
          setMessage("The password link has expired. Please retry again.");
          setSuccess(false);
        }
      } catch (e) {
        console.error("Error during password confirmation:", e);
        setMessage("An error occurred. Please try again.");
      }
    }
  };

  function setPasswordStatus(tflag) {
    setstrongPassword(tflag);
  }

  const updateForm = (value) =>
    setSignupForm((prev) => ({ ...prev, ...value }));

  const verifyCaptchaCallback = (resp) => setCaptchaValue(!!resp);

  return (
    <React.Fragment>
      <div className="back-to-home">
        <Link to="/">
          <MDBBtn>
            <MDBIcon fas icon="arrow-left" />
          </MDBBtn>
        </Link>
      </div>

      <section className="cover-user bg-white">
        <div className="container-fluid px-0">
          <Row className="g-0 position-relative">
            <Col lg={4} className="cover-my-30">
              <div className="cover-user-img d-flex align-items-center justify-content-center">
                <Row>
                  <Col className="d-flex flex-column auth-hero">
                    <div className="my-md-5 pt-md-5 text-center">
                      <img src={logImg} className="gymlogo1 mt-md-5" alt="" />
                    </div>
                    <MDBCard className="border border-success w-100 mb-2">
                      <MDBCardBody>
                        <MDBCardTitle>Reset Password</MDBCardTitle>
                        <Row>
                          {isLinkValid === false ? (
                            <Col lg={12}>
                              <p className="text-danger">{message}</p>
                              <Link to="/getresetPassword" className="btn btn-secondary">
                                Request New Link
                              </Link>
                            </Col>
                          ) : (
                            !success && (
                              <>
                                <Col lg={12}>
                                  <PasswordStrengthChecker
                                    setPasswordInput={updateForm}
                                    setPasswordStatus={setPasswordStatus}
                                  />
                                  {error.passwordstrong && (
                                    <p className="clr-red">{error.passwordstrong}</p>
                                  )}
                                </Col>
                                <Col lg={12}>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Confirm Password<span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder="Confirm Password"
                                      onChange={(e) => updateForm({ confirmp: e.target.value })}
                                      value={signupForm.confirmp}
                                      required
                                    />
                                    {error.confirmp && (
                                      <p className="clr-red">{error.confirmp}</p>
                                    )}
                                  </div>
                                </Col>
                                <Col lg={12}>
                                  <div className="mb-3">
                                    <ReCAPTCHA
                                      className="mt-3"
                                      sitekey={"6LfQZGglAAAAAPPUCjiIsnyCvmGisjTTowbcFP3_"}
                                      onChange={verifyCaptchaCallback}
                                      theme="light"
                                      size="large"
                                    />
                                  </div>
                                  {error.tempcaptcha && (
                                    <p className="clr-red">{error.tempcaptcha}</p>
                                  )}
                                </Col>
                                <Col lg={12} className="mb-3">
                                  <div className="d-grid">
                                    <button
                                      className="btn btn-primary"
                                      type="submit"
                                      onClick={submitHandler}

                                    >
                                      Submit
                                    </button>
                                  </div>
                                </Col>
                              </>
                            )
                          )}
                          {isLinkValid && message && success && (
                            <Col lg={12}>
                              <p className="mt-3">{message}</p>
                            </Col>
                          )}
                          {isLinkValid && (
                            <Col lg={12}>
                              <div className="d-grid">
                                <Link to="/login" className="btn btn-secondary text-white text-center fw-bold">
                                  Back to Login
                                </Link>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </MDBCardBody>

                    </MDBCard>
                    <div className="mb-md-5 text-center">
                      <p className="mb-0 text-muted">
                        © {new Date().getFullYear()} Zencruz.
                      </p>
                    </div>
                  </Col>

                </Row>

              </div>

            </Col>
            <Col lg={8} className="padding-less img" style={{ backgroundImage: `url(${logBg})` }}>
              <div className="greenoverlay"></div>
            </Col>
          </Row>

        </div>
      </section>
    </React.Fragment>
  );
};


export default ConfirmPassword;
