import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBDatatable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';
import { useNavigate } from "react-router";
import Toast from "../components/Toast";
import { cancelSubscriptionPlan, getAdminById, getAllPricingPlans, getSubscriptionPlans, saveAdmin } from "../services/apiServices";
import { logEvent } from "../services/apiServices.js";
import {
  isEmpty,
  isLength,
  isValidAge,
  isValidPhoneNumber,
} from "../validators/helper.js";
import PageError from "./PageError";
import { googleAnalytic } from "../components/googleAnalytics";
import ExcelExportButton from "../components/exportToExcel";

const Settings = (props) => {
  const [error, setError] = useState({});
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    age: "",
    gender: "",
    number: "",
  });
  const [PlansData, setPlansData] = useState([]);
  const navigate = useNavigate();
  const [smessage, setSMessage] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [basicData, setBasicData] = useState({
    columns: ["S.no", 'Date', "Plan", 'Module', 'Duration', "Start Date", 'End Date', 'Amount'],
    rows: [],
  })
  const [subPlans, setSubPlans] = useState([])
  const [activeSubPlan, setActiveSubPlan] = useState(null)
  const handleReload = () => {
    window.location.reload();
  }

  async function fetchPData() {
    if (localStorage.getItem("loggedUserInfo")) {
      const response = await getSubscriptionPlans(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
      if (!response) {
        return;
      }
      if (response === "false") {
        setApiError(true)
      } else {
        const record = await response.json();
        if (!record) {
          navigate("/");
          return;
        }
        setSubPlans(record);
        if (record.length > 0) {
          setActiveSubPlan(record[0]);
        }
      }
    }
  }

  useEffect(() => {
    fetchPData();
  }, [PlansData]);

  useEffect(() => {
    fetchPData();
    async function fetchData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getAdminById(
          JSON.parse(localStorage.getItem("loggedUserInfo"))._id
        );
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
        } else {
          const record = await response.json();
          if (!record) {
            navigate("/");
            return;
          }
          if (localStorage.getItem("setting_save")) {
            setSMessage(localStorage.getItem("setting_save"));
            setTimeout(() => {
              setSMessage(null);
              localStorage.removeItem("setting_save");
            }, 5000);
          }
          setForm(record);
          if (!record.gender) {
            updateForm({ gender: "Male" });
          }
        }
      } else {
        navigate("/login");
      }
    }
    fetchData();
  }, []);

  const isAlphabetical = (str) => /^[a-zA-Z]+$/.test(str);

  let validateForm = (e) => {
    console.log("Validating form...");
    let errors = {};
    if (isEmpty(form.firstname)) {
      errors.firstname = "First Name can't be blank";
    } else if (!isAlphabetical(form.firstname)) {
      errors.firstname = `Name should contain only alphabetical characters`;
    }
    if (isEmpty(form.lastname)) {
      errors.lastname = "Last can't be blank";
    } else if (!isAlphabetical(form.lastname)) {
      errors.lastname = `Name should contain only alphabetical characters`;
    }

    // if (isEmpty(form.age)) {
    //   errors.age = "Age can't be blank";
    // } else if (!isLength(form.age, { gte: 1, lte: 3, trim: true })) {
    //   errors.age = "Age must be between 15 and 100";
    // } else if (!isValidAge(form.age)) {
    //   errors.age = "Please enter valid Age";
    // }

    if (isEmpty(form.number)) {
      errors.number = "Contact Number can't be blank";
    } else if (!isValidPhoneNumber(form.number)) {
      errors.number = "Please enter valid Contact Number";
    }

    console.log(errors);
    if (isEmpty(errors)) {
      return true;
    } else {
      return errors;
    }
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }
  function processCancel() {
    window.location.href = "/settingview";
  }
  async function onSubmit(e) {

    e.preventDefault();
    const updates = {
      fname: form.firstname,
      lname: form.lastname,
      age: form.age,
      gender: form.gender,
      number: form.number,
      _id: localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo"))._id : "",
    };
    console.log(updates);
    let errors = validateForm(e);
    if (errors === true) {
      let saverep = await saveAdmin(
        updates,
        localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo"))._id : ""
      );
      localStorage.setItem("setting_save", "Profile saved successfully");
      console.log(await saverep.json());
      let lObj = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")) : "";
      lObj.name = updates.fname + " " + updates.lname;

      let ip = localStorage.getItem("ipaddress");

      let browserinfo = browserName + "-" + browserVersion + ".0.0";
      let flag = "Settings Changed Successfull";
      let Eventtype = "setting";
      let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
      let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
      await googleAnalytic("Settings", 'Settings', 'Save Button', 'Settings')
      logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");
      localStorage.setItem("loggedUserInfo", JSON.stringify(lObj));
      window.location.href = "/settingview";
    } else {
      setError(errors);
    }
  }

  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = () => {
    setBasicModal(!basicModal)
  };
  const [basicActive, setBasicActive] = useState('tab1');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  const [centredModal, setCentredModal] = useState(false);

  const toggleOpenPlan = () => setCentredModal(!centredModal);

  const updateModules = (Plan) => {
    let modules = [];
    if (Plan !== null && Plan.plandetails) {
      if (Plan.plandetails.smsintergration) {
        modules.push("SMS Integration");
      }
      if (Plan.plandetails.whatsappintegration) {
        modules.push("WhatsApp Integration");
      }
      if (Plan.plandetails.accountantrole) {
        modules.push("Accountant Role");
      }
      if (Plan.plandetails.memberportal) {
        modules.push("Member Portal");
      }
    }
    return modules.join(", ");
  }
  useEffect(() => {
    async function fetchData() {
      const response = await getAllPricingPlans();
      if (!response || response == 'false') {
        return;
      }
      let recinfo = await response.json();
      if (recinfo && recinfo.pricingPlans) {
        let filterData = recinfo.pricingPlans.filter((obj) => obj.availability == true);
        console.log(filterData);
        setPlansData(filterData);
      }
    }
    fetchData();
  }, []);

  const cancelHandler = async (e) => {
    e.preventDefault();
    let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
    let updates = {
      subscriptionId: activeSubPlan.subscriptionid
    }
    const response = await cancelSubscriptionPlan(gymid, updates);
    if (!response || response == 'false') {
      console.log("Subscription cancelled successfully");
      return;
    }
    const res = await response.json();
    console.log(res);
  }

  useEffect(() => {
    const popupRow = [];
    let snumber = 1
    subPlans && subPlans.length > 0 && subPlans.forEach((item, index) => {
      const rarray = [];
      rarray.push(snumber++)
      rarray.push(item.createdAt ? new Date(item.createdAt).toLocaleDateString('en-US') : "--");
      rarray.push(PlansData.find((obj) => obj._id === item.plandetails.id)?.name || "");
      rarray.push(updateModules(item) ? updateModules(item) : "--");
      rarray.push(
        item.subscriptionfrequency ? `${item.subscriptionfrequency} months` : "--"
      );
      rarray.push(item.subscriptionstartdate ? new Date(item.subscriptionstartdate).toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
      }) : "--");
      rarray.push(item.subscriptionexpirydate ? new Date(item.subscriptionexpirydate).toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
      }) : "--");
      rarray.push(item.amount && item.amount);
      popupRow.push(rarray);
    })

    setBasicData({
      columns: ["S.no", 'Date', "Plan", 'Module', 'Duration', "Start Date", 'End Date', 'Amount'],
      rows: popupRow
    })
  }, [subPlans]);

  const downloaddata = (data, title) => {
    ExcelExportButton(data, title);
  }
  console.log(activeSubPlan)
  return (
    <>
      <LeftPane isactive="9" />
      <Header />
      {apiError ? <PageError handleReload={handleReload} /> :
        <div
          className="gym-main rightContent"
        >

          <h3 class="gym-large mb-3">Manage Account</h3>
          <MDBTabs className='mb-3'>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                Profile Settings
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                Subscription Plan
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
          <MDBTabsContent>
            <MDBTabsPane open={basicActive === 'tab1'}>
              <div className="w-50 border rounded-4 a p-4 pe-5 shadow-4">
                <MDBCardTitle className="text-dark d-flex justify-content-between">
                  <label>Profile Settings</label>
                  <button className="edit-Btn"
                    title="Edit" onClick={(e) => toggleOpen()}>
                    <MDBIcon fas icon="pencil-alt" className="fs-6 me-2" /> Edit
                  </button>
                </MDBCardTitle>
                <MDBCardText>
                  <div className=" Business__Details__data  mt-2">
                    <label>First Name:<span className="text-danger">*</span></label> <label className="fw-600">{form.firstname}</label>
                  </div>
                  <div className=" Business__Details__data  mt-1">
                    <label>Last Name:<span className="text-danger">*</span></label> <label className="fw-600">{form.lastname}</label>
                  </div>
                  <div className=" Business__Details__data  mt-1">
                    <label>Age:</label> <label className="fw-600">{form.age}</label>
                  </div>
                  <div className=" Business__Details__data  mt-1">
                    <label>Gender:<span className="text-danger">*</span></label> <label className="fw-600">{form.gender}</label>
                  </div>
                  <div className=" Business__Details__data  mt-1">
                    <label>Mobile Number:<span className="text-danger">*</span></label> <label className="fw-600">{form.number}</label>
                  </div>
                </MDBCardText>
              </div>

            </MDBTabsPane>
            <MDBTabsPane open={basicActive === 'tab2'}>
              <div className="w-50 border rounded-4 a p-4 pe-5 shadow-4">
                <MDBCardTitle className="text-dark d-flex justify-content-between">
                  <label>Subscription Plan</label>
                  <div>
                    {/* <button className="edit-Btn clr-red" title="Edit" onClick={(e) => cancelHandler(e)}>
                      Cancel Subscription
                    </button> */}
                    <button className="edit-Btn ms-2" title="Edit" onClick={(e) => toggleOpenPlan()}>
                      View History
                    </button>
                  </div>
                </MDBCardTitle>
                <MDBCardText>
                  <div className="Business__Details__data mt-2">
                    <label>Plan Name</label>
                    <label className="fw-600">{activeSubPlan && (PlansData.find((obj) => obj._id === activeSubPlan.plandetails.id)?.name) || ""}</label>
                  </div>
                  <div className="Business__Details__data mt-1">
                    <label>Additional Addons</label>
                    <label className="fw-600">
                      {updateModules(activeSubPlan) ? updateModules(activeSubPlan) : "--"}
                    </label>
                  </div>
                  <div className="Business__Details__data mt-1">
                    <label>Status</label>
                    <label className="fw-600">
                      {activeSubPlan ? (activeSubPlan.status == 1 ? "Active" : (activeSubPlan.status == 0 ? "Inactive" : (activeSubPlan.status == 2 ? "Pending" : (activeSubPlan.status == 3 ? "Canceled" : "")))) : ""}
                    </label>
                  </div>
                  <div className="Business__Details__data mt-1">
                    <label>Auto Payment</label>
                    <label className="fw-600">
                      {activeSubPlan && activeSubPlan.autopay ? "Enabled" : "Disabled"}
                    </label>
                  </div>
                  <div className="Business__Details__data mt-1">
                    <label>Billing Period</label>
                    <label className="fw-600">
                      {activeSubPlan && activeSubPlan.subscriptionfrequency} {`Month(s)`}
                    </label>
                  </div>
                  <div className="Business__Details__data mt-1">
                    <label>Start Date</label>
                    <label className="fw-600">
                      {activeSubPlan && activeSubPlan.subscriptionstartdate ? new Date(activeSubPlan.subscriptionstartdate).toLocaleDateString('en-IN') : "--"}
                    </label>
                  </div>
                  <div className="Business__Details__data mt-1">
                    <label>End Date</label>
                    <label className="fw-600">
                      {activeSubPlan && activeSubPlan.subscriptionexpirydate ? new Date(activeSubPlan.subscriptionexpirydate).toLocaleDateString('en-IN') : "--"}
                    </label>
                  </div>
                </MDBCardText>
              </div>
            </MDBTabsPane>
          </MDBTabsContent>
          {smessage && (
            <h4>
              <Toast message={smessage} />
            </h4>
          )}
          <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
            <MDBModalDialog>
              {/* <form
              action="#"
              target="_blank"
              onSubmit={onSubmit}
              className="business-form w-100"
            > */}
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Edit Profile</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={processCancel}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="BusinessPage__content">
                    <MDBCard className="shadow-0">
                      <MDBCardBody className=" py-0">
                        <MDBCardTitle>
                        </MDBCardTitle>
                        <MDBCardText>
                          <MDBRow>

                            <div className=" Business__Details__data">
                              <label>First Name:<span className="text-danger">*</span></label> <label className="fw-600">
                                <input
                                  className="Business__input__adjust"
                                  type="text"
                                  name="firstname"
                                  id="firs_name"
                                  placeholder="First Name"
                                  value={form.firstname}
                                  onChange={(e) =>
                                    updateForm({ firstname: e.target.value })
                                  }
                                />
                                {error.firstname && (
                                  <>
                                    <p className="clr-red">{error.firstname}</p>
                                  </>
                                )}

                              </label>
                            </div>
                            <div className=" Business__Details__data">
                              <label>Last Name:<span className="text-danger">*</span></label> <label className="fw-600">
                                <input
                                  className="Business__input__adjust"
                                  type="text"
                                  name="lastname"
                                  id="last_name"
                                  placeholder="Last Name"
                                  value={form.lastname}
                                  onChange={(e) => updateForm({ lastname: e.target.value })}
                                />
                                {error.lastname && (
                                  <>
                                    <p className="clr-red">{error.lastname}</p>
                                  </>
                                )}

                              </label>
                            </div>
                            <div className=" Business__Details__data">
                              <label>Age:</label> <label className="fw-600">
                                <input
                                  className="Business__input__adjust"
                                  type="text"
                                  name="age"
                                  id="age"
                                  placeholder="Age"
                                  value={form.age}
                                  onChange={(e) => updateForm({ age: e.target.value })}
                                />
                              </label>
                            </div>
                            <div className=" Business__Details__data">
                              <label>Gender:<span className="text-danger">*</span></label> <label className="fw-600">

                                <select
                                  className="Business__input__adjust"
                                  name="gender"
                                  id="time"
                                  value={form.gender}
                                  onChange={(e) => updateForm({ gender: e.target.value })}
                                >
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </label>
                            </div>

                            <div className=" Business__Details__data">
                              <label>Mobile Number:<span className="text-danger">*</span></label> <label className="fw-600">
                                <input
                                  className="Business__input__adjust"
                                  type="text"
                                  name="contact                                                                                                                                                          s_name"
                                  // id="contact"
                                  placeholder="Number"
                                  value={form.number}
                                  onChange={(e) => updateForm({ number: e.target.value })}
                                />
                                {error.number && (
                                  <>
                                    <p className="clr-red">{error.number}</p>
                                  </>
                                )}
                              </label>
                            </div>
                          </MDBRow>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </div>
                </MDBModalBody>

                <MDBModalFooter className="pe-3 pb-2">
                  <MDBBtn color='secondary' onClick={processCancel}>
                    cancel
                  </MDBBtn>
                  <MDBBtn onClick={onSubmit}>Save</MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
              {/* </form> */}
            </MDBModalDialog>
          </MDBModal>


          <MDBModal tabIndex='-1' open={centredModal} onClose={() => setCentredModal(false)}>
            <MDBModalDialog size="lg">
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Suscription History</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={toggleOpenPlan}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBDatatable entries={25} data={basicData} />
                </MDBModalBody>
                <MDBModalFooter className="pe-3 pb-2">
                  <MDBBtn color='secondary' onClick={toggleOpenPlan}>
                    Close
                  </MDBBtn>
                  <MDBBtn className="ms-2" onClick={() => downloaddata(basicData, "subscriptionPlans")}>download</MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>

        </div>}
    </>
  );
};
export default Settings;