import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { Link } from 'react-router-dom';
import { CardBody, Col, Row } from "reactstrap";
import { numberSignUp } from "../services/apiServices";
// import logImg from "./../components/images/GymLogo.png";
import logImg from "./../components/images/Easy-MembrLogo.png";
import logBg from "./../components/images/loginBg.png";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBIcon } from "mdb-react-ui-kit";

const OTPValidation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [phnumber, setPhnumber] = useState("");

    const [otp, setOtp] = useState("");
    const [notp, setNotp] = useState("");
    const [error, setError] = useState("");

    const [userExists, setUserExists] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const returnUrl = urlParams.get("returnUrl");
        if (returnUrl) {
            localStorage.setItem("returnUrl", decodeURIComponent(returnUrl));
        }
    }, [location.search]);
    const params = new URLSearchParams(location.search);
    const planId = params.get('id');

    const handleBackClick = () => {
        const returnUrl = localStorage.getItem("returnUrl");
        console.log(returnUrl);

        if (returnUrl) {
            window.location.href = returnUrl
            localStorage.removeItem("returnUrl");
        } else {
            navigate("/");
        }
    };


    const validation = async (e) => {
        e.preventDefault();
        console.log(phnumber);
        if (otp !== notp) {
            setError("Invalid OTP.Please try again.");
        }
        else {
            localStorage.setItem("number", phnumber);
            if (planId) {
                navigate(`/billingsubscriptionplan?id=${planId}`);
            } else {
                navigate(`/signup`);
            }

        }
        // Other validation logic or API calls can go here
    }
    const otpfield = async (e) => {
        e.preventDefault();
        if (!isValidPhoneNumber(phnumber)) {
            setError("Please enter valid contact number!");
        }
        else {
            const response = await numberSignUp({ phone: phnumber });
            let resData = await response.json();
            // console.log(resData);
            if (resData.status !== 200) {
                setError(resData.status === 400 ? "Please try again." : "Number already exits in the system!")
            }
            else {
                setError("OTP Sent Sucessfully");
                setUserExists(true);
                setNotp(resData.otp);
            }
        }
    }
    function updateLoginUser(value) {
        // e.preventDefault();
        setPhnumber(value);
        setOtp("");
        setUserExists(false);
        setError("");
    }
    return (
        <React.Fragment>
            <div className="back-to-home">
                <MDBBtn onClick={handleBackClick}>
                    <MDBIcon fas icon="arrow-left" />
                </MDBBtn>
            </div>
            {/* Hero Start */}
            <section className="cover-user bg-white">
                <div className="container-fluid px-0">
                    <Row className="g-0 position-relative">
                        <Col lg={4} className="cover-my-30 ">
                            <div className="cover-user-img d-flex ">
                                <Row className="w-100">
                                    <div className="col-12">

                                        <div className="d-flex flex-column mt-md-5 auth-hero">

                                            <div className="mb-md-5 text-center">
                                                <Link to="#" >
                                                    <img src={logImg} className="gymlogo1 mt-md-5" alt="" />
                                                </Link>
                                            </div>
                                            {/* <h4 class="card-title pb-4">SignUp</h4> */}

                                            <MDBCard className="border border-success w-100">
                                                <MDBCardBody>
                                                    <MDBCardTitle>SignUp - OTP Validation</MDBCardTitle>
                                                    <form className="login-form mt-4"
                                                        onSubmit={(e) => userExists === true ? validation(e) : otpfield(e)}
                                                    >
                                                        <div style={(error === "OTP Sent Sucessfully") ? { color: "green" } : { color: "red" }}>{error}</div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Your Mobile No.<span className="text-danger">*</span></label>
                                                            <PhoneInput
                                                                className="form-control"
                                                                defaultCountry="IN"
                                                                placeholder="Mobile Number"
                                                                value={phnumber}
                                                                disabled={userExists}
                                                                // onInput={(e) => e.target.value = e.target.value.slice(0, 15)}
                                                                onChange={(value) => updateLoginUser(value)}
                                                            />
                                                        </div>

                                                        {userExists && (

                                                            <div className="mb-3">
                                                                <label className="form-label">Enter OTP<span className="text-danger">*</span></label>
                                                                <input type="text"
                                                                    className="form-control" placeholder="Enter OTP"
                                                                    value={otp}
                                                                    onChange={(e) => setOtp(e.target.value)}
                                                                    required />
                                                            </div>
                                                        )}


                                                        <MDBBtn className="w-100 mt-2">{!userExists ? "Get OTP" : "Submit"}</MDBBtn>

                                                        <div className="mx-auto">
                                                            <p className="mb-0 mt-3"><span className="text-dark me-2 font-14px">Already have an account ?</span> <Link to={planId ? `/login?id=${planId}` : `/login`} className="text-dark fw-bold">Login</Link></p>
                                                        </div>
                                                    </form>

                                                </MDBCardBody>
                                            </MDBCard>

                                            <div className="footer mb-md-5 mt-2 text-center">
                                                <p className="mb-0 text-muted">© {(new Date().getFullYear())}{" "} Zencruz.</p>
                                            </div>







                                            {/* <div className="title-heading my-lg-auto">
                                                <div className="border-0">
                                                    <CardBody className="p-0 margin__signuo">
                                                        <h4 className="card-title">OTP Validation</h4>
                                                        <form className="login-form mt-4"
                                                            onSubmit={(e) => userExists === true ? validation(e) : otpfield(e)}
                                                        >
                                                            <div style={(error === "OTP Sent Sucessfully") ? { color: "green" } : { color: "red" }}>{error}</div>
                                                            <Row>
                                                                <Col md={12} >
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Your Mobile No.<span className="text-danger">*</span></label>

                                                                        <PhoneInput
                                                                            className="form-control"
                                                                            defaultCountry="IN"
                                                                            placeholder="Mobile Number"
                                                                            value={phnumber}
                                                                            disabled={userExists}
                                                                            // onInput={(e) => e.target.value = e.target.value.slice(0, 15)}
                                                                            onChange={(value) => updateLoginUser(value)}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                {userExists && (

                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Enter OTP<span className="text-danger">*</span></label>
                                                                            <input type="text"
                                                                                className="form-control" placeholder="Enter OTP"
                                                                                value={otp}
                                                                                onChange={(e) => setOtp(e.target.value)}
                                                                                required />
                                                                        </div>
                                                                    </Col>
                                                                )}
                                                                <div className="col-lg-12 mb-0 mt-4">
                                                                    <div className="d-grid">
                                                                        <button type="submit" className="btn btn-primary">{!userExists ? "Get OTP" : "Submit"}</button>
                                                                    </div>
                                                                </div>

                                                            </Row>
                                                        </form>
                                                    </CardBody>
                                                </div>
                                            </div> */}

                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </Col>
                        <div className="col-lg-8  padding-less img" style={{ backgroundImage: `url(${logBg})` }} data-jarallax='{"speed": 0.5}'>
                            <div className="greenoverlay"></div>
                        </div>
                    </Row>
                </div>
            </section>
            {/* end section */}
        </React.Fragment>
    );
};

export default OTPValidation;
