import { MDBDatatable, MDBIcon } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { getEmailTEMP } from '../services/apiServices';
import PageError from './PageError';
import ReactGA from 'react-ga';

function EmailTempletes() {
    const [tempData, setTempData] = useState(null);
    const [content, setContent] = useState(``);
    const [apiError, setApiError] = useState(false);

    const [emailSettingsData, setEmailSettingsData] = useState({
        columns: [
            { label: 'EMAIL Templetes', field: 'name' },
            { label: 'ACTIONS', field: 'contact', sort: false },
        ],
        rows: [
            {
                name: "Transaction Receipt",

                contact: (
                    <div className="me-3 d-flex align-items-center cursor-pointer" onClick={(e) => showThisInf(e, content)} title='Edit'>
                        <MDBIcon fas icon="edit" className='fs-5' />
                    </div>
                )
            }
        ]
    });

    const handleReload = () => {
        window.location.reload();
    }

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            try {
                if (localStorage.getItem("loggedUserInfo")) {
                    const waiverRes = await getEmailTEMP(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);

                    if (waiverRes === "false") {
                        setApiError(true)
                        return;
                    }
                    const emailtemp = await waiverRes.json();
                    if (emailtemp.length > 0 && emailtemp[0].gymid) {
                        setContent(emailtemp[0].content);
                        setTempData(emailtemp);

                    } else {

                        const defaultcontent = [{
                            content: "<p><strong>Hello there!</strong></p><p><br></p><p>Thank you for your recent transaction with us. The receipt for your purchase is attached to this email.</p><p><br></p><p>We appreciate your business and look forward to serving you again.</p><p><br></p><p>Thank you,&nbsp;</p><p><strong>Support Team</strong></p><p><strong>﻿</strong></p>",
                            Title: "Transaction Receipt",
                        }]
                        setContent(defaultcontent.content)
                        setTempData(defaultcontent)

                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }

        fetchData();
    }, []);
    console.log(tempData);
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);
    useEffect(() => {
        if (tempData) {
            const newRows = [
                {
                    name: tempData[0].title ? tempData[0].title : "Transaction Receipt",

                    contact: (
                        <div className="me-3 d-flex align-items-center cursor-pointer" onClick={(e) => showThisInf(e, content)} title='Edit'>
                            <MDBIcon fas icon="edit" className='fs-5' />
                        </div>
                    )
                }
            ];
            setEmailSettingsData(prevState => ({
                ...prevState,
                rows: newRows
            }));
        }
    }, [tempData, content]);

    function showThisInf(e, data) {
        navigate("/emailTemplateView", {
            state: data,
        });
    }

    return (
        <div>
            {apiError ? <PageError handleReload={handleReload} /> :
                <div className='sms-settings-table'>
                    <MDBDatatable
                        className='smsTemplateTable'
                        searchLabel="search here"
                        pagination={false}
                        // search
                        fixedHeader
                        maxHeight='70vh'
                        hover
                        data={emailSettingsData}
                    />
                </div>}


        </div>
    );
}

export default EmailTempletes;
