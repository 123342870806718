import {
  MDBAlert,
  MDBBtn,
  MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBTooltip,
} from 'mdb-react-ui-kit';
import { React, useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';
import { Link, useNavigate } from "react-router-dom";
import report from '../components/images/reportlogo.svg';
import {
  endBussinessSession,
  getGBussinessInfo,
  getPricingPlansById,
  getStaffProfile,
  getWhatsappApi,
  logEvent,
  sendVEmail
} from "../services/apiServices";
import BusinessForm from "../views/BusinessForm";
// import logo from "./../components/images/GymLogo.png";
import logo from "./../components/images/Easy-MembrLogo.png";
import Popup from "./Popup";
import businessImg from "./images/Business.svg";
import checkImg from "./images/Checkin.svg";
import logOutImg from "./images/Logout.svg";
import membersImg from "./images/Members.svg";
import memShipImg from "./images/Membership.svg";
import remaindersImg from "./images/Reminders.svg";
import settingsImg from "./images/Settings.svg";
import staffImg from "./images/Staff.svg";
import transcImg from "./images/Transactions.svg";
import event from "./images/activity-svgrepo-com.svg";
import dashImg from "./images/dashboard.svg";
import posImg from "./images/point-of-sale.svg";
import sucessImg from "./images/succes_icon.svg";
import { googleAnalytic } from './googleAnalytics';
import axios from 'axios';
import GenerateNewPassword from './GenerateNewPassword';

export default function LeftPane(props) {
  // const [userInfo, setUserInfo] = useState("");
  let plansInfo = localStorage.getItem("planInfo") ? JSON.parse(localStorage.getItem("planInfo")) : null;
  const [showBusinessPop, setShowBusinessPop] = useState(false);
  const logourl = (localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl : logo) : logo)
  const [imageLoaded, setImageLoaded] = useState(true);
  const bussName = (localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymname) : "");
  const [menuClass, setMenuClass] = useState(
    "gym-sidebar gym-red gym-top gym-medium gym-padding gym-collapse "
  );
  const navigate = useNavigate();
  const [enagleGym, setEnagleGym] = useState(true);
  const [isSignupEnabled, setIsSignupEnabled] = useState(null);
  const [showPopup, setShowPopup] = useState(false);



  const [whatsAppapiCheck, setWhatsAppapiCheck] = useState(false)

  const [showNewPasswordPop, setShowNewPasswordPop] = useState(true);
  const [Error2, setError2] = useState("")

  useEffect(() => {

    const handleImageLoad = () => {
      setImageLoaded(true);
    };

    const handleImageError = () => {
      setImageLoaded(false);
    };

    const imgElement = document.createElement("img");
    imgElement.onload = handleImageLoad;
    imgElement.onerror = handleImageError;
    imgElement.src = logourl;

    return () => {
      imgElement.onload = null;
      imgElement.onerror = null;
    };
  }, [logourl]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("loggedUserInfo")).gymid) {
      fetchWhatsapiData()
    }

  }, [])

  const fetchWhatsapiData = async () => {
    const response = await getWhatsappApi(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
    const whatsAppapi = await response.json();
    console.log(whatsAppapi, '8888888888886666');
    const filterapi = whatsAppapi.length > 0 && whatsAppapi.filter((item) => item.primary === true)
    console.log(filterapi);

    if (filterapi.length > 0) {
      setWhatsAppapiCheck(filterapi[0].istokenexpired)
    }

  }
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  function closeMenu() {
    setMenuClass(
      "gym-sidebar gym-red gym-top gym-medium gym-padding gym-collapse "
    );
  }
  function showmenu() {
    setMenuClass("gym-sidebar gym-red gym-top gym-medium gym-padding");
  }
  if (plansInfo != null && (plansInfo.planFeature && !plansInfo.planFeature.adminlogin)) {
    if (localStorage.getItem("loggedUserInfo") && (JSON.parse(localStorage.getItem("loggedUserInfo")).role.toLowerCase() == "admin")) {
      handleClick();
    }
  }
  console.log(plansInfo);
  if (plansInfo != null && (plansInfo.planFeature && !plansInfo.planFeature.stafflogin)) {
    if (localStorage.getItem("loggedUserInfo") && (JSON.parse(localStorage.getItem("loggedUserInfo")).role.toLowerCase() == "staff")) {
      handleClick();
    }
  }
  async function handleClick() {
    try {
      // logOutUser(JSON.parse(localStorage.getItem("loggedUserInfo"))._id);
      let ip = localStorage.getItem("ipaddress");
      let browserinfo = browserName + "-" + browserVersion + ".0.0";
      let flag = "Logout Successfull";
      let Eventtype = "login";
      let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
      let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
      let staffid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo"))._id : "";
      await googleAnalytic("LeftPane", 'Staff', 'Logout Button', 'Staff Logout')
      await logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
      const date = new Date()
      console.log(date.toISOString());
      const update = {
        staffid: staffid,
        date: date.toISOString()
      }
      await endBussinessSession(staffid, update);
      localStorage.removeItem("transaction_filter");
      localStorage.removeItem("loggedUserInfo");
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      localStorage.removeItem("ipaddress");
      window.location.href = "/login";
      localStorage.removeItem("planInfo");
      //this.context.router.transitionTo('/login');
      //return;
    } catch (e) {
      console.log(e);
    }
  }
  let noPlanpopup = false;
  if (plansInfo === null) {
    noPlanpopup = true;
  }
  let expiryPlanpopup = false;
  if (plansInfo != null && plansInfo.planUsage.baldays <= 0) {
    expiryPlanpopup = true;
  }
  let userInfoInSession = localStorage.getItem("loggedUserInfo") ? true : false;

  function closeWindow() {
    handleClick();
  }
  function resendEamil(_id) {
    sendVEmail(_id);
  }



  useEffect(() => {
    const fetchStaffProfile = async () => {
      if (localStorage.getItem("loggedUserInfo")) {
        try {
          const userInfo = JSON.parse(localStorage.getItem("loggedUserInfo"));

          const response = await getStaffProfile(userInfo._id);

          if (!response || response === "false") {
            return;
          }

          const staffProfile = await response.json();

          if (staffProfile) {
            const signupEnabledValue = staffProfile.issignup;
            setIsSignupEnabled(signupEnabledValue);

            if (signupEnabledValue === false) {
              setShowPopup(true);
            }
          }
        } catch (error) {
          console.error("Error fetching staff profile:", error);
        }
      }
    };

    fetchStaffProfile();
  }, []);







  useEffect(() => {
    let getLoggedInGymInfo = async () => {
      try {
        if (localStorage.getItem("loggedUserInfo") === null) {
          navigate("/login");
          return;
        } else {

          // let uInfo = await getStaffProfile(
          //   JSON.parse(localStorage.getItem("loggedUserInfo"))._id
          // );
          // if (!uInfo) {
          //   return;
          // }
          // if (uInfo === "false") {
          //   return;
          // }
          // const recinfo1 = await uInfo.json();
          // console.log(recinfo1);
          if (
            JSON.parse(localStorage.getItem("loggedUserInfo")).isemailverifed ===
            "1"
          ) {
            const response = await getGBussinessInfo(
              JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
            );
            if (!response || response === "false") {



              return
            }
            const recinfo = await response.json();
            // console.log(recinfo);
            // setBussName(recinfo.businessName)
            // setLogourl(recinfo ? recinfo.logourl:logo)
            if (
              JSON.parse(localStorage.getItem("loggedUserInfo")).isemailverifed ===
              "1"
            ) {
              const response = await getGBussinessInfo(
                JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
              );
              if (!response || response === "false") {
                return;
              }
              const recinfo = await response.json();
              // console.log(recinfo);
              // setBussName(recinfo.businessName)
              // setLogourl(recinfo ? recinfo.logourl:logo)
              if (
                !recinfo.businessName ||
                !recinfo.firstName ||
                !recinfo.lastName ||
                !recinfo.contactNumber ||
                !recinfo.address) {
                setShowBusinessPop(true);
              }
            }
          }
        }
      } catch (e) { }
      //return { name: userInfo.gym, loc: userInfo.gymloc };
    };

    if (localStorage.getItem("loggedUserInfo")) {
      getLoggedInGymInfo();
      // fetchData();
    }
    else {
      navigate("/login");
    }
  });


  useEffect(() => {
    const getPricingPlansBYGymid = async () => {
      const response = await getPricingPlansById(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
      if (!response) {
        return
      }
      const recinfo = await response.json();
      if (recinfo && recinfo.pricingPlans) {
        localStorage.setItem("planInfo", JSON.stringify(recinfo));
      } else {
        localStorage.removeItem("planInfo");
      }
    }
    if (localStorage.getItem("loggedUserInfo")) {
      getPricingPlansBYGymid();
    } else {
      localStorage.removeItem('planInfo');
    }
  }, []);





  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getGBussinessInfo(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (!response || response === "false") {
          return;
        }
        const recinfo = await response.json();
        if (recinfo) {
          let value = recinfo.isenable != true ? false : true;
          setEnagleGym(value);
        }
      }
    }
    if (localStorage.getItem('ipaddress') == null || localStorage.getItem('ipaddress') == undefined) {
      ipaddress();
    }
    fetchData();
  }, []);
  async function ipaddress() {
    try {
      const url = 'https://api.ipify.org?format=json';
      const res = await axios.get(url);
      console.log(process.env.REACT_APP_IP_ADDRESS_URL);
      console.log(res.data.ip);
      localStorage.setItem('ipaddress', res.data.ip);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  }





  const handleLinkClick = (event, path) => {
    if (event.ctrlKey) {
      event.preventDefault();
      window.open(path, "_blank");
    } else {
      navigate(path);
    }
  };


  let posValue = true;
  if (plansInfo != null) {
    if (!plansInfo.planUsage.transactionflag) {
      posValue = false;
    }
    if (!plansInfo.planUsage.activeMembflag) {
      posValue = false;
    }
    if (!plansInfo.planFeature.pos) {
      posValue = false;
    }
  }


  return (
    <>
      <header className="gym-container gym-top gym-hide-large gym-red gym-xlarge gym-padding">
        <button
          onClick={showmenu}
          className="gym-button gym-red gym-margin-right button-link"
        >
          <MDBIcon fas icon="bars" />
        </button>
        <h5 className='ms-3 mb-0 d-inline'>Gym Management</h5>
      </header>
      <nav
        className={menuClass}
        style={{ zIndex: 11, width: "320px" }}
        id="mySidebar"
      >
        {/* <a
          href="#"
          className="gym-button gym-hide-large gym-display-topleft"
          style={{ width: "100%", fontSize: 22 }}
          onClick={closeMenu}
        >

          <MDBIcon fas icon="close" className='close-icon-m' onClick={closeMenu} />
        </a> */}
        <button
          onClick={closeMenu}
          className="gym-button gym-hide-large gym-display-topleft button-link"
        >
          <MDBIcon fas icon="close" className='close-icon-m' onClick={closeMenu} />
        </button>
        <img
          src={imageLoaded ? logourl : logo}
          className="logoImg ms-2"
          alt="logo"
        />
        <div className="user-info">
          <h5 className="mx-2 pt-3 title">

            <MDBTooltip tag='div' placement="bottom"
              title={
                // (localStorage.getItem("loggedUserInfo")) ? username : " "}
                bussName !== "" ? bussName : ""}
            >
              {/* {(localStorage.getItem("loggedUserInfo")) ? username : " "} */}
              {bussName !== "" ? bussName : ""}
            </MDBTooltip>
          </h5>
          {userInfoInSession &&
            <div className='d-flex flex-wrap mb-3'>
              {JSON.parse(localStorage.getItem("loggedUserInfo")).name ? <p className="expiry mx-1 mb-0 chip bg-secondary bg-opacity-50">
                {JSON.parse(localStorage.getItem("loggedUserInfo")).name}
              </p> : null}
              {JSON.parse(localStorage.getItem("loggedUserInfo")).role ?
                <p className="expiry mx-1 mb-0 title chip bg-info bg-opacity-50">
                  {JSON.parse(localStorage.getItem("loggedUserInfo")).role}
                </p> : null}
              {plansInfo != null ? <>
                <p className="expiry mx-1 mb-0 chip bg-success bg-opacity-50">{plansInfo.pricingPlans.name}
                  <button className="button-link link-color px-1 fs-10px"
                    onClick={() => navigate(`/pricing`)}
                  >
                    Change Plan
                  </button></p>
              </>
                : <></>}
            </div>}

          {plansInfo != null ? (
            plansInfo.planUsage.baldays <= 0 ?
              <a href={`/billingsubscriptionplan?id=${plansInfo.pricingPlans._id}`} className="btn btn-danger my-1 mx-2">Plan Expired/Renew</a>

              : plansInfo.planUsage.baldays <= 5 ?
                <a href={`/billingsubscriptionplan?id=${plansInfo.pricingPlans._id}`} className="btn btn-warning my-1 mx-2">Expires in {Math.round(plansInfo.planUsage.baldays)} days</a>
                : ""
          ) : ""}
        </div>
        <div className='d-flex flex-column justify-content-between leftpanelHeigt '>
          <div className="gym-bar-block">
            {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.dashboard)) &&
              <Link
                id="hs-bp"
                to="/dashboard"
                onClick={(event) => handleLinkClick(event, '/dashboard')}
                className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "11" ? "active" : ""
                  }`}
              >
                <img src={dashImg} alt="Logo" /> Dashboard
              </Link>}

            {posValue && <Link
              id="hs-home"
              to="/home"
              onClick={(event) => handleLinkClick(event, '/home')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "1" ? "active" : ""
                }`}
            >
              <img src={posImg} alt="Logo" /> Point of Sale
            </Link>}
            {userInfoInSession && JSON.parse(localStorage.getItem("loggedUserInfo")).role !== "staff" &&
              <Link
                id="hs-bp"
                to="/bInfo"
                onClick={(event) => handleLinkClick(event, '/bInfo')}
                className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "2" ? "active" : ""
                  }`}
              >
                <img src={businessImg} alt="Logo" /> Business Details
              </Link>
            }
            <Link
              id="hs-mmanger"
              to="/mmanager"
              onClick={(event) => handleLinkClick(event, '/mmanager')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "3" ? "active" : ""
                }`}
            >
              <img src={membersImg} alt="Logo" /> Members Management
            </Link>
            {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.membercheckin)) &&
              <Link
                to="/checkin"
                id="checkin"
                onClick={(event) => handleLinkClick(event, '/checkin')}
                className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "5" ? "active" : ""}`}
              >
                <img src={checkImg} alt="Logo" /> Check-In/Check-Out
              </Link>
            }
            <Link
              to="/report"
              id="/report"
              onClick={(event) => handleLinkClick(event, '/report')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "13" ? "active" : ""}`}>
              <img src={report} alt="Logo" /> Reports
            </Link>
            <Link
              to="/staff#"
              id="staff"
              onClick={(event) => handleLinkClick(event, '/staff')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "6" ? "active" : ""
                }`}
            >
              <img src={staffImg} alt="Logo" /> Staff Roles & Attendance
            </Link>
            <Link
              to="/plansandaddons"
              id="about-me"
              onClick={(event) => handleLinkClick(event, '/plansandaddons')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "7" ? "active" : ""
                }`}
            >
              <img src={memShipImg} alt="Logo" /> Plans, Slots, Addons & Discounts
            </Link>

            <Link
              to="/transactions"
              id="transaction"
              onClick={(event) => handleLinkClick(event, '/transactions')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "8" ? "active" : ""
                }`}
            >
              <img src={transcImg} alt="Logo" /> Transactions
            </Link>

            <Link
              to="/logEvent"
              id="logEvent"
              onClick={(event) => handleLinkClick(event, '/logEvent')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "12" ? "active" : ""
                }`}
            >
              <img src={event} alt="Logo" /> Activities
            </Link>

            <Link
              id="hs-receipts"
              to="/messageSettings"
              onClick={(event) => handleLinkClick(event, '/messageSettings')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "4" ? "active" : ""
                }`}
            >
              <img src={remaindersImg} alt="Logo" /> Templates & Reminders {whatsAppapiCheck && <i class="fas fa-triangle-exclamation text-warning"></i>}
            </Link>
            <Link
              to="/settingview"
              id="settings"
              onClick={(event) => handleLinkClick(event, '/settingview')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "9" ? "active" : ""
                }`}
            >
              <img src={settingsImg} alt="Logo" /> Manage Account
            </Link>
            <Link
              onClick={handleClick}
              id="settings"

              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "10" ? "active" : ""
                }`}
            >
              <img src={logOutImg} alt="Logo" /> Logout
            </Link>
          </div>


          <div className="PoweredTxt">
            <span className='d-block text-secondary'>Powered by</span>
            <b className='d-block fs-6'>Zencruz</b>
          </div>
        </div>
      </nav >
      {
        userInfoInSession ? (
          JSON.parse(localStorage.getItem("loggedUserInfo")).isemailverifed
          && JSON.parse(localStorage.getItem("loggedUserInfo")).role &&
          (JSON.parse(localStorage.getItem("loggedUserInfo")).isemailverifed ===
            "0" && JSON.parse(localStorage.getItem("loggedUserInfo")).role !== "staff") && (
            <>
              <Popup
                title="Please verify your email to continue"
                ptype="modal-md"
                content={
                  <>
                    <div style={{ textAlign: "center" }}>
                      <img src={sucessImg} alt='logo' />
                    </div>
                    <div className="thanksmain mt40">
                      Instructions for registering for Business have been sent to{" "}
                      <b>
                        {
                          JSON.parse(localStorage.getItem("loggedUserInfo"))
                            .email
                        }
                      </b>
                      . Kindly check your spam folder if you have not received the
                      email
                    </div>
                    <br />
                    <div className="thanksmain">
                      Email not received? <br />
                      <button className="btn btn-primary mt10 button-link" onClick={resendEamil}>
                        Resend
                      </button>
                      &nbsp;

                    </div>
                  </>
                }
                handleClose={closeWindow}
              />
            </>
          )) : ""


      }


      {
        showPopup && (
          <MDBModal open={showNewPasswordPop} onClose={() => setShowNewPasswordPop(false)} tabIndex="-1">
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Create New Password</MDBModalTitle>
                  <MDBBtn className="btn-close" color="none" onClick={() => setShowNewPasswordPop(false)}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>

                  <GenerateNewPassword
                    onSuccess={() => {
                      setShowNewPasswordPop(false);
                      setError2("Password successfully changed.");
                    }}
                  />
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        )
      }

      <MDBAlert
        color={(Error2.includes("internet") || Error2.includes("wrong")) ? "danger" : (Error2.includes("submitted") || Error2.includes("changed") ? "success" : "secondary")}
        autohide
        position="top-center"
        delay={5000}
        appendToBody
        open={Error2 !== ""}
        onClose={() => setError2("")}
      >
        {Error2}
      </MDBAlert>

      
      {/* {
        showBusinessPop && (
          <>
            <Popup
              title="Please update your Business Informaion to continue"
              ptype="modal-lg"
              content={
                <>
                  <BusinessForm />
                </>
              }
              handleClose={() => setShowBusinessPop(false)}

            />
          </>
        )
      }

      } */}
      {noPlanpopup && (
        <>
          <Popup
            title="Plan Subscription"
            ptype="modal-md"
            style=""
            content={
              <>
                Seems you haven't subscribed yet! Subscribe for more features.
                <MDBModalFooter className="pe-2 pb-2">
                  <MDBBtn color='secondary' onClick={() => handleClick()}>Cancel</MDBBtn>
                  <MDBBtn onClick={() => navigate('/pricing')}>Subscribe</MDBBtn>
                </MDBModalFooter>
              </>
            }
            handleClose={() => handleClick()}
          />
        </>
      )}
      {!enagleGym && (
        <>
          <Popup
            title="Plan Subscription"
            ptype="modal-md"
            style=""
            content={
              <>
                <div className='pb-2'>
                  Your payment is in pending. <br />Once payment is completed, <strong>Reload</strong> manually.
                </div>
                <MDBModalFooter className="pe-2 pb-2">
                  <MDBBtn onClick={() => window.location.reload(false)}>Reload</MDBBtn>
                </MDBModalFooter>
              </>
            }
            handleClose={() => handleClick()}
          />
        </>
      )}
      {expiryPlanpopup && (
        <>
          <Popup
            title="Renew Subscription"
            ptype="modal-md"
            style=""
            content={
              <>
                <div className='pb-2'>
                  Oops! Your subscription has expired. please renew for uninterrupted service.
                </div>
                <MDBModalFooter className="pe-2 pb-2">
                  <MDBBtn color='secondary' onClick={() => handleClick()}>Cancel</MDBBtn>
                  <MDBBtn onClick={() => navigate(`/billingsubscriptionplan?id=${plansInfo.pricingPlans._id}`)}>Renew</MDBBtn>
                </MDBModalFooter>
              </>
            }
            handleClose={() => handleClick()}
          />
        </>
      )}
    </>
  );
}