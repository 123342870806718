import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { contactus } from "../../services/apiServices";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {

            name:" ",
            email:" ",
            subject:" ",
            comments:" ",
        }
    }
    handleSubmit = (e,_id) => {
    e.preventDefault();
     contactus(_id,this.state);
    }
    
      handleChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value
        });
      }
    
      render() {
        const { name, email, comments, subject } = this.state;
        return (
            <React.Fragment>
                {/* Start Contact  */}
                <section className="" id="contact">
                    <Container>
                        <Row className="justify-content-center">
                            <Col>
                                <div className="section-title text-center pb-2">
                                    <h4 className="title mb-3">Get In Touch !</h4>
                                    <p className="text-muted para-desc mb-0 mx-auto">Fill the form below and we will get back to you</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="align-items-center">
                            <Col lg={8} md={6} className="order-md-2 order-1 mt-4 pt-2">
                                <div className="p-4 rounded shadow bg-white">
                                    <form onSubmit={this.handleSubmit} name="myForm" id="form" >
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-4"  value={name} onChange={this.handleChange}>
                                                    <input name="name" id="name" type="text" className="form-control" placeholder="Name" />
                                                </div>
                                            </Col>

                                            <Col md={6} >
                                                <div className="mb-4" >
                                                    <input name="email" id="email" type="email" className="form-control" placeholder="Email" value={email} onChange={this.handleChange} />
                                                </div>
                                            </Col>

                                            <div className="col-12">
                                                <div className="mb-4" value={subject} onChange={this.handleChange}>
                                                    <input name="subject" id="subject" className="form-control" placeholder="Subject" />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="mb-4" value={comments} onChange={this.handleChange}>
                                                    <textarea name="comments" id="comments" rows={4} className="form-control" placeholder="Message" >

                                                    </textarea>
                                                </div>
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="col-12 text-end">
                                                <button type="submit" id="submit" name="send" className="btn btn-primary">Send Message</button>
                                            </div>
                                        </Row>
                                    </form>
                                </div>
                            </Col>

                            <Col lg={4} md={6} className="col-12 order-md-1 order-2 mt-4 pt-2">
                                <div className="me-lg-4">
                                    <div className="d-flex">
                                        <div className="icons text-center">
                                            <i className="uil uil-phone d-block rounded h4 mb-0"></i>
                                        </div>

                                        <div className="flex-1 ms-3">
                                            <h5 className="mb-2">Phone</h5>
                                            <Link to="tel:+91 9100944539" className="text-muted">+91 9100944539</Link>
                                        </div>
                                    </div>

                                    <div className="d-flex mt-4">
                                        <div className="icons text-center">
                                            <i className="uil uil-envelope d-block rounded h4 mb-0"></i>
                                        </div>

                                        <div className="flex-1 ms-3">
                                            <h5 className="mb-2">Email</h5>
                                            <Link to="mailto:sales@zencruz.com" className="text-muted">sales@zencruz.com</Link>
                                        </div>
                                    </div>

                                    <div className="d-flex mt-4">
                                        <div className="icons text-center">
                                            <i className="uil uil-map-marker d-block rounded h4 mb-0"></i>
                                        </div>

                                        <div className="flex-1 ms-3">
                                            <h5 className="mb-2">Location</h5>
                                            <p className="text-muted mb-2">{process.env.REACT_APP_INDEX_ADDRESS} Sundaraiah Vignana Kendram, Survey No 91, Gachibowli Rd, Madhava Reddy Colony, Gachibowli, Hyderabad, Telangana 500032</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* End Contact  */}
            </React.Fragment>
        )

    }
}

export default Contact;
