import React, { useEffect, useState, useRef } from "react";
import PosterImg from "../components/images/EmailTemplateImages/image-5.jpeg";
import ReactQuill from 'react-quill';
import ReactGA from 'react-ga';
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
// import Logo from "../components/images/GymLogo.png";
import Logo from "../components/images/Easy-MembrLogo.png";
import { getEmailTEMP, updateEmailTemp } from "../services/apiServices";
import UploadLogo from "./uploadLogo";
import { useNavigate } from "react-router";
import PageError from "./PageError";
import { googleAnalytic } from "../components/googleAnalytics";


function EmailTemplateView(props) {
    const [apiError, setApiError] = useState(false);

    const [logourl, setLogourl] = useState(localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl : Logo) : Logo)
    const [imageLoaded, setImageLoaded] = useState(true);
    const [imageLoaded1, setImageLoaded1] = useState(true);
    const [title, settitle] = useState("Transaction Receipt")
    const [btntitle, setBtntitle] = useState("Download Receipt")
    const [content, setContent] = useState("<p><strong>Hello there!</strong></p><p><br></p><p>Thank you for your recent transaction with us. The receipt for your purchase is attached to this email.</p><p><br></p><p>We appreciate your business and look forward to serving you again.</p><p><br></p><p>Thank you,&nbsp;</p><p><strong>Support Team</strong></p><p><strong>﻿</strong></p>")
    const [highlightedElement, setHighlightedElement] = useState(null);
    const [media, setMedia] = useState([
        { label: 'facebook', check: true, url: '' },
        { label: 'twitter', check: true, url: '' },
        { label: 'youtube', check: true, url: '' },
        // { label: 'pinterest', check: false, url: '' },
        { label: 'whatsapp', check: false, url: '' },
        { label: "instagram", check: false, url: '' },
        { label: "linkedin", check: false, url: '' }
    ]);
    const navigate = useNavigate();
    const [bannerurl, setBannerurl] = useState()
    const gymid = useState(localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");
    let email = (localStorage.getItem("loggedUserInfo")) ?
        (JSON.parse(localStorage.getItem("loggedUserInfo")).email.split('@')[0].charAt(0).toUpperCase()
            + JSON.parse(localStorage.getItem("loggedUserInfo")).email.split('@')[0].slice(1)) : "";
    let username = (localStorage.getItem("loggedUserInfo")) ? ((JSON.parse(localStorage.getItem("loggedUserInfo")).username)
        ? (JSON.parse(localStorage.getItem("loggedUserInfo")).username.charAt(0).toUpperCase()
            + JSON.parse(localStorage.getItem("loggedUserInfo")).username.slice(1)) : email) : "";

    const quillRef = useRef(null);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    useEffect(() => {
        const quillElement = quillRef.current?.editor?.container;
        if (quillElement) {
            quillElement.addEventListener('click', () => {
                handleInputClick('content');
            });
            return () => {
                quillElement.removeEventListener('click', () => {
                    handleInputClick('content');
                });
            };
        }
    }, [quillRef]);

    useEffect(() => {

        const handleImageLoad = () => {
            setImageLoaded1(true);
        };

        const handleImageError = () => {
            setImageLoaded1(false);
        };
        const imgElement = document.createElement("img");
        imgElement.onload = handleImageLoad;
        imgElement.onerror = handleImageError;
        imgElement.src = logourl;

        return () => {
            imgElement.onload = null;
            imgElement.onerror = null;
        };
    }, [logourl]);
    useEffect(() => {

        const handleImageLoad = () => {
            setImageLoaded(true);
        };

        const handleImageError = () => {
            setImageLoaded(false);
        };
        const imgElement = document.createElement("img");
        imgElement.onload = handleImageLoad;
        imgElement.onerror = handleImageError;
        imgElement.src = bannerurl;

        return () => {
            imgElement.onload = null;
            imgElement.onerror = null;
        };
    }, [bannerurl]);


    const handleReload = () => {
        window.location.reload();
    }
    async function fetchData() {

        try {

            if (localStorage.getItem("loggedUserInfo")) {
                const defaultContent = "<p><strong>Hello there!</strong></p><p><br></p><p>Thank you for your recent transaction with us. The receipt for your purchase is attached to this email.</p><p><br></p><p>We appreciate your business and look forward to serving you again.</p><p><br></p><p>Thank you,&nbsp;</p><p><strong>Support Team</strong></p><p><strong>﻿</strong></p>";
                const waiverRes = await getEmailTEMP(
                    JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
                );
                if (!waiverRes) {
                    return;
                }

                if (waiverRes == "false") {
                    setApiError(true)
                } else {
                    const emailtemp = await waiverRes.json();
                    console.log(emailtemp[0]);
                    if (emailtemp.length > 0 && emailtemp[0].gymid) {

                        setContent(emailtemp[0].content ? emailtemp[0].content : defaultContent);
                        setBtntitle(emailtemp[0].recbtntitle ? emailtemp[0].recbtntitle : "Download Receipt")
                        settitle(emailtemp[0].title ? emailtemp[0].title : "Transaction Receipt")
                        setBannerurl(emailtemp[0].bannerimg)
                        setMedia(emailtemp[0].media.length > 0 ? emailtemp[0].media : [
                            { label: 'facebook', check: true, url: '' },
                            { label: 'twitter', check: true, url: '' },
                            { label: 'youtube', check: true, url: '' },
                            // { label: 'pinterest', check: false, url: '' },
                            { label: 'whatsapp', check: false, url: '' },
                            { label: "instagram", check: false, url: '' },
                            { label: "linkedin", check: false, url: '' }
                        ])
                    } else {

                        const updates = {
                            gymid: localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
                            content: defaultContent,
                            title: "Transaction Receipt",
                            bannerimg: bannerurl,
                            recbtntitle: "Download Receipt",
                            media: [
                                { label: 'facebook', check: true, url: '' },
                                { label: 'twitter', check: true, url: '' },
                                { label: 'youtube', check: true, url: '' },
                                // { label: 'pinterest', check: false, url: '' },
                                { label: 'whatsapp', check: false, url: '' },
                                { label: "instagram", check: false, url: '' },
                                { label: "linkedin", check: false, url: '' }
                            ]

                        };
                        const saverep = await updateEmailTemp(updates, JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
                        if (saverep == "false") {
                            setApiError(true)
                            return;
                        }
                        const recinf = await saverep.json();
                        console.log(recinf);

                        setContent(recinf.content);
                        setBtntitle(recinf.recbtntitle)
                        settitle(recinf.title)
                        setBannerurl(recinf.bannerimg)
                        setMedia(recinf.media ? recinf.media : [
                            { label: 'facebook', check: true, url: '' },
                            { label: 'twitter', check: true, url: '' },
                            { label: 'youtube', check: true, url: '' },
                            // { label: 'pinterest', check: false, url: '' },
                            { label: 'whatsapp', check: false, url: '' },
                            { label: "instagram", check: false, url: '' },
                            { label: "linkedin", check: false, url: '' }
                        ]);
                    }

                    setHighlightedElement(null);
                }
            }

        } catch (error) {
            console.error("Error fetching data:", error);

        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    const handleUpdateEmailTemplete = async (e) => {
        e.preventDefault();
        const updates = {
            gymid: gymid,
            content: content,
            title: title,
            bannerimg: bannerurl,
            recbtntitle: btntitle,
            media: media
        };
        try {
            const saverep = await updateEmailTemp(updates, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");


            if (saverep == "false") {
                setApiError(true)
                return;
            }
            const recinf = await saverep.json();
            console.log(recinf);
            await googleAnalytic("EmailTempleteView", 'Email Templete', 'Update Button', 'Email Templete Updated')

            setContent(recinf.content);
            setBtntitle(recinf.recbtntitle)
            settitle(recinf.title)
            setBannerurl(recinf.bannerimg)
            // toggleOpen();
            setMedia(recinf.media)
            setHighlightedElement(null);
        } catch (error) {
            console.error("Error updating email template:", error);

        }
    };

    const handleInputChange = (e, field) => {

        switch (field) {
            case 'title':
                settitle(e.target.value);
                break;
            case 'banner':
                setBannerurl(e)

                break;
            case 'content':
                setContent(e);
                break;
            case 'btntitle':
                setBtntitle(e.target.value);
                break;

            default:
                break;
        }
    };
    const handleInputClick = (field) => {
        setHighlightedElement(field);
    };
    const getHighlightedStyle = (element) => {
        return highlightedElement === element ? { border: '2px solid red' } : {};
    };

    const handleUrl = (data) => {
        console.log(data);
        console.log(typeof data);

        handleInputChange(data, "banner")
    }
    const handleMediaCheckboxChange = (index, isChecked) => {
        setMedia(prevMedia => {
            if (!Array.isArray(prevMedia)) {
                prevMedia = [];
            }
            const updatedMedia = [...prevMedia];
            updatedMedia[index] = { ...updatedMedia[index], check: isChecked };
            return updatedMedia;
        });
        setHighlightedElement(media[index].label);
    };

    const handleMediaUrlChange = (index, newUrl) => {
        setMedia(prevMedia => {
            if (!Array.isArray(prevMedia)) {
                prevMedia = [];
            }
            const updatedMedia = [...prevMedia];
            updatedMedia[index] = { ...updatedMedia[index], url: newUrl };
            return updatedMedia;
        });
        setHighlightedElement(media[index].label);
    };

    const handleHompage = () => {
        navigate("/messageSettings");
    }
    console.log(content, '888888888888');
    return (
        <>
            <LeftPane isactive="4" />
            <Header />
            {apiError ? <PageError handleReload={handleReload} /> :
                <div className="gym-main gym-main-div rightContent">

                    <h5 className="m-0 me-3 d-flex mb-1 align-items-center justify-content-between">
                        <h5 className="m-0 title">
                            <MDBIcon fas icon="arrow-left" className="me-4 fs-3" onClick={handleHompage} />
                            <span>Edit Email Template</span>
                        </h5>
                        <div>
                            <MDBBtn className="me-2" color="secondary" onClick={handleHompage}>Cancel</MDBBtn>
                            <MDBBtn className="me-2" color="primary" onClick={(e) => handleUpdateEmailTemplete(e)}>Save</MDBBtn>
                        </div>

                    </h5>
                    <div className="d-flex pt-3 emailTemplate">
                        <div className='w-50 border p-3 mob-vw-100'>
                            <h5 className="m-0 title text-center border p-3 bg-info bg-opacity-10">
                                <span>Preview Template</span>
                            </h5>
                            <div className="px-5">
                                <div className="email-container">
                                    {/* Header Section */}

                                    <div className="d-flex align-items-center justify-content-cente  flex-column">
                                        {/* <img src={logourl != "" ? logourl : logo} className="w-100 mt-3 " /> */}
                                        <img
                                            src={imageLoaded1 ? logourl : Logo}
                                            className="w-30 mt-3"
                                            alt="logo"
                                        />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-cente  flex-column">
                                        <img src={imageLoaded ? bannerurl : PosterImg} className="w-90 mt-3 " style={getHighlightedStyle('banner')} />
                                        <div className='m-0 p-2' style={getHighlightedStyle('title')}>
                                            <h3 className="text-dark mt-3 " >{title}</h3>
                                        </div>
                                    </div>

                                    {/* Body Section */}
                                    <main className="email-body px-4">

                                        <div className='m-0 p-3' style={getHighlightedStyle('content')} dangerouslySetInnerHTML={{
                                            __html: (content && content.replace(/\n/g, '<br/>')) || ''
                                        }} />
                                        {/*<center >
                                            <button className="download-receipt-button" style={getHighlightedStyle('btntitle')}>{btntitle}</button>
                                              </center>*/}
                                    </main>

                                    {/* Footer Section */}
                                    <footer className="email-footer p-4">
                                        <p className="footer-text">Follow us on:</p>
                                        <div className="email-footer-icons py-3">
                                            {media && media.length > 0 && media.map((item, index) => (

                                                (item.check == true && <a href={item.url}  ><i class={`fab fa-${item.label.toLowerCase()} fa-2x text-white`} style={getHighlightedStyle(item.label)}></i></a>)))}

                                        </div>
                                        <div>
                                            <p className="footer-text">	&#169;
                                                <strong className="mx-1">
                                                    {(localStorage.getItem("loggedUserInfo")) ? username : " "}
                                                </strong>
                                                2024.  All rights reserved.</p>
                                        </div>
                                    </footer>
                                    <div className="px-3 pb-2 bg-info w-100 text-center">
                                        <p className="footer-text mb-0 text-dark">
                                            <strong>Powered by: Zencruz, 2024. All rights reserved.</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='w-50 border p-3 mob-vw-100'>
                            <h5 className="m-0 title text-center border p-3 bg-info bg-opacity-10">
                                <span>Edit Template</span>
                            </h5>
                            <div className=' mt-3'>
                                <label className="fw-bold">Templete Banner</label><br /><span>Click here or Drag and Drop to upload Banner.</span><span>Suggested size (699px width X 245px height)</span>
                                <div className="uploadOuter w-100 cursor-pointer">

                                    <div className="">
                                        <UploadLogo handleUrl={handleUrl} Gid={gymid} imageurl={imageLoaded ? bannerurl : PosterImg} from={"banner"} onClick={() => handleInputClick('banner')} />
                                    </div>
                                </div>
                            </div>
                            <div className=' mt-3'>
                                <label className="fw-bold">Templete Title</label>
                                <div className='d-flex align-items-center justify-content-start mb-2'>
                                    <input type='text' className='form-control w-100' placeholder='Enter the page title here' value={title} onChange={(e) => handleInputChange(e, 'title')} onClick={() => handleInputClick('title')} />
                                </div>
                            </div>
                            <label className="fw-bold mt-3">Templete Content</label>

                            <ReactQuill
                                ref={quillRef}
                                value={content} onChange={(value) => handleInputChange(value, 'content')} onClick={() => handleInputClick('content')}
                            />


                            {/* <div>
                                <label className="fw-bold mt-3">Button Title</label>
                                <div className='d-flex align-items-center justify-content-start mb-2'>
                                    <input type='text' className='form-control w-100' placeholder='Enter the button title here' value={btntitle} onChange={(e) => handleInputChange(e, 'btntitle')} onClick={() => handleInputClick('btntitle')} />
                                </div>
                            </div> */}
                            <div className="d-block py-3">
                                <label className="fw-bold mt-3">Templete Footer</label>
                                <p className="footer-text">Follow us on:</p>


                                {media && media.map((item, index) => (
                                    <div key={index} className='d-flex align-items-center justify-content-start mb-2'>
                                        <div className='w-150px'>
                                            <input
                                                type='checkbox'
                                                checked={item && item.check == true ? true : false}
                                                onChange={(e) => handleMediaCheckboxChange(index, e.target.checked)}
                                            />
                                            <i className={`fab fa-${item.label.toLowerCase()} ms-2 text-secondary`}></i>
                                            <span className='mx-2'>{item.label}</span>
                                        </div>
                                        <input
                                            type='text'
                                            className='form-control w-80'
                                            placeholder={item.label == "whatsapp" ? "Enter your phone number here" : 'Enter the page URL here'}
                                            value={item && item.url}
                                            onChange={(e) => handleMediaUrlChange(index, e.target.value)}
                                            onClick={() => handleInputClick(item.label)}
                                            name={item && item.label.toLowerCase()}
                                        />
                                    </div>
                                ))} </div>

                        </div>

                    </div>
                </div>}
        </>
    )
}
export default EmailTemplateView