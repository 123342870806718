import { MDBBtn, MDBModalFooter } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga';
import { createDiscount } from "../services/apiServices";
import { isEmpty } from "../validators/helper.js";
import PageError from "./PageError";
import { browserName, browserVersion } from "react-device-detect";
import { logEvent } from "../services/apiServices.js";
import { googleAnalytic } from "../components/googleAnalytics";

function CreateDiscount(props) {
    const [error, setError] = useState({});
    const [apiError, setApiError] = useState(false);
    const [form, setForm] = useState({
        title: "",
        apply: "flat",
        discount: "",
        mingroup: "",
        couponcode: "",
        packagetype: "plan",
        ontimerenewal: true,
        primarydiscount: true,
        gymid: "",
        _id: ""
    });

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    const handleReload = () => {
        window.location.reload();
    }
    function updateForm(value) {
        return setForm((prev) => {
            return { ...prev, ...value };
        });
    }
    useEffect(() => {
        console.log(props);
        if (!(form._id !== "" && props.id === "-1")) { setForm(props.data) }
    }, [props, form._id]);
    console.log(form);
    let validateForm = (e) => {
        console.log("Validating form...");
        let errors = {};

        if (isEmpty(form.title)) {
            errors.title = "discount Name can't be blank";
        }
        if (isEmpty(form.discount)) {
            errors.discount = "discount can't be blank";
        }
        console.log(errors);
        if (isEmpty(errors)) {
            return true;
        } else {
            return errors;
        }
    };

    async function onSubmit(e) {


        e.preventDefault();

        const updates = {
            title: form.title,
            apply: form.apply,
            discount: form.discount,
            mingroup: form.mingroup,
            couponcode: form.couponcode,
            packagetype: form.packagetype,
            ontimerenewal: form.ontimerenewal,
            primarydiscount: form.primarydiscount,
            gymid: localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
            _id: form._id ? form._id : -1
        };


        let errors = validateForm(e);
        if (errors === true) {
            console.log(updates);
            let savedObj = await createDiscount(
                updates,
                localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : ""
            );
            if (savedObj === "false") {
                setApiError(true)
                return;
            }
            let discount = await savedObj.json();
            console.log(discount);
            if (props.id === discount._id) {
                let ip = localStorage.getItem("ipaddress");
                let browserinfo = browserName + "-" + browserVersion + ".0.0";
                let flag = "Discount Updated Successfull";
                let Eventtype = "Discount";
                let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
                let username1 = JSON.parse(localStorage.getItem("loggedUserInfo")).email;
                await googleAnalytic("CreateDiscount", 'Discount', 'Save Button', 'Discount Updated')

                logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
                props.onsubmit(discount);
            }
            else {
                let ip = localStorage.getItem("ipaddress");
                let browserinfo = browserName + "-" + browserVersion + ".0.0";
                let flag = "Discount Created Successfull";
                let Eventtype = "Discount";
                let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
                let username1 = JSON.parse(localStorage.getItem("loggedUserInfo")).email;
                await googleAnalytic("CreateDiscount", 'Discount', 'create Button', 'Discount Created')

                logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
                localStorage.setItem("setting_save_discount", "Discount saved successfully");
                props.onsubmit(discount);
            }
            window.location.href = "/plansandaddons";
        }
        else {
            setError(errors);
        }
    }
    return (
        <>
            {apiError ? <PageError handleReload={handleReload} /> :
                <>  <div className="gym-section gym-half">
                    <label>Title<span className="text-danger">*</span> </label>
                    <input
                        className="gym-input"
                        type="text"
                        name="product_name"
                        id="product_name"
                        placeholder="Title "
                        value={form.title}
                        onChange={(e) => updateForm({ title: e.target.value })}
                    />
                    {error.title && (
                        <>
                            <p className="clr-red">{error.title}</p>
                        </>
                    )}
                </div>


                    <div className="gym-section gym-half">
                        <label>Apply </label>
                        <select name="" className="gym-input" id="product_name"
                            value={form.apply}
                            onChange={(e) => updateForm({ apply: e.target.value })} >
                            <option value="flat">Flat </option>
                            <option value="first member only"> First member only</option>
                            <option value="second member onwards">Second member onwards </option>
                        </select>
                    </div>
                    <div className="gym-section gym-half">
                        <label>Discount<span className="text-danger">*</span>  </label>
                        <input
                            className="gym-input"
                            type="number"
                            name="product_name"
                            id="product_name"
                            placeholder="Discount"
                            value={form.discount}
                            onChange={(e) => updateForm({ discount: e.target.value })}
                        />
                        {error.discount && (
                            <>
                                <p className="clr-red">{error.discount}</p>
                            </>
                        )}
                    </div>
                    <div className="gym-section gym-half">
                        <label>Min Group</label>
                        <input
                            className="gym-input"
                            type="number"
                            name="product_name"
                            id="product_name"
                            placeholder="Min group"
                            value={form.mingroup}
                            onChange={(e) => updateForm({ mingroup: e.target.value })}
                        />
                    </div>

                    <div className="gym-section gym-half">
                        <label>Package Type </label>
                        <select name="" className="gym-input" id="product_name"
                            value={form.packagetype}
                            onChange={(e) => updateForm({ packagetype: e.target.value })} >
                            <option value="plan">plan </option>
                            <option value="addon">addon </option>
                        </select>
                    </div>

                    <div className="gym-section gym-half">
                        <label>Coupon Code</label>
                        <input
                            className="gym-input"
                            type="text"
                            name="product_name"
                            id="product_name"
                            placeholder="Coupon Code"
                            value={form.couponcode}
                            onChange={(e) => updateForm({ couponcode: e.target.value })}
                        />

                    </div>

                    <div className="gym-section gym-half d-flex align-items-center mb-2">
                        <label className="mb-2">On Time Renewal</label>
                        <div className="phoneselect">
                            <label className="m-0 ms-2 switch">
                                <input className="form-control" type="checkbox" checked={form.ontimerenewal} onChange={(e) => updateForm({ ontimerenewal: !form.ontimerenewal })} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>

                    <div className="gym-section gym-half d-flex align-items-center mb-2">
                        <label className="mb-2">Primary Discount </label>
                        <div className="phoneselect">
                            <label className="m-0 ms-2 switch">
                                <input className="form-control" type="checkbox" checked={form.primarydiscount} onChange={(e) => updateForm({ primarydiscount: !form.primarydiscount })} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <MDBModalFooter className="justify-content-start">
                        <MDBBtn onClick={(e) => onSubmit(e)} >Save</MDBBtn>
                        <MDBBtn color='secondary' onClick={props.onclose} >
                            Cancel
                        </MDBBtn>
                    </MDBModalFooter>

                </>}</>
    )
}
export default CreateDiscount