import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon } from "mdb-react-ui-kit";
import ReCAPTCHA from "react-google-recaptcha";
import logImg from "./../components/images/Easy-MembrLogo.png";
import logBg from "./../components/images/loginBg.png";
import { isEmpty } from "../validators/helper.js";

import { resetPassword } from "../services/apiServices";

const ResetPasswords = () => {
  const [email, setEmail] = useState(""); // State to store the email
  console.log(email)
  const [message, setMessage] = useState(""); // State to show success/error messages
  const [captchaValue, setCaptchaValue] = useState(false); // State to track CAPTCHA verification
  const [emailSent, setEmailSent] = useState(false); // State to track if the email link has been sent
  const [capSitekey, setCapSiteKey] = useState("")
  const [domain, setDomain] = useState("")
  const [error, setError] = useState({});


  useEffect(() => {
    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    const domain = urlObj.hostname;
    // console.log(domain);
    setDomain(domain)
  }, []);


  useEffect(() => {
    let captcha_ID = "6LfQZGglAAAAAPPUCjiIsnyCvmGisjTTowbcFP3_";
    if (domain === "easymembr.zencruz.com" || domain === "easyemebr-beta.zencruz.com") {
      captcha_ID = "6LcbvooqAAAAADsZ5lZdBiF1dt_ipQQdlTnppmwX";
    }
    setCapSiteKey(captcha_ID)

  }, [domain]);




  const validateForm = () => {
    let errors = {};
    if (isEmpty(email)) {
      errors.email = "Email can't be blank";
      }  else if (!captchaValue) {
      errors.tempcaptcha = "Please check the captcha"
      }
      setError(errors);
      return isEmpty(errors);
  };

  // Handle email input change
  const handleMail = (e) => {
    setEmail(e.target.value);
  };

  // Handle CAPTCHA verification
  function verifyCaptchaCallback(resp) {
    console.log("Respons for captcha");
    // console.log(resp);
    if (!resp) {
      setCaptchaValue(false);
    }
    else {
      console.log("true");
      setCaptchaValue(true);
    }
  }

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
      if(validateForm()){

        const updates = { email: email };
        try {
          const response = await resetPassword(updates);
          const result = await response.json();
          // console.log(result)
    
          if (response.ok) {
            setMessage("If you have entered a verified email address, you should receive an email with a link to reset your password");
            setEmailSent(true);
          } else {
            setMessage(result.error || "If you have entered a verified email address, you should receive an email with a link to reset your password");
            setEmailSent(true);
          }
        } catch (error) {
          console.error("Error during reset password request:", error);
          setMessage("Failed to send email. Please check your network and try again.");
        }
      }
 
  };

  return (
    <React.Fragment>
      <div className="back-to-home">
        <Link to="/">
          <MDBBtn>
            <MDBIcon fas icon="arrow-left" />
          </MDBBtn>
        </Link>
      </div>

      {/* Hero Start */}
      <section className="cover-user bg-white">
        <div className="container-fluid px-0">
          <Row className="g-0 position-relative">
            <Col lg={4} className="cover-my-30">
              <div className="cover-user-img d-flex align-items-center justify-content-center">
                <Row>
                  <div className="col-12">
                    <div className="d-flex flex-column auth-hero">
                      <div className="my-md-5 pt-md-5 text-center">
                        <Link to="#">
                          <img src={logImg} className="gymlogo1 mt-md-5" alt="" />
                        </Link>
                      </div>

                      <MDBCard className="border border-success w-100 mb-2">
                        <MDBCardBody>
                          <MDBCardTitle>Reset password</MDBCardTitle>
                          <form className="login-form mt-4" onSubmit={handleSubmit}>
                            <Row>
                              {/* Conditionally render email and CAPTCHA fields */}
                              {!emailSent && (
                                <>
                                  <Col lg={12}>
                                    <p className="text-muted">
                                      Please enter your registered email address. You will receive a link to create a
                                      new password via email.
                                    </p>
                                    <div className="mb-2">
                                      <label className="form-label">
                                        Email address<span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter your email address"
                                        name="email"
                                        value={email}
                                        required
                                        onChange={handleMail}
                                      />
                                    </div>
                                  </Col>

                                  
                                  {error.email && (
                                      <>
                                        <p className="clr-red">{error.email}</p>
                                      </>
                                    )}


                                  <Col md={12}>
                                    <div className="mb-3">
                                      <ReCAPTCHA
                                        className="mt-3"
                                        sitekey={"6LfQZGglAAAAAPPUCjiIsnyCvmGisjTTowbcFP3_"}
                                        onChange={verifyCaptchaCallback}
                                        theme="light"
                                        size="large"
                                      />
                                    </div>
                                  </Col>
                                  
                         
                                  {error.tempcaptcha && (
                                      <>
                                        <p className="clr-red">{error.tempcaptcha}</p>
                                      </>
                                    )}


                                  <Col lg={12} style={{ marginBottom: "15px" }}>
                                    <div className="d-grid">
                                      <button
                                        className="btn btn-primary mt-2"
                                        type="submit"
                                     
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </Col>
                                </>
                              )}
                              {message && (
                                <Col lg={12}>
                                  <p className="ms-0 ps-0">{message}</p>
                                </Col>
                              )}

                              <Col lg={12}>
                                <div className="d-grid">
                                  <Link
                                    to="/login"
                                    className="btn btn-secondary text-white text-center fw-bold"
                                  >
                                    Back to Login
                                  </Link>
                                </div>
                              </Col>


                            </Row>
                          </form>
                        </MDBCardBody>
                      </MDBCard>

                      <div className="mb-md-5 text-center">
                        <p className="mb-0 text-muted">
                          © {new Date().getFullYear()} Zencruz.
                        </p>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </Col>

            <div
              className="col-lg-8 padding-less img"
              style={{ backgroundImage: `url(${logBg})` }}
              data-jarallax='{"speed": 0.5}'
            >
              <div className="greenoverlay"></div>
            </div>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ResetPasswords;
