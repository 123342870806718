import React, { useState, useEffect } from "react";
import { Col, Card, Row, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { getAllPricingPlans } from "../../services/apiServices";
import FeatureText from "./FeatureText"
import {
    MDBBtn,
    MDBAlert,
    MDBModal,
    MDBModalDialog, MDBIcon,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
} from 'mdb-react-ui-kit';
// import { planLimitation, plansFeature, pricingPlans } from "./pricingplans";
const Pricing = () => {
    const navigate = useNavigate();
    const [PlansData, setPlansData] = useState()
    const [Error2, setError2] = useState("")
    useEffect(() => {
        async function fetchData() {
            const response = await getAllPricingPlans();
            if (!response || response == 'false') {
                // updateDefaultPlans();
                return;
            }
            let recinfo = await response.json();
            console.log(recinfo);
            if (recinfo && recinfo.pricingPlans) {
                let filterData = recinfo.pricingPlans.filter((obj) => obj.availability == true);
                recinfo.pricingPlans = filterData;
                setPlansData(recinfo);
            }
        }
        fetchData();
    }, []);
    // function updateDefaultPlans() {
    //     let data = {
    //         pricingPlans: pricingPlans.filter((obj) => obj.availability == true),
    //         planFeature: plansFeature,
    //         planLimitation: planLimitation
    //     }
    //     setPlansData(data);
    // }
    // useEffect(() => {
    //     // setTimeout(() => {
    //     updateDefaultPlans();
    //     // }, 3000)
    // }, [])
    const buynowHandler = (e, plan) => {
        e.preventDefault();
        if (!(localStorage.getItem("loggedUserInfo") && JSON.parse(localStorage.getItem("loggedUserInfo")).gymid)) {
            navigate(`/OtpValidation?id=${plan._id}`);
            return;
        } else {
            navigate(`/billingsubscriptionplan?id=${plan._id}`);
            return;
        }
    }
    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

    const toggleOpen = () => setFullscreenXlModal(!fullscreenXlModal);
    const featureFields = [
        { field: "dashboard", label: "Dashboard" },
        { field: "membercheckin", label: "Member Check-In" },
        { field: "checkinreports", label: "Check-In Reports" },
        { field: "memebershipplans", label: "Membership Plans" },
        { field: "addons", label: "Addons" },
        { field: "timeslots", label: "Time Slots" },
        { field: "planswithsessionslimit", label: "Plans With Sessions Limit" },
        { field: "adminlogin", label: "Admin Login" },
        { field: "stafflogin", label: "Staff Login" },
        { field: "discountplans", label: "Discount Plans" },
        { field: "emailreceipt", label: "Email Receipt" },
        { field: "digitalwaiver", label: "Digital Waiver" },
        { field: "pos", label: "POS" },
        { field: "gstreceipts", label: "GST Receipts" },
        { field: "trainersessionssummary", label: "Trainer Sessions Summary" },
        { field: "loginwithgoogleaccount", label: "Login With Google Account" }
    ];
    const limitFields = [
        { field: "memebershipplans", label: "Membership Plan(s)" },
        { field: "addons", label: "Addon(s)" },
        { field: "timeslots", label: "Time Slot(s)" },
        { field: "stafflogin", label: "Staff login(s)" },
        { field: "activemembers", label: "Active Member(s)" },
        { field: "transactions", label: "Transaction(s) (Monthly)" }
    ];

    const moduleFields = [
        { field: "smsintergration", label: "SMS Integration" },
        { field: "accountantrole", label: "Accountant Role" },
        { field: "whatsappintegration", label: "WhatsApp Integration" },
        // { field: "memberportal", label: "Member Portal" }
    ];

    return (
        <React.Fragment>
            {/* Start Pricing  */}
            <section className="section bg-light" id="pricing">
                <div className="container-fluid">
                    <Row className="justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4">
                                <h4 className="title fw-semibold mb-4">Pricing Plans</h4>
                                <MDBBtn size='sm' className="btn btn-primary btn-sm" onClick={toggleOpen}>Compare</MDBBtn>
                            </div>
                        </div>
                    </Row>
                    <Row className="d-flex justify-content-center pricingDiv">
                        <>
                            {PlansData && PlansData.pricingPlans && PlansData.pricingPlans.length > 0 ? (
                                PlansData.pricingPlans.map((item, key) => {
                                    let featureIndexOf = PlansData.pricingPlans.findIndex((obj) => obj._id === item._id);
                                    let prevFeature = PlansData.planFeature[featureIndexOf]; // Get the previous plan's feature
                                    let feature = PlansData.planFeature.find((obj) => obj.id === item._id);
                                    let limitation = PlansData.planLimitation.find((obj) => obj.id === item._id);

                                    return (
                                        <Col lg={2} md={6} className="col-12 mt-3 pricingPlan" key={key}>
                                            <Card className="border-0 shadow prisingdiv">
                                                <div className="p-4 pb-0 border-bottom border-light">
                                                    <h6 className="fw-semibold mb-3 text-uppercase">{item.name}</h6>
                                                    {item.pricemonthly == 0 ?
                                                        <div className="d-flex my-4">
                                                            <span className="price h3 fw-semibold mb-0">Free</span>
                                                        </div>
                                                        : <>

                                                            <div className="d-flex">
                                                                <span className="price h3 fw-semibold mb-0">&#8377;{item.pricemonthly}/<small>Month + GST</small></span>
                                                            </div>
                                                            <span className="d-flex mb-3" style={{ fontSize: "12px" }}>
                                                                When billed {item.name === "Lean" ? "quarterly" : item.name === "Pro" ? "half-yearly" : "annually"} for one license.
                                                            </span>
                                                        </>
                                                    }
                                                    <button className="btn btn-primary w-100 mt-2" onClick={(e) => buynowHandler(e, item)}>Subscribe Now</button>
                                                </div>
                                                <div className="p-4">
                                                    {feature && <>
                                                        <h6 className="mb-3">Features:</h6>
                                                        <ul className="mb-0 list-unstyled planlist">
                                                            {/* For the first plan (key === 0), display all features that are true */}
                                                            {(key === 0 || (prevFeature && prevFeature.dashboard === false && feature.dashboard)) && feature.dashboard && <FeatureText value={"Dashboard"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.membercheckin === false && feature.membercheckin)) && feature.membercheckin && <FeatureText value={"Member Check-In"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.checkinreports === false && feature.checkinreports)) && feature.checkinreports && <FeatureText value={"Check-In Reports"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.memebershipplans === false && feature.memebershipplans)) && feature.memebershipplans && <FeatureText value={"Membership Plans"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.addons === false && feature.addons)) && feature.addons && <FeatureText value={"Addons"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.timeslots === false && feature.timeslots)) && feature.timeslots && <FeatureText value={"Time Slots"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.planswithsessionslimit === false && feature.planswithsessionslimit)) && feature.planswithsessionslimit && <FeatureText value={"Plans With Sessions Limit"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.adminlogin === false && feature.adminlogin)) && feature.adminlogin && <FeatureText value={"Admin Login"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.stafflogin === false && feature.stafflogin)) && feature.stafflogin && <FeatureText value={"Staff Login"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.discountplans === false && feature.discountplans)) && feature.discountplans && <FeatureText value={"Discount Plans"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.emailreceipt === false && feature.emailreceipt)) && feature.emailreceipt && <FeatureText value={"Email Receipt"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.digitalwaiver === false && feature.digitalwaiver)) && feature.digitalwaiver && <FeatureText value={"Digital Waiver"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.pos === false && feature.pos)) && feature.pos && <FeatureText value={"POS"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.gstreceipts === false && feature.gstreceipts)) && feature.gstreceipts && <FeatureText value={"GST Receipts"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.trainersessionssummary === false && feature.trainersessionssummary)) && feature.trainersessionssummary && <FeatureText value={"Trainer Sessions Summary"} />}
                                                            {(key === 0 || (prevFeature && prevFeature.loginwithgoogleaccount === false && feature.loginwithgoogleaccount)) && feature.loginwithgoogleaccount && <FeatureText value={"Login With Google Account"} />}
                                                        </ul>
                                                    </>}
                                                    {limitation && <>

                                                        <h6 className="my-3">Limits:</h6>
                                                        <ul className="mb-0 list-unstyled planlist">
                                                            {(feature.memebershipplans == false || limitation.memebershipplans == 0) ? "" : <FeatureText value={`${limitation.memebershipplans == -1 ? "Unlimited" : limitation.memebershipplans} Memebership Plan(s)`} />}
                                                            {(feature.addons == false || limitation.addons == 0) ? "" : <FeatureText value={`${limitation.addons == -1 ? "Unlimited" : limitation.addons} Addon(s)`} />}
                                                            {(feature.timeslots == false || limitation.timeslots == 0) ? "" : <FeatureText value={`${limitation.timeslots == -1 ? "Unlimited" : limitation.timeslots} Time Slot(s)`} />}
                                                            {(feature.stafflogin == false || limitation.stafflogin == 0) ? "" : <FeatureText value={`${limitation.stafflogin == -1 ? "Unlimited" : limitation.stafflogin} Staff login(s)`} />}
                                                            {limitation.activemembers == 0 ? "" : <FeatureText value={`${limitation.activemembers == -1 ? "Unlimited" : limitation.activemembers} Active Member(s)`} />}
                                                            {limitation.transactions == 0 ? "" : <FeatureText value={`${limitation.transactions == -1 ? "Unlimited" : limitation.transactions} Transaction(s) (Monthly)`} />}
                                                        </ul>
                                                    </>}

                                                    <h6 className="my-3">Additional Addons:</h6>

                                                    <ul className="mb-0 list-unstyled planlist">
                                                        <FeatureText value={`SMS Integration @Rs.${item.smsintergration} (Monthly)`} />
                                                        <FeatureText value={`Accountant Role @Rs.${item.accountantrole} (Monthly)`} />
                                                        <FeatureText value={`WhatsApp Integration @Rs.${item.whatsappintegration} (Monthly)`} />
                                                        {/* <FeatureText value={`Member Portal @Rs.${item.memberportal} (Monthly)`} /> */}
                                                    </ul>

                                                </div>
                                            </Card>
                                        </Col>
                                    );
                                })
                            ) : ("")}
                        </>
                    </Row>
                    <MDBModal tabIndex='-1' open={fullscreenXlModal} onClose={() => setFullscreenXlModal(false)}>
                        <MDBModalDialog size='fullscreen'>
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle>Pricing Plans Comparison</MDBModalTitle>
                                    <MDBBtn
                                        type='button'
                                        className='btn-close'
                                        color='none'
                                        onClick={toggleOpen}
                                    ></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody style={{ maxHeight: "90vh" }}>
                                    <div className="container">

                                        {PlansData && PlansData.pricingPlans && PlansData.pricingPlans.length > 0 &&
                                            <div className="container-fluid">

                                                <Table bordered hover responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>Plan</th>
                                                            {PlansData.pricingPlans.map((item, key) => (
                                                                <th key={key} className="text-center">
                                                                    {item.name}
                                                                    <br />

                                                                    {
                                                                        item.pricemonthly == 0 ?
                                                                            <span className="price h5">Free</span>
                                                                            : <span className="price h5">&#8377;{item.pricemonthly}/<small>Month</small></span>
                                                                    }
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Features Section */}
                                                        <tr className="table-secondary">
                                                            <td colSpan={PlansData.pricingPlans.length + 1} className="text-center fw-bold">Features</td>
                                                        </tr>
                                                        {featureFields.map(({ field, label }, index) => (
                                                            <tr key={index}>
                                                                <td>{label}</td>
                                                                {PlansData.pricingPlans.map((item, key) => {
                                                                    const featureData = PlansData.planFeature.find(f => f.id === item._id);
                                                                    return (
                                                                        <td key={key} className="text-center">
                                                                            {featureData && featureData[field] ? (
                                                                                <span className="text-success"><MDBIcon fas className="fs-6" icon="check" /></span>
                                                                            ) : (
                                                                                <span className="text-danger"><MDBIcon fas className="fs-5" icon="times" /></span>
                                                                            )}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        ))}

                                                        {/* Limitations Section */}
                                                        <tr className="table-secondary">
                                                            <td colSpan={PlansData.pricingPlans.length + 1} className="text-center fw-bold">Limits</td>
                                                        </tr>
                                                        {limitFields.map(({ field, label }, index) => (
                                                            <tr key={index}>
                                                                <td>{label}</td>
                                                                {PlansData.pricingPlans.map((item, key) => {
                                                                    const limitationData = PlansData.planLimitation.find(l => l.id === item._id);
                                                                    const limitValue = limitationData ? (limitationData[field] === -1 ? "Unlimited" : limitationData[field]) : "N/A";
                                                                    return <td key={key} className="text-center">{limitValue}</td>;
                                                                })}
                                                            </tr>
                                                        ))}

                                                        {/* Modules Section */}
                                                        <tr className="table-secondary">
                                                            <td colSpan={PlansData.pricingPlans.length + 1} className="text-center fw-bold">Additional Addons</td>
                                                        </tr>
                                                        {moduleFields.map(({ field, label }, index) => (
                                                            <tr key={index}>
                                                                <td>{label}</td>
                                                                {PlansData.pricingPlans.map((item, key) => (
                                                                    <td key={key} className="text-center">
                                                                        {item[field] ? `@Rs.${item[field]}` : "N/A"}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            {PlansData.pricingPlans.map((item, key) => (
                                                                <td key={key} className="text-center">
                                                                    <button className="btn btn-primary w-100" onClick={(e) => buynowHandler(e, item)}>Subscribe Now</button>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </div>
                                        }
                                    </div>
                                </MDBModalBody>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                </div>
                <MDBAlert
                    color={"danger"}
                    autohide
                    position='top-right'
                    delay={5000}
                    appendToBody
                    open={Error2 != ""}
                    onClose={() => setError2("")}
                >
                    {Error2}
                </MDBAlert>
            </section>
        </React.Fragment >
    );
}

export default Pricing;
